import { Invoice, InvoiceBasicDetailsFormData, InvoiceLineItem } from "@/models/invoice";

import axios from "axios";
import { notifier } from "@/models/common";

declare const dataURL: string
declare const getMaxRows: Function

export enum OrderStatus {
  Paid = 1,
  Created = 2,
  Sent = 0,
}

export enum InvoiceReportStatus {
  No = 0,
  Yes = 1,
}

export interface InvoiceForm {
  basicDetails: InvoiceBasicDetailsFormData;
  linesItems: InvoiceLineItem[];
  tax: number;
  shipping: number;
  inEditPage: boolean;
  cardInfo: any;
}
export type advance = {
  hideIncludeItems?:boolean,
  hideZeroPrice?:boolean,
  priceRolledUp?: boolean
}

export type onExport = {
  templateid?: number,
  showOnExport?: number,
  saveAsDefault?: number,
  pricingid?: string,
  advanced?:advance,
  hardwareCSVExport: boolean,
  doExport?: number
  templateCustomDetails: {};
}

export type Filters = {
  searchAll: string
  searchId: string
  searchName: string
  searchAccountName: string
  sDate: string | undefined
  eDate: string | undefined
  sTotalMin: string | undefined
  sTotalMax: string | undefined
  sTaxMin: string | undefined
  sTaxMax: string | undefined
  paidsDate: string | undefined
  paideDate: string | undefined
  AccountTagID: number[]
  groupIDs: number[]
  selectedAccs: (number | undefined)[]
  resellerCheckList: (number | undefined)[]
  sStatus: OrderStatus[]
  InvoicedPercentMin: string | undefined
  InvoicedPercentMax: string | undefined
  InvoicedProfitMin: string | undefined
  InvoicedProfitMax: string | undefined
  accountTagName: (string | undefined)[]
  selectedAccsName: (string | undefined)[]
  groupName: (string | undefined)[]
  filterGrandTotal: string | undefined
  filterTax: string | undefined
  filterInvoiceProfit: string | undefined
  filterDueDate: string | undefined
  filterPaidDate: string | undefined
  statusName: (string | undefined)[]
  filterInvoiced: string | undefined
  invoiceStartDate: string | undefined
  invoiceEndDate: string | undefined
  filterInvoiceDate: string | undefined
  searchMail: string
  reportStatus: InvoiceReportStatus[]
  reportName: (string | undefined)[]
  gTagIDs: number[]
  sSource: string[]
  sourceName?: (string | undefined)[]
  iType: invoiceTypeName[]
  typeName: (string | undefined)[],
  from: string | undefined,
  fYear: string | undefined,
  deptName?: string | undefined,
  endUserName?: string | undefined,
  bAddress?: string | undefined,
  bState?: string | undefined,
  searchPaymentRef?: string | undefined
}

export enum SortField {
  Id = 1,
  Name = 2,
  Customer = 3,
  Total = 4,
  Date = 5,
  Tax = 6,
  PaidDate = 7,
  InvoicedPercent = 10,
  InvoiceProfit = 11,
  InvoiceDate = 12,
  InvoiceMail = 13,
  InvoiceReport = 14,
  InvoiceType = 15,
  DepartmentName = 16,
  EndUserName = 17,
  bAddress = 18,
  bState = 19,
  PaymentRef = 20,
  UnpaidProfit = 21,
  PaidProfit = 22,
  iAddress = 23,
  iState = 24,
}

export enum InvoiceStatus {
  Sent = 0,
  Created = 2,
  Paid = 1,
  PartialPay = 3,
  Void = 4,
}

export type Sort = {
  field: SortField | null
  direction: {
    [SortField.Id]: 1 | 2,
    [SortField.Name]: 1 | 2,
    [SortField.Customer]: 1 | 2,
    [SortField.Total]: 1 | 2,
    [SortField.Date]: 1 | 2,
    [SortField.Tax]: 1 | 2,
    [SortField.PaidDate]: 1 | 2,
    [SortField.InvoicedPercent]: 1 | 2,
    [SortField.InvoiceProfit]: 1 | 2,
    [SortField.InvoiceDate]: 1 | 2,
    [SortField.InvoiceMail]: 1 | 2,
    [SortField.InvoiceReport]: 1 | 2,
    [SortField.InvoiceType]: 1 | 2,
    [SortField.DepartmentName]: 1 | 2,
    [SortField.EndUserName]: 1 | 2,
    [SortField.bAddress]: 1 | 2,
    [SortField.bState]: 1 | 2,
    [SortField.iAddress]: 1 | 2,
    [SortField.iState]: 1 | 2,
    [SortField.PaymentRef]: 1 | 2
  }
}

export enum DepositStatus {
  Sent = 0,
  Created = 2,
  PartialPay = 3,
}

export type DepositFilters = {
  depositSearchId: string
  depositTotalMin: string | undefined
  depositTotalMax: string | undefined
  depositStartDate: string | undefined
  depositEndDate: string | undefined
  depositStatus: DepositStatus[]
  depositCustomer: string
}

export enum DepositSortField {
  depositSearchId = 1,
  depositTotal = 2,
  depositDate = 3,
  depositCustomer = 4
}

export type headersArray = {
  header: string,
  column: string,
  widthPercent: string
}

export type DepositSort = {
  field: DepositSortField | null
  direction: {
    [DepositSortField.depositSearchId]: 1 | 2,
    [DepositSortField.depositTotal]: 1 | 2,
    [DepositSortField.depositDate]: 1 | 2,
    [DepositSortField.depositCustomer]: 1 | 2
  }
}

export type InvoiceDepositFilters = {
  invoiceDepositId?: string
  invoiceDepositName?: string
  invoiceDepositStartDate: string | undefined
  invoiceDepositEndDate: string | undefined
  invoiceDepositTotalMin: string | undefined
  invoiceDepositTotalMax: string | undefined
  invoiceDepositIsConfirmed: number[]
}

export enum InvoiceDepositSortField {
  invoiceDepositId = 1,
  invoiceDepositName = 2,
  invoiceDepositDate = 3,
  invoiceDepositTotal = 4,
  invoiceDepositIsConfirmed = 5
}

export type InvoiceDepositSort = {
  field: InvoiceDepositSortField | null | number
  direction: {
    [InvoiceDepositSortField.invoiceDepositId]: number,
    [InvoiceDepositSortField.invoiceDepositName]: number,
    [InvoiceDepositSortField.invoiceDepositDate]: number,
    [InvoiceDepositSortField.invoiceDepositTotal]: number
  }
}

export enum invoiceTypeName {
  "Credit Memo" = 1,
  "HPE Direct" = 2,
  "Adjustment" = 3,
  "Invoice" = 4,
  "Credit Card" = 5
}

export const USState = {
  "1": {
    "Abbr": "AL",
    "FullName": "Alabama"
  },
  "2": {
    "Abbr": "AK",
    "FullName": "Alaska"
  },
  "3": {
    "Abbr": "AZ",
    "FullName": "Arizona"
  },
  "4": {
    "Abbr": "AR",
    "FullName": "Arkansas"
  },
  "5": {
    "Abbr": "CA",
    "FullName": "California"
  },
  "6": {
    "Abbr": "CO",
    "FullName": "Colorado"
  },
  "7": {
    "Abbr": "CT",
    "FullName": "Connecticut"
  },
  "8": {
    "Abbr": "DC",
    "FullName": "District Of Columbia"
  },
  "9": {
    "Abbr": "DE",
    "FullName": "Delaware"
  },
  "10": {
    "Abbr": "FL",
    "FullName": "Florida"
  },
  "11": {
    "Abbr": "GA",
    "FullName": "Georgia"
  },
  "12": {
    "Abbr": "HI",
    "FullName": "Hawaii"
  },
  "13": {
    "Abbr": "ID",
    "FullName": "Idaho"
  },
  "14": {
    "Abbr": "IL",
    "FullName": "Illinois"
  },
  "15": {
    "Abbr": "IN",
    "FullName": "Indiana"
  },
  "16": {
    "Abbr": "IA",
    "FullName": "Iowa"
  },
  "17": {
    "Abbr": "KS",
    "FullName": "Kansas"
  },
  "18": {
    "Abbr": "KY",
    "FullName": "Kentucky"
  },
  "19": {
    "Abbr": "LA",
    "FullName": "Louisiana"
  },
  "20": {
    "Abbr": "ME",
    "FullName": "Maine"
  },
  "21": {
    "Abbr": "MD",
    "FullName": "Maryland"
  },
  "22": {
    "Abbr": "MA",
    "FullName": "Massachusetts"
  },
  "23": {
    "Abbr": "MI",
    "FullName": "Michigan"
  },
  "24": {
    "Abbr": "MN",
    "FullName": "Minnesota"
  },
  "25": {
    "Abbr": "MS",
    "FullName": "Mississippi"
  },
  "26": {
    "Abbr": "MO",
    "FullName": "Missouri"
  },
  "27": {
    "Abbr": "MT",
    "FullName": "Montana"
  },
  "28": {
    "Abbr": "NE",
    "FullName": "Nebraska"
  },
  "29": {
    "Abbr": "NV",
    "FullName": "Nevada"
  },
  "30": {
    "Abbr": "NH",
    "FullName": "New Hampshire"
  },
  "31": {
    "Abbr": "NJ",
    "FullName": "New Jersey"
  },
  "32": {
    "Abbr": "NM",
    "FullName": "New Mexico"
  },
  "33": {
    "Abbr": "NY",
    "FullName": "New York"
  },
  "34": {
    "Abbr": "NC",
    "FullName": "North Carolina"
  },
  "35": {
    "Abbr": "ND",
    "FullName": "North Dakota"
  },
  "36": {
    "Abbr": "OH",
    "FullName": "Ohio"
  },
  "37": {
    "Abbr": "OK",
    "FullName": "Oklahoma"
  },
  "38": {
    "Abbr": "OR",
    "FullName": "Oregon"
  },
  "39": {
    "Abbr": "PA",
    "FullName": "Pennsylvania"
  },
  "40": {
    "Abbr": "PR",
    "FullName": "Puerto Rico"
  },
  "41": {
    "Abbr": "RI",
    "FullName": "Rhode Island"
  },
  "42": {
    "Abbr": "SC",
    "FullName": "South Carolina"
  },
  "43": {
    "Abbr": "SD",
    "FullName": "South Dakota"
  },
  "44": {
    "Abbr": "TN",
    "FullName": "Tennessee"
  },
  "45": {
    "Abbr": "TX",
    "FullName": "Texas"
  },
  "46": {
    "Abbr": "UT",
    "FullName": "Utah"
  },
  "47": {
    "Abbr": "VA",
    "FullName": "Virginia"
  },
  "48": {
    "Abbr": "VT",
    "FullName": "Vermont"
  },
  "49": {
    "Abbr": "WA",
    "FullName": "Washington"
  },
  "50": {
    "Abbr": "WV",
    "FullName": "West Virginia"
  },
  "51": {
    "Abbr": "WI",
    "FullName": "Wisconsin"
  },
  "52": {
    "Abbr": "WY",
    "FullName": "Wyoming"
  }
}
export class InvoiceAPIRepo {
  async find(filters: Filters, sort: Sort, pageNumber: number, view: string = 'CurrentUsers', selectedView = false, initialLoad: boolean = false): Promise<Invoice.Root> {
    const reqData: any = this.prepareFilteredData(filters, sort, view, initialLoad);
    reqData["FunctionName"] = "List";
    reqData["maxRows"] = sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows();
    reqData["PageNumber"] = pageNumber.toString();
    reqData["Default_totalRows"] = getMaxRows();
    reqData["selectedView"] = selectedView;

    // const reqData: any = {
    //   "controller": "Invoices",
    //   "FunctionName": "List",
    //   "search": filters.searchAll,
    //   "sInvoiceID": filters.searchId,
    //   "view": view,
    //   "sDescription": filters.searchName,
    //   "sAccountName": filters.searchAccountName,
    //   "PageNumber": pageNumber.toString(),
    //   "Default_totalRows": getMaxRows(),
    // }

    // reqData['maxRows'] = sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows()

    // if (filters.sDate) {
    //   reqData['sDate'] = filters.sDate
    // }
    // if (filters.eDate) {
    //   reqData['eDate'] = filters.eDate
    // }
    // if (typeof filters.sTotalMin !== 'undefined') {
    //   reqData['sTotalMin'] = filters.sTotalMin
    // }
    // if (typeof filters.sTotalMax !== 'undefined') {
    //   reqData['sTotalMax'] = filters.sTotalMax
    // }

    // if (sort.field) {
    //   reqData["order"] = sort.field
    //   reqData["direction"] = sort.direction[sort.field].toString()
    // }
    // console.log('reqData', reqData)

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    // console.log('response.data', response.data)

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }

  async findByIDs(ids: string[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders: headersArray[] = []): Promise<Invoice.Root> {
    const reqData: any = this.prepareFilteredData(filters, sort, viewType);
    reqData["FunctionName"] = "List";
    reqData["selectedID"] = [];
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;
    reqData["selectedHeadersList"] = selectedHeaders;

    // var params: any = {
    //   controller: "Invoices",
    //   FunctionName: "List",
    //   selectedID: [],
    //   selectedAll: selectedAll,
    //   excludedIDs: excludedIDs
    // }

    if (ids.length > 0) {
      reqData.selectedID = ids;
    } else {
      reqData.getAll = 1;
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }

  async findOneDetails(invoiceId: string, exportObj?: onExport): Promise<Invoice.Invoices> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Invoices",
      FunctionName: "View",
      Content: "Detailed",
      displayOnExport: exportObj ? exportObj.showOnExport : 0,
      saveAsDefault: exportObj ? exportObj.saveAsDefault : 0,
      SPECIALPRICINGID: exportObj ? exportObj.pricingid : '',
      exportTemplate: exportObj ? exportObj.templateid : 0,
      hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
      hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
      invoiceId: invoiceId,
      doExport: exportObj && (exportObj.doExport || 0) ? 1 : 0,
      priceRolledUp: exportObj && exportObj.advanced && exportObj.advanced.priceRolledUp ? 1 : 0,
      templateCustomDetails: typeof exportObj != "undefined" && typeof exportObj.templateCustomDetails != "undefined" ? exportObj.templateCustomDetails : {},
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }

  async create(form: InvoiceForm) {
    const linesObject: Object = {};
    for (let i = 1; i <= form.linesItems.length; i++) {
      const item: any = form.linesItems[i - 1]
      linesObject[`invoiceDescription${i}`] = item.description
      linesObject[`invoiceNumber${i}`] = item.partNo.toString()
      linesObject[`invoicePrice${i}`] = item.unitPrice.toString()
      linesObject[`invoiceQuantity${i}`] = item.quantity.toString()
      linesObject[`invoiceTotal${i}`] = item.estPrice.toString()
      linesObject[`invoiceInclude${i}`] = item.included.toString()
      linesObject[`invoiceNoTax${i}`] = item.noTax || 0;
      linesObject[`invoiceTaxRate${i}`] = item.taxRate || 0;
      linesObject[`ItemCategory${i}`] = typeof item["ItemCategory"] != "undefined" ? item["ItemCategory"] : 0;
      linesObject[`invoicePOliID${i}`] = item.POliID || 0;
      linesObject[`shippingDate${i}`] = item.shippingDate || "";
      linesObject[`ItemIsConfig${i}`] = item.ISCONFIG || 0;
      linesObject[`ItemSubOrderId${i}`] = item.subOrderId || 0;
    }

    const invoiceTotal = form.linesItems.reduce((total, item) => parseFloat(item.estPrice.toString()) + parseFloat(total.toString()), 0);
    const formData = form.basicDetails;
    const aID = formData.customer.aID;
    const tax = (form.tax) ? form.tax : 0;
    const shipping = (form.shipping) ? form.shipping : 0;

    const payload = Object.assign({
      FunctionName: "Add",
      aID: aID,
      controller: "Invoices",
      invoiceAddress: formData.address,
      invoiceAddress2: formData.address2,
      invoiceCity: formData.city,
      invoiceState: formData.state,
      invoiceZip: formData.zip,

      // billing
      invoiceAddressBilling: formData.addressBilling,
      invoiceAddress2Billing: formData.address2Billing,
      invoiceCityBilling: formData.cityBilling,
      invoiceStateBilling: formData.stateBilling,
      invoiceZipBilling: formData.zipBilling,

      invoiceDate: formData.invoiceDate,
      invoiceDueDate: formData.invoiceDueDate,
      invoiceId: formData.invoiceId,
      invoiceName: formData.invoiceName,
      invoicePurchase: formData.PO,
      invoiceShipping: shipping,
      invoiceTax: tax,
      invoiceTotal: invoiceTotal.toString(),
      submitInvoiceAdd: "submit",
      purchaseID: (typeof formData.purchaseID != "undefined" ? formData.purchaseID : 0),
      shippingID: formData.shippingId,
      inEditPage: form.inEditPage,
      iNotes: formData.iNotes || "",
      DefaultShippingID: formData.DefaultShippingID,
      DefaultBillingID: formData.DefaultBillingID,
      customID: formData.customID,
      customValue: formData.customValue,
      expandDecimal: formData.expandDecimal,
      invoiceType: formData.invoiceType,
      cardInfo: form.cardInfo || {}
    }, linesObject);
    
    if(typeof formData.onetimeAddress != "undefined") {
      payload["onetimeAddress"] = formData.onetimeAddress
    }
    if(typeof formData.onetimeAddressBilling != "undefined") {
      payload["onetimeAddressBilling"] = formData.onetimeAddressBilling
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", payload);
    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response;
  }

  async removeByIds(invoiceIDs: string[], selectedAll = false, excludedIDs: any, aID = 0): Promise<number> {
    let successCount = 0

    //for (const invoiceID of invoiceIDs) {
    const reqBody = {
      Controller: 'Invoices',
      FunctionName: 'Delete',
      //invoiceID,
      deleteInvoice: 'true',
      selectedAll: selectedAll,
      excludedIDs: excludedIDs,
      selectedIDs: invoiceIDs.join(",")
    };
    if(aID > 0) {
      reqBody["aID"] = aID;
    }

    const response = await axios.post(dataURL + '?ReturnType=JSON', reqBody)
    if (response.data.STATUS !== 1) {
      if (response.data.ERROR || response.data.STATUSMESSAGE) {
        notifier.alert(response.data.ERROR || response.data.STATUSMESSAGE);
      }
      //continue
      return 0;
    }

    //successCount++
    //}

    return invoiceIDs.length;
  }

  async exportAgingReportCSVFileUrl(){
    const objData: any = {};
    objData["controller"] = "Invoices";
    objData["FunctionName"] = "Export";
    objData["ExportType"] = "CSV";
    objData["view"] = "All";
    objData["agingReport"] = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", objData);
    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }
    return response.data.S3URL
  }

  async exportListsCSVFileUrl(ids: string[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false): Promise<string> {
    const reqData: any = this.prepareFilteredData(filters, sort, viewType);
    reqData["FunctionName"] = "Export";
    reqData["selectedID"] = ids;
    reqData["ExportType"] = "CSV";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportListsPDFFileUrl(ids: string[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders: headersArray[] = []): Promise<string> {
    const reqData: any = this.prepareFilteredData(filters, sort, viewType);
    reqData["FunctionName"] = "Export";
    reqData["selectedID"] = ids;
    reqData["ExportType"] = "PDF";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;
    reqData["selectedHeadersList"] = selectedHeaders;
    //getAll: 1,

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportDetailsCSVFileUrl(invoiceID: number, exportObj: onExport): Promise<string> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Invoices",
      FunctionName: "ExportView",
      invoiceID: invoiceID,
      ExportType: exportObj.hardwareCSVExport ? "hardwareCSV" : "CSV",
      displayOnExport: exportObj.showOnExport,
      saveAsDefault: exportObj.saveAsDefault,
      SPECIALPRICINGID: exportObj.pricingid,
      exportTemplate: exportObj.templateid,
      hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
      hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
      Content: "Detailed"
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportDetailsPDFFileUrl(invoiceID: number, exportObj: onExport): Promise<string> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Invoices",
      FunctionName: "ExportView",
      invoiceID: invoiceID,
      ExportType: "PDF",
      displayOnExport: exportObj.showOnExport,
      saveAsDefault: exportObj.saveAsDefault,
      SPECIALPRICINGID: exportObj.pricingid,
      exportTemplate: exportObj.templateid,
      hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
      hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
      Content: "Detailed",
      priceRolledUp: exportObj && exportObj.advanced && exportObj.advanced.priceRolledUp ? 1 : 0,
      templateCustomDetails: exportObj && exportObj.templateCustomDetails ? exportObj.templateCustomDetails : {}
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportInvoiceAgingReportFileUrl(ids: string[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, exportInvoiceAgingDate: string): Promise<string> {
    const reqData: any = this.prepareFilteredData(filters, sort, viewType);
    reqData["FunctionName"] = "ExportInvoiceAgingReport";
    reqData["selectedID"] = ids;
    reqData["ExportType"] = "CSV";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;
    reqData["agingReport"] = true;
    reqData["exportInvoiceAgingDate"] = exportInvoiceAgingDate;

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async getAddress(userId: number): Promise<any> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Accounts",
      FunctionName: "accountDetails",
      aID: userId
    });
    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }

  async updateInvoiceStatus(invoiceID: number|string, mdInvoiceStatus: number,Pid: number, profit: number, aID = 0, confirmedVals: any = {}) {
    const reqBody = {
      Controller: 'Invoices',
      FunctionName: 'Edit',
      invoiceID,
      mdInvoiceStatus,
      updateInvoiceStatus: 'true',
      invPurchaseId : Pid,
      orderProfit : profit,
      partial : confirmedVals.isPartial
    };
    reqBody["paymentAmount"] = confirmedVals.paymentAmount || "";
    reqBody["paymentDate"] = confirmedVals.paymentDate || "";
    reqBody["paymentType"] = confirmedVals.paymentType || "";
    reqBody["paymentReference"] = confirmedVals.paymentReference || "";
    reqBody["taxAmount"] = confirmedVals.taxAmount || 0;
    if(typeof confirmedVals.profitTotal != "undefined" && confirmedVals.profitTotal != "") {
      reqBody["profit"] = confirmedVals.profitTotal || "";
    }

    if(aID > 0) {
      reqBody["aID"] = aID;
    }

    const response = await axios.post(dataURL + '?ReturnType=JSON', reqBody)
    
    // if (response.data.STATUS !== 1) {
    //   if (response.data.ERROR || response.data.STATUSMESSAGE) {
    //     notifier.alert(response.data.ERROR || response.data.STATUSMESSAGE);
    //   }
    // }

    return response;
  }

  prepareFilteredData(filters: any, sort: any, view: string = 'CurrentUsers', isStatus: boolean = false) {
    var ret = {
      "controller": "Invoices",
      "search": filters.searchAll,
      "sInvoiceID": filters.searchId,
      "view": view,
      "sDescription": filters.searchName,
      "sAccountName": filters.searchAccountName,
      "selectedResellers": filters.resellerCheckList,
      "selectedTagIDs": filters.AccountTagID.join(","),
      "groupIDs": filters.groupIDs.join(","),
      "selectedAccountDs": filters.selectedAccs.join(","),
      "gTagIDs": filters.gTagIDs.join(","),
      "from": filters.from,
      "fYear": filters.fYear,
      "searchPaymentRef": filters.searchPaymentRef,
    }

    if (filters.sDate) {
      ret['sDate'] = filters.sDate
    }
    if (filters.eDate) {
      ret['eDate'] = filters.eDate
    }
    if (typeof filters.sTotalMin !== 'undefined') {
      ret['sTotalMin'] = filters.sTotalMin
    }
    if (typeof filters.sTotalMax !== 'undefined') {
      ret['sTotalMax'] = filters.sTotalMax
    }
    if (filters.sStatus.length > 0) {
      ret['invoiceStatus'] = filters.sStatus.join(',')
    }
    if (!isStatus && filters.sStatus.length == 0) {
      ret['invoiceStatus'] = '-1';
    }
    if (typeof filters.sTaxMin !== 'undefined') {
      ret['sTaxMin'] = filters.sTaxMin
    }
    if (typeof filters.sTaxMax !== 'undefined') {
      ret['sTaxMax'] = filters.sTaxMax
    }
    if (filters.paidsDate) {
      ret['paidsDate'] = filters.paidsDate
    }
    if (filters.paideDate) {
      ret['paideDate'] = filters.paideDate
    }
    if (typeof filters.InvoicedPercentMin !== 'undefined') {
      ret['InvoicedPercentMin'] = filters.InvoicedPercentMin
    }
    if (typeof filters.InvoicedPercentMax !== 'undefined') {
      ret['InvoicedPercentMax'] = filters.InvoicedPercentMax
    }
    if (typeof filters.InvoicedProfitMin !== 'undefined') {
      ret['InvoicedProfitMin'] = filters.InvoicedProfitMin
    }
    if (typeof filters.InvoicedProfitMax !== 'undefined') {
      ret['InvoicedProfitMax'] = filters.InvoicedProfitMax
    }
    if (typeof filters.UnpaidProfitMin !== 'undefined') {
      ret['UnpaidProfitMin'] = filters.UnpaidProfitMin;
    }
    if (typeof filters.UnpaidProfitMax !== 'undefined') {
      ret['UnpaidProfitMax'] = filters.UnpaidProfitMax;
    }
    if (typeof filters.PaidProfitMin !== 'undefined') {
      ret['PaidProfitMin'] = filters.PaidProfitMin;
    }
    if (typeof filters.PaidProfitMax !== 'undefined') {
      ret['PaidProfitMax'] = filters.PaidProfitMax;
    }
    if (typeof filters.invoiceStartDate !== 'undefined') {
      ret['invoiceStartDate'] = filters.invoiceStartDate
    }
    if (typeof filters.invoiceEndDate !== 'undefined') {
      ret['invoiceEndDate'] = filters.invoiceEndDate
    }
    if (typeof filters.searchMail !== 'undefined') {
      ret['invoiceMail'] = filters.searchMail
    }
    if (typeof filters.reportStatus !== 'undefined' && filters.reportStatus.length > 0) {
      ret['invoiceReportStatus'] = filters.reportStatus.join(',')
    } else {
      ret['invoiceReportStatus'] = '-1';
    }
    ret['source'] = "";
    if (filters.sSource.length) {
      ret['source'] = filters.sSource.join(',');
    }
    else if (!isStatus && !filters.sSource.length) {
      ret['source'] = "";
    }
    if (filters.iType.length) {
      ret['invoiceType'] = filters.iType.join(',')
    } else if (!isStatus &&!filters.iType.length) {
      ret['invoiceType'] = "-1";
    }

    if (sort.field) {
      ret["order"] = sort.field
      ret["direction"] = sort.direction[sort.field].toString()
    }
    if (filters.endUserName) {
      ret['endUserName'] = filters.endUserName
    }
    if (filters.deptName) {
      ret['sDeptName'] = filters.deptName;
    }
    if (filters.bAddress) {
      ret['bAddress'] = filters.bAddress;
    }
    if (filters.bState.length) {
      ret['bState'] = filters.bState.join(",");
    }
    if (filters.iAddress) {
      ret['iAddress'] = filters.iAddress;
    }
    if (filters.iState.length) {
      ret['iState'] = filters.iState.join(",");
    }

    return ret;
  }

  async findDepositDetails(depositFilters: DepositFilters, depositSort: DepositSort, editedDepositDetails: any = []) {
    var requestData = {
      "controller": "Invoices",
      "FunctionName": "invoicesDeposit",
      "depositInvoiceId": depositFilters.depositSearchId,
      "depositStartDate": depositFilters.depositStartDate ? depositFilters.depositStartDate : '',
      "depositEndDate": depositFilters.depositEndDate ? depositFilters.depositEndDate : '',
      "depositCustomer": depositFilters.depositCustomer ? depositFilters.depositCustomer : '',
      "action" : "list",
      "editedDepositDetails": editedDepositDetails
    }
    if (depositFilters.depositStatus.length > 0) {
      requestData["depositInvoiceStatus"] = depositFilters.depositStatus.join(',')
    } else if (depositFilters.depositStatus.length == 0) {
      requestData["depositInvoiceStatus"] = '-1';
    }
    if (typeof depositFilters.depositTotalMin !== 'undefined') {
      requestData["depositTotalMin"] = depositFilters.depositTotalMin
    }
    if (typeof depositFilters.depositTotalMax !== 'undefined') {
      requestData["depositTotalMax"] = depositFilters.depositTotalMax
    }
    if (depositSort.field) {
      requestData["order"] = depositSort.field;
      requestData["direction"] = depositSort.direction[depositSort.field].toString();
    }
    const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);
    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    return response.data
  }

  async findInvoiceDeposits(invoiceDepositFilters: InvoiceDepositFilters, invoiceDepositSort: InvoiceDepositSort, pageNumber: number, viewType = "CurrentUsers", selectedView = false) {
    var requestData = {
      "controller": "Invoices",
      "FunctionName": "depositList",
      "pageNumber": pageNumber,
      "maxRows": sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : getMaxRows(),
      "depositId": invoiceDepositFilters.invoiceDepositId,
      "depositName": invoiceDepositFilters.invoiceDepositName,
      "invoiceDepositStartDate": invoiceDepositFilters.invoiceDepositStartDate ? invoiceDepositFilters.invoiceDepositStartDate : '',
      "invoiceDepositEndDate": invoiceDepositFilters.invoiceDepositEndDate ? invoiceDepositFilters.invoiceDepositEndDate : '',
      "view": viewType,
      "selectedView": selectedView,
      "action" : "list",
      "isConfirmed": invoiceDepositFilters.invoiceDepositIsConfirmed ? invoiceDepositFilters.invoiceDepositIsConfirmed.join(",") : ''
    }
    if (typeof invoiceDepositFilters.invoiceDepositTotalMin !== 'undefined') {
      requestData["invoiceDepositTotalMin"] = invoiceDepositFilters.invoiceDepositTotalMin
    }
    if (typeof invoiceDepositFilters.invoiceDepositTotalMax !== 'undefined') {
      requestData["invoiceDepositTotalMax"] = invoiceDepositFilters.invoiceDepositTotalMax
    }
    if (invoiceDepositSort.field) {
      requestData["order"] = invoiceDepositSort.field;
      requestData["direction"] = invoiceDepositSort.direction[invoiceDepositSort.field].toString();
    }
    if (!invoiceDepositFilters.invoiceDepositIsConfirmed.length) {
      requestData['isConfirmed'] = "-1";
    }
    const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);
    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    return response.data
  }
}
