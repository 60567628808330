var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_header : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":10,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_paragraph : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":2},"end":{"line":27,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_shipment_notes : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":2},"end":{"line":40,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRemitAddress : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":2},"end":{"line":145,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInfoHeader : depth0),{"name":"if","hash":{},"fn":container.program(83, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":2},"end":{"line":814,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"if","hash":{},"fn":container.program(231, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":816,"column":2},"end":{"line":820,"column":9}}})) != null ? stack1 : "")
    + "  \n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"if","hash":{},"fn":container.program(234, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":822,"column":2},"end":{"line":841,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_table : depth0),{"name":"if","hash":{},"fn":container.program(240, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":843,"column":2},"end":{"line":869,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.type_data_fields : depth0),{"name":"if","hash":{},"fn":container.program(250, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":871,"column":2},"end":{"line":908,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <table width=\"100%\" class=\"element-header "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":110}}})) != null ? stack1 : "")
    + " element-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":119},"end":{"line":4,"column":125}}}) : helper)))
    + "\">\n        <tr>\n          <td class=\"element-header-content\">"
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":6,"column":45},"end":{"line":6,"column":54}}}) : helper)))
    + "</td>\n        </tr>\n      </table>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-header-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":4,"column":87},"end":{"line":4,"column":103}}}) : helper)));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <table width=\"100%\" class=\"element-paragraph "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":51},"end":{"line":14,"column":116}}})) != null ? stack1 : "")
    + " element-"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":125},"end":{"line":14,"column":131}}}) : helper)))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.header : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "      </table>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-paragraph-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":14,"column":93},"end":{"line":14,"column":109}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <tr>\n            <td class=\"element-paragraph-header\">"
    + container.escapeExpression(((helper = (helper = helpers.header || (depth0 != null ? depth0.header : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"header","hash":{},"data":data,"loc":{"start":{"line":17,"column":49},"end":{"line":17,"column":59}}}) : helper)))
    + "</td>\n          </tr>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <tr>\n            <td class=\"element-paragraph-content\">"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":22,"column":50},"end":{"line":22,"column":59}}}) : helper)))
    + "</td>\n          </tr>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":39,"column":11}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <table width=\"100%\" class=\"element-paragraph "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":51},"end":{"line":31,"column":116}}})) != null ? stack1 : "")
    + " element-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":31,"column":125},"end":{"line":31,"column":131}}}) : helper)))
    + "\">\n        <tr>\n          <td class=\"element-paragraph-header\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":33,"column":47},"end":{"line":33,"column":55}}}) : helper)))
    + "</td>\n        </tr>\n        <tr>\n          <td class=\"element-paragraph-content\">"
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":36,"column":48},"end":{"line":36,"column":57}}}) : helper)))
    + "</td>\n        </tr>\n      </table>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <table width=\"100%\" id=\"quoteDetails_header\">\n      <tr>\n        <td style=\"color:#bbb; line-height: 20px; letter-spacing: 1.7px; padding-right: 5px; padding-top: 20px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showRemitAddress),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":10},"end":{"line":77,"column":17}}})) != null ? stack1 : "")
    + "        </td>\n        <td colspan=\"2\">\n          <img src=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.companyLogo), depth0))
    + "\" style=\"display:block; border: 0; float: right; height: 52px;\" border=\"0\" />\n        </td>\n      </tr>\n      <tr>\n        <td colspan=\"3\">\n          <table width=\"100%\">\n            <tr>\n              <td style=\"padding: 20px 0;font-size: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.headerSize),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":88,"column":52},"end":{"line":88,"column":145}}})) != null ? stack1 : "")
    + "px;letter-spacing: 2px;font-weight: bold;color: #a5a5a5;\">\n                <span style=\"color: #0196d5;\">PURCHASE ORDER #:</span>\n                <span style=\"font-size: 22px !important;\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMERPO),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":90,"column":58},"end":{"line":90,"column":175}}})) != null ? stack1 : "")
    + "</span>\n              </td>\n              <td align=\"right\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.sourceRepInfo),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":16},"end":{"line":104,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showLogo),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(61, data, 0),"data":data,"loc":{"start":{"line":106,"column":16},"end":{"line":114,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showTempIndicator),{"name":"if","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":16},"end":{"line":118,"column":23}}})) != null ? stack1 : "")
    + "              </td>\n            </tr>\n          </table>\n        </td>\n      </tr>\n\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"unless","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":125,"column":6},"end":{"line":143,"column":17}}})) != null ? stack1 : "")
    + "    </table>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":48,"column":12},"end":{"line":53,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":59,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":65,"column":19}}})) != null ? stack1 : "")
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.phoneNo),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":75,"column":19}}})) != null ? stack1 : "")
    + " <br />\n            "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":76,"column":12},"end":{"line":76,"column":141}}})) != null ? stack1 : "")
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.companyName), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.companyName), depth0)) != null ? stack1 : "")
    + "</strong>\n            ";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.streetAddress), depth0)) != null ? stack1 : "")
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.address1), depth0)) != null ? stack1 : "")
    + "\n            ";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.cityState), depth0)) != null ? stack1 : "")
    + "\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return "              "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.city), depth0)) != null ? stack1 : "")
    + ", "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.state), depth0)) != null ? stack1 : "")
    + " "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.zip), depth0)) != null ? stack1 : "")
    + "\n            ";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.phoneNo), depth0)) != null ? stack1 : "")
    + "\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":69,"column":14},"end":{"line":74,"column":21}}})) != null ? stack1 : "")
    + "            ";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText), depth0))
    + " \n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return "                "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.orderContactPhone1), depth0)) != null ? stack1 : "")
    + " "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.varInfo)) && stack1.orderContactPhone2), depth0)) != null ? stack1 : "")
    + "\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue), depth0))
    + " ";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.Email), depth0))
    + " ";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.headerSize), depth0));
},"44":function(container,depth0,helpers,partials,data) {
    return "26";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMERPO), depth0))
    + " ";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + " ";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  <div style=\"text-align:right; padding: 20px 0; font-size: 26px; letter-spacing: 2px; font-weight: bold; color: #a5a5a5; white-space: nowrap;\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.synnex),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(53, data, 0),"data":data,"loc":{"start":{"line":96,"column":20},"end":{"line":102,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n";
},"51":function(container,depth0,helpers,partials,data) {
    return "                      TD SYNNEX Corporation \n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISOTHERS),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.program(56, data, 0),"data":data,"loc":{"start":{"line":98,"column":20},"end":{"line":102,"column":20}}})) != null ? stack1 : "");
},"54":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0))
    + "\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + "\n                    ";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.LOGOSRC)) && stack1.length),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":18},"end":{"line":109,"column":25}}})) != null ? stack1 : "");
},"59":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span style=\"border-radius: 50%;\"><img src="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.LOGOSRC), depth0))
    + " style=\"height: 75px;\"/></span>\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showCustomerName),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":16},"end":{"line":114,"column":16}}})) != null ? stack1 : "");
},"62":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":18},"end":{"line":113,"column":25}}})) != null ? stack1 : "")
    + "                ";
},"63":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <span style=\"font-size: 25px; font-weight: 600;\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME), depth0))
    + "</span>\n";
},"65":function(container,depth0,helpers,partials,data) {
    return "                  <span style=\"padding: 10px; background-color: #cdcdcd; text-align:center; color: white;\">Customer Indicator</span>\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.shipAddress),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":8},"end":{"line":142,"column":15}}})) != null ? stack1 : "");
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "          <tr>\n            <td colspan=\"3\" style=\"color:#bbb;padding-top: 20px;line-height: 20px;letter-spacing: 1.7px;\">\n              <strong>Ship To: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.program(71, data, 0),"data":data,"loc":{"start":{"line":129,"column":31},"end":{"line":129,"column":151}}})) != null ? stack1 : "")
    + "</strong><br/>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.program(75, data, 0),"data":data,"loc":{"start":{"line":130,"column":14},"end":{"line":139,"column":21}}})) != null ? stack1 : "")
    + "            </td>\n          </tr>\n";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO), depth0));
},"71":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AACCOUNTNAME), depth0));
},"73":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.program(78, data, 0),"data":data,"loc":{"start":{"line":133,"column":16},"end":{"line":138,"column":23}}})) != null ? stack1 : "");
},"76":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR), depth0)) != null ? stack1 : "")
    + "\n";
},"78":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS),{"name":"if","hash":{},"fn":container.program(79, data, 0),"inverse":container.program(81, data, 0),"data":data,"loc":{"start":{"line":136,"column":18},"end":{"line":137,"column":105}}})) != null ? stack1 : "")
    + "\n";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<strong>"
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ACITY), depth0))
    + ",\n                  "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ASTATE), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AZIP), depth0))
    + "</strong>";
},"81":function(container,depth0,helpers,partials,data) {
    return "&nbsp;";
},"83":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.draggableOptions),{"name":"each","hash":{},"fn":container.program(84, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":4},"end":{"line":813,"column":13}}})) != null ? stack1 : "");
},"84":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showInformation),{"name":"if","hash":{},"fn":container.program(85, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":6},"end":{"line":812,"column":13}}})) != null ? stack1 : "");
},"85":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <table width=\"100%\" id=\"quoteDetails_info\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isContractNo : depth0),{"name":"if","hash":{},"fn":container.program(86, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":10},"end":{"line":158,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOtherOptions : depth0),{"name":"if","hash":{},"fn":container.program(89, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":10},"end":{"line":810,"column":17}}})) != null ? stack1 : "")
    + "        </table>\n";
},"86":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <tr>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contractNumber),{"name":"if","hash":{},"fn":container.program(87, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":14},"end":{"line":156,"column":21}}})) != null ? stack1 : "")
    + "            </tr>\n";
},"87":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td width=\"30%\" class=\"title\">Contract Number</td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SPECIALPRICINGID), depth0))
    + "</strong></td></tr>\n";
},"89":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].cols : depths[1]),{"name":"if","hash":{},"fn":container.program(90, data, 0, blockParams, depths),"inverse":container.program(162, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":160,"column":12},"end":{"line":784,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TEMPLATENOTES),{"name":"if","hash":{},"fn":container.program(218, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":786,"column":12},"end":{"line":789,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"unless","hash":{},"fn":container.program(220, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":791,"column":12},"end":{"line":796,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMSBYORDER),{"name":"if","hash":{},"fn":container.program(223, data, 0, blockParams, depths),"inverse":container.program(225, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":798,"column":12},"end":{"line":809,"column":19}}})) != null ? stack1 : "");
},"90":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "              <tr>\n                <td width=\"60%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depths[1] != null ? depths[1].cols : depths[1])) != null ? stack1.col1 : stack1),{"name":"each","hash":{},"fn":container.program(91, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":20},"end":{"line":401,"column":29}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n                <td width=\"40%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depths[1] != null ? depths[1].cols : depths[1])) != null ? stack1.col2 : stack1),{"name":"each","hash":{},"fn":container.program(156, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":407,"column":20},"end":{"line":642,"column":29}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n              </tr>\n";
},"91":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_customerPO : depth0),{"name":"if","hash":{},"fn":container.program(92, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":22},"end":{"line":172,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_orderNumber : depth0),{"name":"if","hash":{},"fn":container.program(95, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":174,"column":22},"end":{"line":179,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_orderDate : depth0),{"name":"if","hash":{},"fn":container.program(98, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":181,"column":22},"end":{"line":186,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_accountRep : depth0),{"name":"if","hash":{},"fn":container.program(104, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":188,"column":22},"end":{"line":193,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_phone : depth0),{"name":"if","hash":{},"fn":container.program(107, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":22},"end":{"line":210,"column":29}}})) != null ? stack1 : "")
    + "                      \n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_email : depth0),{"name":"if","hash":{},"fn":container.program(116, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":212,"column":22},"end":{"line":227,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_sourceRepInfo : depth0),{"name":"if","hash":{},"fn":container.program(122, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":229,"column":22},"end":{"line":327,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_shipTo : depth0),{"name":"if","hash":{},"fn":container.program(138, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":329,"column":22},"end":{"line":360,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_sourceOrderNumber : depth0),{"name":"if","hash":{},"fn":container.program(148, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":363,"column":22},"end":{"line":380,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_sourceQuoteNumber : depth0),{"name":"if","hash":{},"fn":container.program(152, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":383,"column":22},"end":{"line":400,"column":29}}})) != null ? stack1 : "");
},"92":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.customerPO),{"name":"if","hash":{},"fn":container.program(93, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":24},"end":{"line":171,"column":31}}})) != null ? stack1 : "");
},"93":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Customer PO </td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + "</strong></td></tr>\n";
},"95":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderNO),{"name":"if","hash":{},"fn":container.program(96, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":175,"column":24},"end":{"line":178,"column":31}}})) != null ? stack1 : "");
},"96":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Order Number</td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PURCHASEID), depth0))
    + "</strong></td></tr>\n";
},"98":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderDate),{"name":"if","hash":{},"fn":container.program(99, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":182,"column":24},"end":{"line":185,"column":31}}})) != null ? stack1 : "");
},"99":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                          <tr><td class=\"title\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceRepInfo : depths[2]),{"name":"if","hash":{},"fn":container.program(100, data, 0, blockParams, depths),"inverse":container.program(102, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":183,"column":48},"end":{"line":183,"column":109}}})) != null ? stack1 : "")
    + "</td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PODATETIMEFORMATTED), depth0))
    + "</strong></td></tr>\n";
},"100":function(container,depth0,helpers,partials,data) {
    return "P.O. DATE";
},"102":function(container,depth0,helpers,partials,data) {
    return "Order Date";
},"104":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showAccRep),{"name":"if","hash":{},"fn":container.program(105, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":189,"column":24},"end":{"line":192,"column":31}}})) != null ? stack1 : "");
},"105":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Account Rep</td></tr>\n                          <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.ContactName), depth0))
    + "</strong></td></tr>\n";
},"107":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactPhone),{"name":"if","hash":{},"fn":container.program(108, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":24},"end":{"line":209,"column":31}}})) != null ? stack1 : "");
},"108":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Phone</td></tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\">\n                              <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent),{"name":"if","hash":{},"fn":container.program(109, data, 0),"inverse":container.program(111, data, 0),"data":data,"loc":{"start":{"line":201,"column":32},"end":{"line":205,"column":39}}})) != null ? stack1 : "")
    + "                              </strong>\n                            </td>\n                          </tr>\n";
},"109":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent), depth0))
    + "\n";
},"111":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(112, data, 0),"inverse":container.program(114, data, 0),"data":data,"loc":{"start":{"line":204,"column":34},"end":{"line":204,"column":176}}})) != null ? stack1 : "")
    + "\n";
},"112":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText), depth0))
    + " ";
},"114":function(container,depth0,helpers,partials,data) {
    return "303-848-8405 ";
},"116":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactEmail),{"name":"if","hash":{},"fn":container.program(117, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":213,"column":24},"end":{"line":226,"column":31}}})) != null ? stack1 : "");
},"117":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          <tr><td class=\"title\">Email</td></tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\">\n                              <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent),{"name":"if","hash":{},"fn":container.program(118, data, 0),"inverse":container.program(120, data, 0),"data":data,"loc":{"start":{"line":218,"column":32},"end":{"line":222,"column":39}}})) != null ? stack1 : "")
    + "                              </strong>\n                            </td>\n                          </tr>\n";
},"118":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent), depth0))
    + "\n";
},"120":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":221,"column":34},"end":{"line":221,"column":163}}})) != null ? stack1 : "")
    + "\n";
},"122":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceRepInfo : depths[2]),{"name":"if","hash":{},"fn":container.program(123, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":230,"column":24},"end":{"line":326,"column":31}}})) != null ? stack1 : "");
},"123":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                          <tr>\n                            <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISHPDIRECT),{"name":"if","hash":{},"fn":container.program(124, data, 0, blockParams, depths),"inverse":container.program(126, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":233,"column":30},"end":{"line":323,"column":37}}})) != null ? stack1 : "")
    + "                            </td>\n                          </tr>\n";
},"124":function(container,depth0,helpers,partials,data) {
    return "";
},"126":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISOTHERS),{"name":"if","hash":{},"fn":container.program(127, data, 0, blockParams, depths),"inverse":container.program(136, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":234,"column":30},"end":{"line":323,"column":30}}})) != null ? stack1 : "");
},"127":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                                  <tr class=\"element-header-type"
    + container.escapeExpression(container.lambda((depths[2] != null ? depths[2].templateType : depths[2]), depth0))
    + "\">\n                                    <td class=\"element-header-content\">\n                                      SOURCE ADDRESS    \n                                    </td>\n                                  </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.sourceAddr),{"name":"if","hash":{},"fn":container.program(128, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":261,"column":36},"end":{"line":263,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(130, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":267,"column":34},"end":{"line":269,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(132, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":270,"column":34},"end":{"line":272,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(134, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":273,"column":34},"end":{"line":275,"column":41}}})) != null ? stack1 : "")
    + "                                </table>\n";
},"128":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                      <tr><td style=\"padding: 2px 0; padding-bottom: 7px;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.sourceAddr), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"130":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"132":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"134":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"136":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                                  <tr class=\"element-header-type"
    + container.escapeExpression(container.lambda((depths[2] != null ? depths[2].templateType : depths[2]), depth0))
    + "\">\n                                    <td class=\"element-header-content\">\n                                      SOURCE ADDRESS\n                                    </td>\n                                  </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.sourceAddr),{"name":"if","hash":{},"fn":container.program(128, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":307,"column":36},"end":{"line":309,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(130, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":313,"column":34},"end":{"line":315,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(132, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":316,"column":34},"end":{"line":318,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(134, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":319,"column":34},"end":{"line":321,"column":41}}})) != null ? stack1 : "")
    + "                                </table>\n                              ";
},"138":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.shipAddress),{"name":"if","hash":{},"fn":container.program(139, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":330,"column":24},"end":{"line":359,"column":31}}})) != null ? stack1 : "");
},"139":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                          <tr>\n                            <td class=\"title\">Ship To</td>\n                          </tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\"><strong>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTO),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.program(71, data, 0),"data":data,"loc":{"start":{"line":335,"column":64},"end":{"line":335,"column":184}}})) != null ? stack1 : "")
    + "</strong></td>\n                          </tr>\n                          <tr>\n                            <td class=\"title\">Shipping Address</td>\n                          </tr>\n                          <tr>\n                            <td style=\"padding: 2px 0;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(140, data, 0),"inverse":container.program(142, data, 0),"data":data,"loc":{"start":{"line":342,"column":30},"end":{"line":356,"column":37}}})) != null ? stack1 : "")
    + "                            </td>\n                          </tr>\n";
},"140":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"142":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR),{"name":"if","hash":{},"fn":container.program(143, data, 0),"inverse":container.program(145, data, 0),"data":data,"loc":{"start":{"line":345,"column":32},"end":{"line":355,"column":39}}})) != null ? stack1 : "");
},"143":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                  "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPPINGTOADDR), depth0)) != null ? stack1 : "")
    + "\n";
},"145":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS),{"name":"if","hash":{},"fn":container.program(146, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":348,"column":34},"end":{"line":354,"column":41}}})) != null ? stack1 : "");
},"146":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                                    <strong>\n                                      "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AADDRESS), depth0))
    + "\n                                      <br />\n                                      "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ACITY), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ASTATE), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.AZIP), depth0))
    + "\n                                    </strong>\n";
},"148":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceOrderNumber : depths[2]),{"name":"if","hash":{},"fn":container.program(149, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":364,"column":24},"end":{"line":379,"column":31}}})) != null ? stack1 : "");
},"149":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.finalSourceOrderNumber),{"name":"if","hash":{},"fn":container.program(150, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":365,"column":26},"end":{"line":378,"column":33}}})) != null ? stack1 : "");
},"150":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda;

  return "                            <tr>\n                              <td>\n                                <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                                  <tr class=\"element-header-type"
    + container.escapeExpression(alias1((depths[2] != null ? depths[2].templateType : depths[2]), depth0))
    + "\">\n                                    <td class=\"element-header-content\">\n                                      SOURCE ORDER NUMBER\n                                    </td>\n                                  </tr>\n                                  <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = alias1(((stack1 = (data && data.root)) && stack1.finalSourceOrderNumber), depth0)) != null ? stack1 : "")
    + "</td></tr>\n                                </table>\n                              </td>\n                            </tr>\n";
},"152":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceQuoteNumber : depths[2]),{"name":"if","hash":{},"fn":container.program(153, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":384,"column":24},"end":{"line":399,"column":31}}})) != null ? stack1 : "");
},"153":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.INDIRECTQUOTEID),{"name":"if","hash":{},"fn":container.program(154, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":385,"column":26},"end":{"line":398,"column":33}}})) != null ? stack1 : "");
},"154":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda;

  return "                            <tr>\n                              <td>\n                                <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                                  <tr class=\"element-header-type"
    + container.escapeExpression(alias1((depths[2] != null ? depths[2].templateType : depths[2]), depth0))
    + "\">\n                                    <td class=\"element-header-content\">\n                                      SOURCE QUOTE NUMBER\n                                    </td>\n                                  </tr>\n                                  <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.INDIRECTQUOTEID), depth0)) != null ? stack1 : "")
    + "</td></tr>\n                                </table>\n                              </td>\n                            </tr>\n";
},"156":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_customerPO : depth0),{"name":"if","hash":{},"fn":container.program(92, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":408,"column":22},"end":{"line":413,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_orderNumber : depth0),{"name":"if","hash":{},"fn":container.program(95, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":415,"column":22},"end":{"line":420,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_orderDate : depth0),{"name":"if","hash":{},"fn":container.program(98, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":422,"column":22},"end":{"line":427,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_accountRep : depth0),{"name":"if","hash":{},"fn":container.program(104, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":429,"column":22},"end":{"line":434,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_phone : depth0),{"name":"if","hash":{},"fn":container.program(107, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":436,"column":22},"end":{"line":451,"column":29}}})) != null ? stack1 : "")
    + "                      \n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_email : depth0),{"name":"if","hash":{},"fn":container.program(116, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":453,"column":22},"end":{"line":468,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_sourceRepInfo : depth0),{"name":"if","hash":{},"fn":container.program(157, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":470,"column":22},"end":{"line":568,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_shipTo : depth0),{"name":"if","hash":{},"fn":container.program(138, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":570,"column":22},"end":{"line":601,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_sourceOrderNumber : depth0),{"name":"if","hash":{},"fn":container.program(148, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":604,"column":22},"end":{"line":621,"column":29}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.option_sourceQuoteNumber : depth0),{"name":"if","hash":{},"fn":container.program(152, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":624,"column":22},"end":{"line":641,"column":29}}})) != null ? stack1 : "");
},"157":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? depths[2].sourceRepInfo : depths[2]),{"name":"if","hash":{},"fn":container.program(158, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":471,"column":24},"end":{"line":567,"column":31}}})) != null ? stack1 : "");
},"158":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                          <tr>\n                            <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISHPDIRECT),{"name":"if","hash":{},"fn":container.program(124, data, 0, blockParams, depths),"inverse":container.program(159, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":474,"column":30},"end":{"line":564,"column":37}}})) != null ? stack1 : "")
    + "                            </td>\n                          </tr>\n";
},"159":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISOTHERS),{"name":"if","hash":{},"fn":container.program(160, data, 0, blockParams, depths),"inverse":container.program(136, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":475,"column":30},"end":{"line":564,"column":30}}})) != null ? stack1 : "");
},"160":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                                  <tr class=\"element-header-type"
    + container.escapeExpression(container.lambda((depths[2] != null ? depths[2].templateType : depths[2]), depth0))
    + "\">\n                                    <td class=\"element-header-content\">\n                                      SOURCE ADDRESS\n                                    </td>\n                                  </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.sourceAddr),{"name":"if","hash":{},"fn":container.program(128, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":502,"column":36},"end":{"line":504,"column":43}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(130, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":508,"column":34},"end":{"line":510,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(132, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":511,"column":34},"end":{"line":513,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(134, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":514,"column":34},"end":{"line":516,"column":41}}})) != null ? stack1 : "")
    + "                                </table>\n";
},"162":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "              <tr>\n                <td width=\"60%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.customerPO),{"name":"if","hash":{},"fn":container.program(163, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":651,"column":20},"end":{"line":654,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderNO),{"name":"if","hash":{},"fn":container.program(165, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":655,"column":20},"end":{"line":658,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.orderDate),{"name":"if","hash":{},"fn":container.program(167, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":659,"column":20},"end":{"line":662,"column":27}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n                <td width=\"40%\" valign=\"top\">\n                  <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showAccRep),{"name":"if","hash":{},"fn":container.program(169, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":667,"column":20},"end":{"line":670,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactPhone),{"name":"if","hash":{},"fn":container.program(171, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":671,"column":20},"end":{"line":684,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showContactEmail),{"name":"if","hash":{},"fn":container.program(176, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":685,"column":20},"end":{"line":698,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].sourceRepInfo : depths[1]),{"name":"if","hash":{},"fn":container.program(181, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":700,"column":20},"end":{"line":780,"column":27}}})) != null ? stack1 : "")
    + "                  </table>\n                </td>\n              </tr>\n";
},"163":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Customer PO </td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.POID), depth0))
    + "</strong></td></tr>\n";
},"165":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Order Number</td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PURCHASEID), depth0))
    + "</strong></td></tr>\n";
},"167":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Order Date</td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.PODATETIMEFORMATTED), depth0))
    + "</strong></td></tr>\n";
},"169":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Account Rep</td></tr>\n                      <tr><td style=\"padding: 2px 0;\"><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.VARCONTACT)) && stack1.ContactName), depth0))
    + "</strong></td></tr>\n";
},"171":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Phone</td></tr>\n                      <tr>\n                        <td style=\"padding: 2px 0;\">\n                          <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent),{"name":"if","hash":{},"fn":container.program(172, data, 0),"inverse":container.program(174, data, 0),"data":data,"loc":{"start":{"line":676,"column":28},"end":{"line":680,"column":35}}})) != null ? stack1 : "")
    + "                          </strong>\n                        </td>\n                      </tr>\n";
},"172":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactPhoneContent), depth0))
    + "\n";
},"174":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERPHONECONTACT)) && stack1.OrderContactPhoneValueText),{"name":"if","hash":{},"fn":container.program(112, data, 0),"inverse":container.program(114, data, 0),"data":data,"loc":{"start":{"line":679,"column":30},"end":{"line":679,"column":172}}})) != null ? stack1 : "")
    + "\n";
},"176":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      <tr><td class=\"title\">Email</td></tr>\n                      <tr>\n                        <td style=\"padding: 2px 0;\">\n                          <strong>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent),{"name":"if","hash":{},"fn":container.program(177, data, 0),"inverse":container.program(179, data, 0),"data":data,"loc":{"start":{"line":690,"column":28},"end":{"line":694,"column":35}}})) != null ? stack1 : "")
    + "                          </strong>\n                        </td>\n                      </tr>\n";
},"177":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contactEmailContent), depth0))
    + "\n";
},"179":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                              "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERCONTACT)) && stack1.OrderContactValue),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":693,"column":30},"end":{"line":693,"column":159}}})) != null ? stack1 : "")
    + "\n";
},"181":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                      <tr>\n                        <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISHPDIRECT),{"name":"if","hash":{},"fn":container.program(124, data, 0, blockParams, depths),"inverse":container.program(182, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":703,"column":26},"end":{"line":777,"column":33}}})) != null ? stack1 : "")
    + "                        </td>\n                      </tr>\n";
},"182":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.ISOTHERS),{"name":"if","hash":{},"fn":container.program(183, data, 0, blockParams, depths),"inverse":container.program(202, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":704,"column":26},"end":{"line":777,"column":26}}})) != null ? stack1 : "");
},"183":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                            <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                              <tr class=\"element-header-type"
    + alias2(alias1((depths[1] != null ? depths[1].templateType : depths[1]), depth0))
    + "\">\n                                <td class=\"element-header-content\">\n                                  "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0))
    + " REP AND INFO\n                                </td>\n                              </tr>\n                              <tr>\n                                <td><b>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME),{"name":"if","hash":{},"fn":container.program(184, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":712,"column":39},"end":{"line":712,"column":126}}})) != null ? stack1 : "")
    + "</b></td>\n                              </tr>\n                              <tr>\n                                <td><strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR1),{"name":"if","hash":{},"fn":container.program(186, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":715,"column":44},"end":{"line":715,"column":133}}})) != null ? stack1 : "")
    + "</strong></td>\n                              </tr>\n                              <tr>\n                                <td>\n                                  <strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR2),{"name":"if","hash":{},"fn":container.program(188, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":719,"column":42},"end":{"line":719,"column":131}}})) != null ? stack1 : "")
    + "</strong>\n                                </td>\n                              </tr>\n                              <tr>\n                                <td>\n                                  <strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYCITY),{"name":"if","hash":{},"fn":container.program(190, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":724,"column":42},"end":{"line":724,"column":129}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYSTATE),{"name":"if","hash":{},"fn":container.program(192, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":724,"column":129},"end":{"line":724,"column":225}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYZIP),{"name":"if","hash":{},"fn":container.program(194, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":724,"column":225},"end":{"line":724,"column":316}}})) != null ? stack1 : "")
    + "</strong>\n                                </td>\n                              </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(196, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":729,"column":30},"end":{"line":731,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(198, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":732,"column":30},"end":{"line":734,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(200, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":735,"column":30},"end":{"line":737,"column":37}}})) != null ? stack1 : "")
    + "                            </table>\n";
},"184":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYNAME), depth0));
},"186":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR1), depth0));
},"188":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYADDR2), depth0));
},"190":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYCITY), depth0));
},"192":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ",&nbsp;"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYSTATE), depth0));
},"194":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&nbsp;"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CUSTOMDISTYZIP), depth0));
},"196":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"198":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"200":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                <tr><td style=\"padding: 2px 0;\">"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone), depth0)) != null ? stack1 : "")
    + "</td></tr>\n";
},"202":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "                            <table width=\"100%\" cellspacing=\"0\" cellpadding=\"0\">\n                              <tr class=\"element-header-type"
    + alias2(alias1((depths[1] != null ? depths[1].templateType : depths[1]), depth0))
    + "\">\n                                <td class=\"element-header-content\">\n                                  "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VARSOURCE_NAME), depth0))
    + " REP AND INFO\n                                </td>\n                              </tr>\n                              <tr><td><b>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.NAME),{"name":"if","hash":{},"fn":container.program(203, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":746,"column":41},"end":{"line":746,"column":186}}})) != null ? stack1 : "")
    + "</b></td></tr>\n                              <tr><td><strong>"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ATTRN),{"name":"if","hash":{},"fn":container.program(208, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":747,"column":46},"end":{"line":747,"column":137}}})) != null ? stack1 : "")
    + "</strong></td></tr>\n                              <tr>\n                                <td>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS),{"name":"if","hash":{},"fn":container.program(210, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":750,"column":34},"end":{"line":752,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS1),{"name":"if","hash":{},"fn":container.program(212, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":753,"column":34},"end":{"line":756,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.PHONE),{"name":"if","hash":{},"fn":container.program(214, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":757,"column":34},"end":{"line":759,"column":41}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.EMAIL),{"name":"if","hash":{},"fn":container.program(216, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":760,"column":34},"end":{"line":762,"column":41}}})) != null ? stack1 : "")
    + "                                </td>\n                              </tr>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactName),{"name":"if","hash":{},"fn":container.program(196, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":767,"column":30},"end":{"line":769,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactEmail),{"name":"if","hash":{},"fn":container.program(198, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":770,"column":30},"end":{"line":772,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.salesContactPhone),{"name":"if","hash":{},"fn":container.program(200, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":773,"column":30},"end":{"line":775,"column":37}}})) != null ? stack1 : "")
    + "                            </table>\n                          ";
},"203":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.synnex),{"name":"if","hash":{},"fn":container.program(204, data, 0),"inverse":container.program(206, data, 0),"data":data,"loc":{"start":{"line":746,"column":83},"end":{"line":746,"column":179}}})) != null ? stack1 : "");
},"204":function(container,depth0,helpers,partials,data) {
    return "TD SYNNEX Corporation";
},"206":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.NAME), depth0)) != null ? stack1 : "");
},"208":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ATTRN), depth0)) != null ? stack1 : "");
},"210":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"212":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <br>\n                                    <strong>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.ADDRESS1), depth0)) != null ? stack1 : "")
    + "</strong>\n";
},"214":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <br>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.PHONE), depth0)) != null ? stack1 : "")
    + "\n";
},"216":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                    <br>"
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.VENDORINFO)) && stack1.EMAIL), depth0)) != null ? stack1 : "")
    + "\n";
},"218":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td width=\"30%\" class=\"title\">Notes</td></tr>\n              <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TEMPLATENOTES), depth0))
    + "</strong></td></tr>\n";
},"220":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPMENTNOTES),{"name":"if","hash":{},"fn":container.program(221, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":792,"column":14},"end":{"line":795,"column":21}}})) != null ? stack1 : "");
},"221":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td width=\"30%\" class=\"title\">Shipment Notes</td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.SHIPMENTNOTES), depth0))
    + "</strong></td></tr>\n";
},"223":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td width=\"30%\" class=\"title\">Contract Terms</td></tr>\n              <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMSBYORDER), depth0))
    + "</strong></td></tr>\n";
},"225":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMS),{"name":"if","hash":{},"fn":container.program(226, data, 0),"inverse":container.program(228, data, 0),"data":data,"loc":{"start":{"line":801,"column":12},"end":{"line":809,"column":12}}})) != null ? stack1 : "");
},"226":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <tr><td width=\"30%\" class=\"title\">Contract Terms</td></tr>\n              <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.FUNDINGSOURCETERMS), depth0))
    + "</strong></td></tr>\n";
},"228":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TERMS),{"name":"if","hash":{},"fn":container.program(229, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":805,"column":14},"end":{"line":808,"column":21}}})) != null ? stack1 : "")
    + "            ";
},"229":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td width=\"30%\" class=\"title\">Contract Terms</td></tr>\n                <tr><td><strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.TERMS), depth0))
    + "</strong></td></tr>\n";
},"231":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isCoreProduct : depth0),{"name":"if","hash":{},"fn":container.program(232, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":817,"column":4},"end":{"line":819,"column":11}}})) != null ? stack1 : "");
},"232":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./orderDetails_items.handlebars"),depth0,{"name":"orderDetails_items","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"234":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isFooterDetail : depth0),{"name":"if","hash":{},"fn":container.program(235, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":823,"column":4},"end":{"line":840,"column":11}}})) != null ? stack1 : "");
},"235":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showFooterDetail),{"name":"if","hash":{},"fn":container.program(236, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":824,"column":6},"end":{"line":826,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.signature : depth0),{"name":"if","hash":{},"fn":container.program(238, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":829,"column":6},"end":{"line":839,"column":13}}})) != null ? stack1 : "");
},"236":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./orderDetails_summary.handlebars"),depth0,{"name":"orderDetails_summary","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"238":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return "        <table width=\"100%\" id=\"quoteDetails_notes\">\n          <tr><td>&nbsp;</td></tr>\n          <tr>\n            <td width=\"60%\"></td>\n            <td width=\"40%\">\n              <img src=\""
    + alias1(((helper = (helper = helpers.signature || (depth0 != null ? depth0.signature : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"signature","hash":{},"data":data,"loc":{"start":{"line":835,"column":24},"end":{"line":835,"column":37}}}) : helper)))
    + "\" alt=\"signature\" style=\"width: 180px;\"/> "
    + alias1(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.ORDERDETAILS)) && stack1.CURRENTDATE), depth0))
    + "\n            </td>\n          </tr>\n        </table>\n";
},"240":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(241, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":845,"column":4},"end":{"line":851,"column":11}}})) != null ? stack1 : "")
    + "    <table width=\"100%\" class=\"element-table "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(243, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":853,"column":45},"end":{"line":853,"column":106}}})) != null ? stack1 : "")
    + "\">\n      <thead>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.headers : stack1),{"name":"each","hash":{},"fn":container.program(245, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":855,"column":8},"end":{"line":857,"column":17}}})) != null ? stack1 : "")
    + "      </thead>\n      <tbody>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.rows : stack1),{"name":"each","hash":{},"fn":container.program(247, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":860,"column":8},"end":{"line":866,"column":17}}})) != null ? stack1 : "")
    + "      </tbody>\n    </table>\n";
},"241":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <table width=\"100%\" class=\"element-header "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.templateType : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":846,"column":48},"end":{"line":846,"column":110}}})) != null ? stack1 : "")
    + " element-header-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":846,"column":126},"end":{"line":846,"column":132}}}) : helper)))
    + "\">\n        <tr>\n          <td class=\"element-header-content\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":848,"column":45},"end":{"line":848,"column":54}}}) : helper)))
    + "</td>\n        </tr>\n      </table>\n";
},"243":function(container,depth0,helpers,partials,data) {
    var helper;

  return "element-table-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":853,"column":83},"end":{"line":853,"column":99}}}) : helper)));
},"245":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <th>"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":856,"column":14},"end":{"line":856,"column":23}}}) : helper)))
    + "</th>\n";
},"247":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(248, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":862,"column":10},"end":{"line":864,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n";
},"248":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <td>"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":863,"column":16},"end":{"line":863,"column":25}}}) : helper)))
    + "</td>\n";
},"250":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "    <table width=\"100%\" id=\"quoteDetails_items\" style=\"padding-top: 0; padding-bottom: 0;\">\n      <tbody>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showEUs : depth0),{"name":"if","hash":{},"fn":container.program(251, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":875,"column":8},"end":{"line":905,"column":15}}})) != null ? stack1 : "")
    + "      </tbody>\n    </table>\n";
},"251":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.orderEUs),{"name":"if","hash":{},"fn":container.program(252, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":876,"column":10},"end":{"line":904,"column":17}}})) != null ? stack1 : "");
},"252":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <tr class=\"element-header-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":877,"column":42},"end":{"line":877,"column":58}}}) : helper)))
    + "\">\n              <td class=\"element-header-content\">\n                End Users\n              </td>\n            </tr>\n            <tr>\n              <td style=\"padding-top: 1px;\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (data && data.root)) && stack1.orderEUs),{"name":"each","hash":{},"fn":container.program(253, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":884,"column":16},"end":{"line":901,"column":25}}})) != null ? stack1 : "")
    + "              </td>\n            </tr>\n";
},"253":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                  <div "
    + ((stack1 = helpers["if"].call(alias1,(data && data.index),{"name":"if","hash":{},"fn":container.program(254, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":885,"column":23},"end":{"line":885,"column":68}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euName : depths[1]),{"name":"if","hash":{},"fn":container.program(256, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":886,"column":20},"end":{"line":888,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euEmail : depths[1]),{"name":"if","hash":{},"fn":container.program(260, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":889,"column":20},"end":{"line":891,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euPhone : depths[1]),{"name":"if","hash":{},"fn":container.program(262, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":892,"column":20},"end":{"line":899,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n";
},"254":function(container,depth0,helpers,partials,data) {
    return "style=\"margin-top: 3px;\"";
},"256":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                      <span>"
    + container.escapeExpression(((helper = (helper = helpers.EUNAME || (depth0 != null ? depth0.EUNAME : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"EUNAME","hash":{},"data":data,"loc":{"start":{"line":887,"column":28},"end":{"line":887,"column":38}}}) : helper)))
    + "</span>"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euEmail : depths[1]),{"name":"if","hash":{},"fn":container.program(257, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":887,"column":45},"end":{"line":887,"column":93}}})) != null ? stack1 : "")
    + "\n";
},"257":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.EMAIL : depth0),{"name":"if","hash":{},"fn":container.program(258, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":887,"column":63},"end":{"line":887,"column":86}}})) != null ? stack1 : "");
},"258":function(container,depth0,helpers,partials,data) {
    return " - ";
},"260":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                      <span>"
    + container.escapeExpression(((helper = (helper = helpers.EMAIL || (depth0 != null ? depth0.EMAIL : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"EMAIL","hash":{},"data":data,"loc":{"start":{"line":890,"column":28},"end":{"line":890,"column":37}}}) : helper)))
    + "</span>\n";
},"262":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].euName : depths[1]),{"name":"if","hash":{},"fn":container.program(263, data, 0, blockParams, depths),"inverse":container.program(265, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":893,"column":22},"end":{"line":897,"column":29}}})) != null ? stack1 : "")
    + "                      <span>"
    + container.escapeExpression(((helper = (helper = helpers.PHONE || (depth0 != null ? depth0.PHONE : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"PHONE","hash":{},"data":data,"loc":{"start":{"line":898,"column":28},"end":{"line":898,"column":37}}}) : helper)))
    + "</span>\n";
},"263":function(container,depth0,helpers,partials,data) {
    return "                        - \n";
},"265":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].euEmail : depths[1]),{"name":"if","hash":{},"fn":container.program(266, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":895,"column":22},"end":{"line":897,"column":22}}})) != null ? stack1 : "");
},"266":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.EMAIL : depth0),{"name":"if","hash":{},"fn":container.program(258, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":896,"column":24},"end":{"line":896,"column":47}}})) != null ? stack1 : "")
    + "\n                      ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.TemplateSetting : depth0)) != null ? stack1.templateItemReorder : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":909,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});