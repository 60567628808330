import { render, staticRenderFns } from "./InvoiceDetails.vue?vue&type=template&id=4f46b910&scoped=true"
import script from "./InvoiceDetails.vue?vue&type=script&lang=tsx"
export * from "./InvoiceDetails.vue?vue&type=script&lang=tsx"
import style0 from "./InvoiceDetails.vue?vue&type=style&index=0&id=4f46b910&prod&scoped=true&lang=less"
import style1 from "./InvoiceDetails.vue?vue&type=style&index=1&id=4f46b910&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f46b910",
  null
  
)

export default component.exports