import { render, staticRenderFns } from "./HardwareDetails.vue?vue&type=template&id=55bbef51&scoped=true"
import script from "./HardwareDetails.vue?vue&type=script&lang=tsx"
export * from "./HardwareDetails.vue?vue&type=script&lang=tsx"
import style0 from "./HardwareDetails.vue?vue&type=style&index=0&id=55bbef51&prod&scoped=true&lang=less"
import style1 from "./HardwareDetails.vue?vue&type=style&index=1&id=55bbef51&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55bbef51",
  null
  
)

export default component.exports