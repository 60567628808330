














































































































































































































































































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

import {
  InvoiceAPIRepo,
  DepositFilters,
  DepositSort,
  DepositSortField
} from "../repos/InvoiceAPIRepo";
import { notifier, wait } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import Loader from './Loader.vue';
import moment from "moment";
import Datepicker from "./Datepicker.vue";
import Pagination from "../components/Pagination.vue";

interface Props {}

interface Events {
  onClose: void;
  onGenerated: void;
}

const invoiceDepositRepo = new InvoiceAPIRepo();
declare const $: any;
declare const dataURL: string;
declare const getMaxRows: Function;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    ConfirmRemoveItemModal,
    FilterCurrencyBox,
    Loader,
    Pagination,
    Datepicker
  },
})

export default class InvoiceDepositModal extends TSXComponent<Props, Events> {
  @Prop({required: true})
  title!: string

  $router: any;
  $parent: any;
  $validator: any;
  loading = false;
  confirmDepositGenerate = false;
  depositLoading = false;
  noOfDeposits = 0;
  depositName = "";
  pageNumber = 1;
  rowsPerPage = 35;
  totalPages = 1;
  depositDetails:any = [];
  selectedID: (number | undefined)[] = [];
  excludedIDs: number[] = [];
  selectedAll = false;
  selectPageAll = false;
  showFilter = 0;
  selectedPage = 0;
  newPageDeposit: (number | undefined)[] = [];
  totalDeposit: (number | undefined)[] = [];
  selectedPaymentTotal = 0;
  selectedIdPaymentArr:any = [];
  firstClick = true;
  editedDepositDetails: any = [];
  disableClick = true;

  $refs!: {
    modal: HTMLDivElement;
    table: HTMLDivElement;
  };

  depositFilters: DepositFilters = {
    depositSearchId: "",
    depositTotalMin: undefined,
    depositTotalMax: undefined,
    depositStartDate: undefined,
    depositEndDate: undefined, 
    depositStatus: [0,2,3],
    depositCustomer: ""
  };
  depositSort: DepositSort = {
    field: null,
    direction: {
      [DepositSortField.depositSearchId]: 1,
      [DepositSortField.depositTotal]: 1,
      [DepositSortField.depositDate]: 1,
      [DepositSortField.depositCustomer]: 1
    }
  };
  today = new Date();
  paymentDate = (this.today.getMonth()+1 < 10 ? "0" : "") +(this.today.getMonth() + 1) + "/" + (this.today.getDate() < 10 ? "0" : "") + this.today.getDate() + "/" + this.today.getFullYear();
  statusArr: any = [];

  async mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
    $(this.$refs.modal).on('hidden.bs.modal', () => {
      if ($('.modal.show:visible').length) {
        $('body').addClass('modal-open');
      }
    });
  }

 async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      const ret = await invoiceDepositRepo.findDepositDetails(
        this.depositFilters,
        this.depositSort,
        this.editedDepositDetails
      );
      this.depositDetails = ret.DEPOSITOVERVIEW;
      this.statusArr = ret.statusArr || [];
      const total = (this.depositDetails || []).length;
      this.totalPages = Math.ceil(total/this.rowsPerPage);
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
    if (this.selectedAll === true) {
      this.selectAll(true);
    }
    var checkAllPages = this.newPageDeposit.includes(this.pageNumber);
    if (checkAllPages)  {
      this.totalDeposit = [];
      for (var val of this.depositDetails) {
        if (!this.excludedIDs.includes(val.INVOICEID)) {
          this.totalDeposit.push(val.INVOICEID);
        }
      }
      var intersectingArrays = this.getArraysIntersection(this.totalDeposit, this.selectedID);
      if (intersectingArrays.length == this.depositDetails.length) {
        this.depositCheckAll = true;
      } else {
        this.depositCheckAll = false;
      }
    }
    else {
      this.depositCheckAll = false;
    }
    await wait(500);
    this.disableClick = true;
  }

  beforeDestroy() {
    $('.datepicker-dropdown:not(.datepicker-hide)').addClass('datepicker-hide');
    $(this.$refs.modal).modal("hide");
  }

  async searchById() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.depositFilters.depositSearchId == "")
      return;
    else
      await this.fetchData();
  }
  async resetSearchById() {
    this.pageNumber = 1;
    this.depositFilters.depositSearchId = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }
  
  async searchByTotal() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }
  async resetSearchByTotal() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    this.depositFilters.depositTotalMin = "";
    this.depositFilters.depositTotalMax = "";
    await this.fetchData();
  }  

  async searchByDueDate() {
    this.pageNumber = 1;
    if (
      (this.depositFilters.depositStartDate == undefined || this.depositFilters.depositStartDate == "")
      && (this.depositFilters.depositEndDate == "" || this.depositFilters.depositEndDate == undefined)
    ) {
      notifier.alert("Please fill From or To Date");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      await this.fetchData();
    }
  }
  async resetSearchByDueDate() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (
      (this.depositFilters.depositStartDate == undefined || this.depositFilters.depositStartDate == "")
      && (this.depositFilters.depositEndDate == "" || this.depositFilters.depositEndDate == undefined)
    ) {
      return;
    } else {
      this.depositFilters.depositStartDate = "";
      this.depositFilters.depositEndDate = "";
      await this.fetchData();
    }
  }

  async checkStatusList(depositInvoiceStatus: number) {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    const i = this.depositFilters.depositStatus.findIndex( depositStatus => depositStatus === depositInvoiceStatus);
    if (i === -1) {
      this.depositFilters.depositStatus.push(depositInvoiceStatus);
    } else {
      this.depositFilters.depositStatus.splice(i, 1);
    }
    await this.fetchData();
  }
  async checkAllStatusList() {
    this.selectedAll = false;
    this.excludedIDs = [];
    this.depositFilters.depositStatus = [0, 2, 3];
    this.selectedID = [];
    await this.fetchData();
  }
  async unCheckAllStatusList() {
    this.selectedAll = false;
    this.excludedIDs = [];
    this.depositFilters.depositStatus = [];
    this.selectedID = [];
    await this.fetchData();
  }
  async resetStatusList() {
    // this.depositFilters.depositStatus = [];
    // this.selectedAll = false;
    // this.excludedIDs = [];
    // await this.fetchData();
    this.checkAllStatusList();
  }
  async searchByCustomer() {
    this.pageNumber = 1;
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.depositFilters.depositCustomer == "")
      return;
    else
      await this.fetchData();
  }
  async resetSearchByCustomer() {
    this.pageNumber = 1;
    this.depositFilters.depositCustomer = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async sortBy(field: DepositSortField) {
    this.depositSort.field = field;
    this.depositSort.direction[field] = this.depositSort.direction[field] === 1 ? 2 : 1;
    for (const dirField in this.depositSort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }
      this.depositSort.direction[dirField] = 1;
    }
    await this.fetchData();
  }
  async sortingByInvoiceID() {
    await this.sortBy(DepositSortField.depositSearchId);
  }
  async sortingByTotal() {
    await this.sortBy(DepositSortField.depositTotal);
  }
  async sortingByDueDate() {
    await this.sortBy(DepositSortField.depositDate);
  }  
  async sortingByCustomer() {
    await this.sortBy(DepositSortField.depositCustomer);
  }  

  depositCheckAll = false;
  depositCheckAllChange() {
    this.selectedPaymentTotal = 0;
    this.depositCheckAll = !this.depositCheckAll;
    if (!this.depositCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
      this.sumCheckedPayment(this.depositDetails);
    }
  }

  sumCheckedPayment(element){
    element.forEach(item => {
      this.selectedPaymentTotal += parseFloat(item.PAYMENTAMOUNT);
    });
  }

  selectInPage(selectedAllPage = true) {
    this.selectPageAll = selectedAllPage;
    this.showFilter = 0;
    if (this.selectPageAll == true) {
      this.selectedPage = this.pageNumber
      this.newPageDeposit.push(this.selectedPage);
      for (var depositDetail of this.depositDetails) {
        if(this.selectedID.indexOf(depositDetail.INVOICEID) == -1) {
          this.selectedID.push(depositDetail.INVOICEID);
          this.totalDeposit.push(depositDetail.INVOICEID);
          var invoiceDetails = {"INVOICEID": depositDetail.INVOICEID, "PAYMENTAMOUNT": depositDetail.PAYMENTAMOUNT, "PAYMENTTYPE": depositDetail.PAYMENTTYPE, "PAYMENTREFERENCE": depositDetail.PAYMENTREFERENCE, "PROFITTOTAL": depositDetail.PROFITTOTAL, "PURCHASEID": depositDetail.PURCHASEID};
          this.selectedIdPaymentArr.push(invoiceDetails);
        }
      }
    } else {
      for (var depositDetail of this.depositDetails) {
        let selectedIDIndex = this.selectedID.indexOf(depositDetail.INVOICEID);
        let totalLedgersIndex = this.totalDeposit.indexOf(depositDetail.INVOICEID);
        this.selectedID.splice(selectedIDIndex, 1);
        this.totalDeposit.splice(totalLedgersIndex, 1);
      }
      let newPagePaymentIndex = this.newPageDeposit.indexOf(this.pageNumber)
      this.newPageDeposit.splice(newPagePaymentIndex, 1);
      this.selectedIdPaymentArr = [];
    }
  }

  checkExcludedIDs(depositDetails, key, index) {
    if (this.selectedAll || this.selectPageAll) {
      for (var depositDetail of depositDetails) {
        if (!this.selectedID.includes(depositDetail[key]) && !this.excludedIDs.includes(depositDetail[key]) ) {
          this.excludedIDs.push(depositDetail[key]);
          this.depositCheckAll = false;
        } else if ( this.excludedIDs.includes(depositDetail[key]) ) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex((excludedID) => excludedID == depositDetail[key])
          );
        }
      }
    }
    this.depositCheckAll = false;
    if( this.selectedID.length == this.totalDeposit.length && this.selectedID.length !=0 ) {
      this.depositCheckAll = true;
    }

    const selectedIndex = this.selectedID.findIndex(id => id === depositDetails[index].INVOICEID);
    var invoiceID = depositDetails[index].INVOICEID
    var checkInvoiceID = this.selectedIdPaymentArr.findIndex((s) => s.INVOICEID === invoiceID);

    if (selectedIndex === -1)
      this.selectedIdPaymentArr.splice(checkInvoiceID, 1);
    else {
      var invoiceDetails = {"INVOICEID": invoiceID, "PAYMENTAMOUNT": depositDetails[index].PAYMENTAMOUNT, "PAYMENTTYPE": depositDetails[index].PAYMENTTYPE, "PAYMENTREFERENCE": depositDetails[index].PAYMENTREFERENCE, "PROFITTOTAL": depositDetails[index].PROFITTOTAL, "PURCHASEID": depositDetails[index].PURCHASEID};

      if(checkInvoiceID === -1)
        this.selectedIdPaymentArr.push(invoiceDetails);
      else
        this.selectedIdPaymentArr[checkInvoiceID] = invoiceDetails;
    }
    var total = 0;
    this.selectedIdPaymentArr.forEach(x => {
      total += parseFloat(x.PAYMENTAMOUNT);
    });
    this.selectedPaymentTotal = total;
  }

  selectAll(selectedAll = true) {
    this.depositCheckAll = false;
    this.excludedIDs = [];
    this.selectedAll = selectedAll;
    this.selectedID = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (var depositDetail of this.depositDetails) {
        if (!this.excludedIDs.includes(depositDetail.INVOICEID)) {
          this.selectedID.push(depositDetail.INVOICEID);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }  
  getArraysIntersection(getDepositOne,getDepositTwo) {
    return getDepositOne.filter( deposit => { return getDepositTwo.indexOf( deposit ) !== -1; } );
  }

  get generateBtnEnabled(): boolean {
    return this.selectedID.length > 0 ? true : false;
  }
  async generateConfirmation() {
    var isValid = true;
    // remove html for payment ref
    this.$validator.errors.clear();
    await this.$validator.validateAll().then(result => {
      // check payment date format
      let validPaymentDate = true;
      if(this.paymentDate !="" && !moment(this.paymentDate, "MM/DD/YYYY", true).isValid()) {
        validPaymentDate = false;
        this.$validator.errors.add({
          field: "paymentDate",
          msg: "Invalid Date (Date must be in the format mm/dd/yyyy)"
        });
      }
      if(!result || !validPaymentDate) {
        isValid = false;
      }
    });
    if (!isValid) {
      return;
    } else {
      this.confirmDepositGenerate = true;
      this.noOfDeposits = this.selectedID.length;
    }
  }
  async generate() {
    
    this.depositLoading = true;
    try {
      const payment = {
        controller: "Invoices",
        FunctionName: "invoicesDeposit",
        action: "depositUpdate",
        invoiceId: this.selectedID.join(","),
        depositName: this.depositName,
        depositDetails: this.selectedIdPaymentArr,
        paymentAmount: this.selectedPaymentTotal,
        paymentDate: this.paymentDate
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", payment);
      this.depositLoading = false;
      if (response.data.STATUS == 1) {
        if (response.data.backgroundProcessing) {
          notifier.success(response.data.STATUSMESSAGE);
        }
        this.$emit("close");
        if (this.$parent.$route.name == 'invoiceDeposits') {
          this.$parent.fetchData();
        } else {
          this.$router.push({name:"invoiceDeposits"});
        }
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.depositLoading = false;
    }
  }

  async sorting() {
    if (!this.disableClick) {
      return;
    }
    this.disableClick = false;
    if(this.selectedID.length && this.firstClick) {
      var arr: any = [];
      var id = this.selectedID;
      var details = this.depositDetails
      for (let i=0; i < (this.depositDetails.length); i++) {
        if($.inArray(this.depositDetails[i].INVOICEID,id) != -1) {
          arr.push(this.depositDetails[i]);
          details.splice(i,1);
          i--;
        }
      }
      arr.reverse().forEach(item => {
        details.splice(0, 0, item);
      });
      this.depositDetails = details;
      this.firstClick = false;
      await wait(500);
      this.disableClick = true;
    }
    else {
      this.firstClick = true;
      await this.fetchData();
    }
  }
  getEditedDetail(depositDetail) {
    var editedDetailsIndex = this.editedDepositDetails.findIndex(editedDetail => editedDetail.INVOICEID === depositDetail.INVOICEID);
    if(editedDetailsIndex != -1) {
      this.editedDepositDetails.splice(editedDetailsIndex, 1);
    }
    this.editedDepositDetails.push(depositDetail);
  }
  clickOutside() {
    this.$emit("close");
  }


  async loadPage(page: number, type: "prev" | "next" | "") {

    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    let isValid = true;
    if (this.pageNumber < 1) {
      this.pageNumber = 1;
      isValid = false;
    }
    if (this.pageNumber > this.totalPages) {
      this.pageNumber = this.totalPages;
      isValid = false;
    }
    if (isValid) {
      this.loading = true;
      if (this.pageNumber <= this.totalPages && this.pageNumber >= 1) {
        // await this.fetchData();
      }
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

}
