
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Model, Emit, Inject } from "vue-property-decorator";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import { Validator } from "vee-validate";

Validator.extend("phoneInput", {
  getMessage(field, args) {
    return "Invalid Phone Number.";
  },
  validate(value, args) {
    if (!Array.isArray(args)) {
      throw new Error("unique name is required");
    }
    return instances[args[0]];
  }
});

interface Props {
  value: string;
  name: string;
  placeholder: string;
  countryCode: string;
}

interface Events {
  onInput: string;
}

interface PhoneUpdatePayl {
  countryCode: string;
  isValid: boolean;
  phoneNumber: string;
  countryCallingCode: string;
  formattedNumber: string;
  nationalNumber: string;
  formatInternational: string;
  formatNational: string;
  uri: string;
  e164: string;
}

const instances: { [name: string]: boolean } = {};

@Component({
  inheritAttrs: true,
  components: {},
  inject: ["$validator"]
})
export default class PhoneInput extends TSXComponent<Props, Events, PhoneUpdatePayl> {
  mounted() {
    // Get the input element.
    const inputElement = this.$el.querySelector('input.input-tel__input');
    if (inputElement) {
      // Add an event listener for the blur event.
      $(document).on('blur', 'input.input-tel__input', this.onUpdateAndChange);
    }
  }

  @Model("input", {
    type: String,
    required: true
  })
  readonly value!: string;

  @Prop({ required: true })
  readonly name!: string;

  @Prop({ required: true })
  placeholder!: string;

  @Prop({ required: false, default :'US' })
  countryCode!: string;

  constructor() {
    super();
    instances[this.name] = true;
  }

  onInput(value: string | null) {
    value = value || "";
    var oldValue = this.value || "";

    if (oldValue.trim() === value.trim()) {
      return;
    }
    if (oldValue.length < 20) {
      value = value.substring(0, 19)
    }
    this.$emit("input", value);
  }

  onUpdateAndChange(event) {
    if(typeof($(".endUsers .selected-text.textOverflow").html()) != 'undefined'){
      this.$emit("updateNewContact");
    }
  }

  onUpdate(payl: PhoneUpdatePayl) {
    this.$emit("update", payl);
    if(payl.phoneNumber){
      instances[this.name] = payl.isValid;
    }
  }

  onBlur(test) {
    this.$emit("blur");
  }

  render() {
    return (
      <VuePhoneNumberInput
        // class="VuePhoneNumberInput"
        color="#747474"
        valid-color="#747474"
        error-color="orangered"
        no-validator-state={false}
        no-country-selector={false}
        no-example={false}
        default-country-code={this.countryCode}
        translations={{
          countrySelectorLabel: "",
          // countrySelectorError: 'Choisir un pays',
          phoneNumberLabel: this.placeholder,
          example: "Example :"
        }}
        value={this.value}
        border-radius={0}
        onInput={this.onInput}
        onUpdate={this.onUpdate}
        onPhone-number-blur={this.onBlur}
      />
    );
  }
}
