















































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import Datepicker from "@/components/Datepicker.vue";
import { FinancialBoardsAPIRepo } from "@/repos/FinancesDashboardAPIRepo";
import { notifier } from "@/models/common";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

declare const $: any;

const financialBoardsRepo = new FinancialBoardsAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    Datepicker
  }
})

export default class ExpensesModal extends TSXComponent<void> {

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: [] })
  expenses!: any;

  @Prop({ required: false, default: "" })
  selectedExpensesMonthAsString!: string;

  @Prop({ required: false, default: "" })
  selectedExpensesTypeName!: string;

  @Prop({ required: false, default: new Date().getFullYear() })
  year!: number;

  @Prop({ required: false, default: 0 })
  month!: number;

  @Prop({ required: false, default: "" })
  boardUUID!: string;

  @Prop({ required: false, default: "" })
  expenseTypeUUID!: string;

  $validator: any;
  saving: boolean = false;
  expensesData: any[] = [];
  expensesDataBK: any[] = [];
  startDate: string = "";
  endDate: string = "";
  datePickerKey: string = uuidv4();

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  created() {
    this.expensesData = JSON.parse(JSON.stringify(this.expenses));
    this.addInputRow(this.expensesData.length);
    this.expensesDataBK = JSON.parse(JSON.stringify(this.expensesData));
    this.startDate = moment(new Date(this.year, this.month-1, 1)).format('MM/DD/YYYY');
    this.endDate = moment(new Date(this.year, this.month, 0)).format('MM/DD/YYYY');
  }

  isRequired(item) {
    if (
      item.EXPENSENAME.length ||
      item.EXPENSEDATE.length ||
      (item.EXPENSEAMOUNT != 0 && item.ISDOLLARAMOUNT) ||
      (item.EXPENSEPERCENTAGE != 0 && !item.ISDOLLARAMOUNT)
    ) {
      return "required";
    }
    return "";
  }

  addInputRow(index) {
    if (index === this.expensesData.length) {
      var expensesNew = {
        EXPENSEUUID: "",
        EXPENSENAME: "",
        EXPENSEDATE: '',
        EXPENSEAMOUNT: 0,
        ISDOLLARAMOUNT: true,
        EXPENSEPERCENTAGE: 0
      };
      this.expensesData.push(expensesNew);
    }
  }

  deleteExpenses(index) {
    this.$delete(this.expensesData, index);
  }

  get expensesEdited() {
		return JSON.stringify(this.expensesData) != JSON.stringify(this.expensesDataBK);
	}

  async save() {
    var result = await this.$validator.validateAll();
		if (!result) {
			return;
		}

    let expensesData = this.expensesData.filter((item: any) => item.EXPENSENAME.length);
    
    if (this.boardUUID.length) {
      this.saving = true;
  
      const response: any = await financialBoardsRepo.addExpenses(
        this.boardUUID,
        expensesData,
        this.month,
        this.year,
        this.expenseTypeUUID
      );
  
      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        this.$emit("reload");
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
      this.saving = false;
    }
  }
}
