




























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { FinancialBoardsAPIRepo } from "@/repos/FinancesDashboardAPIRepo";

const financialBoardsRepo = new FinancialBoardsAPIRepo();

@Component({
  inheritAttrs: true,
  components: {}
})

export default class EmployeePlanTooltip extends TSXComponent<void> {

  @Prop({ required: false, default: '' })
  boardUUID!: string;

  @Prop({ required: false, default: new Date().getFullYear() })
  year!: number | string;

  loading: boolean = false;
  empPlanData: any[] = [];

  created() {
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    const response: any = await financialBoardsRepo.findEmpPlanDetails(
      this.boardUUID,
      this.year
    );

    if (response.STATUS === 1) {
      this.empPlanData = response.BOARDEMPPLANS || [];
    }
    this.loading = false;
  }

  clickOutside() {
    this.$emit("close");
  }

}
