






















































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "./LaddaButton.vue";

interface Props {
  purchaseID: number;
  details?: any;
  aID?: number;
  type?: string;
  forceProcess?: boolean;
  showNotes?: boolean;
  showSubmitBtn?: boolean;
}

interface Events {}

declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton
  }
})
export default class CategorySKUModal extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: {} })
  skus?: any;

  $refs!: {
    modal: HTMLDivElement;
  };

  saving: boolean | "error" = false;
  loading = false;
  removing = false;
  fullCatList: any = [];

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  async created() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Helpers",
      FunctionName: "Category",
      ignoreConfig: true
    });
    if (response.data.STATUS == 1) {
      this.fullCatList = response.data.CATEGORY;
    }

    const configCat = this.fullCatList.find(
      cat => cat.CATEGORYNAME.toUpperCase() == "CONFIG"
    );

    var index = 0;
    for (const i in this.skus) {
      const item = this.skus[i];
      if ((item.ItemCategory || 0) == 0) {
        item.ItemCategory = 0;
        item.ItemCategoryName = "";
        // Set config category
        if (
          index &&
          configCat &&
          item.sku.length &&
          item.sku
            .split("#")[0]
            .slice(-2)
            .toUpperCase() == "AV"
        ) {
          item.ItemCategory = configCat.CATEGORYID;
          item.ItemCategoryName = configCat.CATEGORYNAME;
        }
        // Set config category for included items
        // if (index && configCat && item.included) {
        //   item.ItemCategory = configCat.CATEGORYID;
        //   item.ItemCategoryName = configCat.CATEGORYNAME;
        // }
      }

      // make sure set config category for config item
      if (configCat && (item.ISCONFIG || 0)) {
        item.ItemCategory = configCat.CATEGORYID;
        item.ItemCategoryName = configCat.CATEGORYNAME;
      }

      index++;
    }
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  close() {
    this.$emit("close");
  }

  afterShowConfirm() {
    $("body .modal-backdrop.show:not(:first)").css("background", "none");
  }

  updateItemCategory(item: any, index) {
    const inList = this.fullCatList.find(
      cat => cat.CATEGORYID == item.ItemCategory
    );
    if (inList) {
      item.ItemCategoryName = inList.CATEGORYNAME;
    } else {
      item.ItemCategory = 0;
      item.ItemCategoryName = "";
    }
    this.$forceUpdate();
    this.$emit("updateItemCategory", index, {
      ItemCategory: item.ItemCategory,
      ItemCategoryName: item.ItemCategoryName
    });
  }

  hasItemHasNoCategory() {
    let found = false;
    for (const i in this.skus) {
      if (this.skus[i].sku != "" && (this.skus[i].ItemCategory || 0) == 0) {
        found = true;
      }
    }
    if (found) {
      return true;
    }

    return false;
  }

  saveOrder() {
    const parent: any = this.$parent;
    if (parent) {
      parent.validateBeforeSubmit();
    }
    this.$emit("close");
  }
}
