







































































































































































































































































































































































































































































































































































































































































































































































































































import {Component as TSXComponent} from "vue-tsx-support";
import {Component, Prop} from "vue-property-decorator";
import SnoozeTooltip from "@/components/Log/SnoozeTooltip.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import Vue from "vue";
import {ApiHelper} from "@/helpers/all";
import {notifier} from "@/models/common";
import Pagination from "@/components/Pagination.vue";
import CustomAlertModal from "@/components/Alert/CustomAlertModal.vue";
import ModifyAlertDetailsModal from "@/components/ModifyAlertDetailsModal.vue";
import {colsResizeable, getDateRangeFilter} from "@/helpers/ApiHelper";

declare function require(params: any): any;

declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    SnoozeTooltip,
    ListHeaderActions,
    Pagination,
    ModifyAlertDetailsModal,
    CustomAlertModal
  },
  methods: {
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class Alerts extends TSXComponent<void> {
  @Prop({required: false, default: 0})
  defaultHeight?: number;

  @Prop({required: false, default: false})
  loadFromCache?: boolean;

  activeTab: string = 'alerts';
  thisType = this.capitalize(this.activeTab);
  Filters = `${this.thisType}Filters`;
  Sort = `${this.activeTab}Sort`
  searchFilters = `search${this.thisType}Filters`;
  currentPage = `current${this.thisType}Page`;
  alertsType: number[] = [1, 2, 3];
  $store: any;
  alerts: any = this.$store.state.alerts || [];
  logs: any = this.$store.state.logs || [];
  status: any = this.$store.state.status || [];
  sourcesObj: any = this.$store.state.sourcesObj || [];
  alertPages: any = Math.ceil(this.$store.state.totalAlerts / 20);
  tickcounts: any = '';
  statusPages: any = this.$store.state.totalStatus;
  logPages: any = this.$store.state.totalLogs;
  currentAlertPage: any = 1;
  currentStatusPage: any = 1;
  currentLogsPage: any = 1;
  loading: boolean = false;
  searchAlertsFilters = {
    Id: "",
    Customer: "",
    CreatedBy: "",
    Name: "",
    PO: "",
    Type: "",
    User: "",
    sDate: undefined,
    eDate: undefined
  }
  alertsSort = {
    field: 0,
    direction: {
      Id: 1,
      Name: 1,
      Customer: 1,
      User: 1,
      DueDate: 1
    }
  };
  searchLogsFilters = {
    Id: "",
    Details: "",
    Customer: "",
    User: "",
    sDate: undefined,
    eDate: undefined
  }
  logsSort = {
    field: 0,
    direction: {
      Id: 1,
      Details: 1,
      Customer: 1,
      User: 1,
      DueDate: 1
    }
  };
  searchStatusFilters = {
    Id: "",
    Name: "",
    PO: "",
    sDate: undefined,
    eDate: undefined
  };
  statusSort = {
    field: 0,
    direction: {
      Id: 1,
      Details: 1,
      Customer: 1,
      User: 1,
      DueDate: 1
    }
  };
  snoozeTooltipIndex = -1;
  isChecked = Vue.prototype.$dueDateAlerts;
  isDisabled = false;
  AlertsFilters: any = {
    Id: "",
    Customer: "",
    CustomerIds: [],
    CreatedBy: "",
    CreatedByIds: [],
    Name: "",
    Type: "",
    PO: "",
    User: "",
    sDate: undefined,
    eDate: undefined,
    alertsType: ['Quotes', 'Orders', 'Invoices']
  };
  LogsFilters = {
    Id: "",
    Details: "",
    Customer: "",
    User: "",
    sDate: undefined,
    eDate: undefined
  };
  StatusFilters: any = {
    Id: "",
    Name: "",
    Customer: "",
    sourceName: [],
    sDate: undefined,
    eDate: undefined,
    sSource: []
  };
  hasChangedSourceFilters = false;
  showModifyAlertModalFlg = false;
  selectedAlert: any;
  sourceType = "alert_list";
  customAlerts: any[] = [];

  selectedCustomAlertHeaders = [
    "ALERTNAME",
    "CONTENT",
    "ALERTTYPE",
    "CUSTOMERS"
  ];

  customAlertPageHeaders = {
    ALERTID: 'ID',
    ALERTNAME: 'Name',
    CONTENT: 'Details',
    ALERTTYPE: 'Type',
    CUSTOMERS: 'Customers'
  };

  currentCustomAlertPage: number = 1;
  customAlertPageTotal: number = 1;
  customAlertModalVisible = false;
  selectedCustomAlert: any = {};

  customAlertsFilters: any = {
    name: ""
  };
  searchCustomAlertsFilters: any = {
    name: ""
  };

  capitalize(str) {
    if (!str || typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // displayPages(currentPage, type) {
  //   let pagesToShow:any = [];

  //   let totalPages;
  //   if (type === 'alerts') {
  //       totalPages = this.alertPages;
  //   } else if (type === 'logs') {
  //       totalPages = this.logPages;
  //   } else if (type === 'status') {
  //       totalPages = this.statusPages;
  //   } else {
  //       console.error('Invalid type:', type);
  //       return pagesToShow;
  //   }

  //   if (totalPages <= 6) {
  //       // If total pages are 6 or less, display them all
  //       for (let i = 1; i <= totalPages; i++) {
  //           pagesToShow.push(i);
  //       }
  //   } else {
  //       // Handle the initial case and when the current page is 1 or 2
  //       if (currentPage <= 3) {
  //           pagesToShow = [1, 2, 3, 4, '...', totalPages];
  //       } else if (currentPage >= totalPages - 2) {
  //           // Handle the case for the last few pages
  //           pagesToShow = [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
  //       } else {
  //           // General case for other pages
  //           pagesToShow = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
  //       }
  //   }

  //   return pagesToShow;
  // }

  async readAlert(alertID) {
    this.$store.state.totalAlerts = this.$store.state.totalAlerts - 1;

    let dataObj = {
      controller: "Alerts",
      FunctionName: "Update",
      alertId: alertID
    };
    const response = await ApiHelper.callApi('post', dataObj);

    if (response && response.STATUS === 1) {
      this.$emit('alert-read');
      let alertToUpdate = this.alerts.find(alert => alert.ALERTID === alertID);
      if (alertToUpdate) {
        alertToUpdate.ALERTREAD = 1;
      }
    }
  }

  get logsRef(): HTMLElement {
    return this.$refs.logs as HTMLElement;
  }

  get alertsRef(): HTMLElement {
    return this.$refs.alerts as HTMLElement;
  }

  async loadAccounts() {
    try {
      const response = await ApiHelper.callApi('post',
          {
            FunctionName: "ListExt",
            controller: "Accounts",
            queryType: "top5UsedAcct",
            view: "all",
            accSearch: 1
          }
      );
      if (response.STATUS) {
        this.customers = response.ACCOUNTS || [];
      }
      if (response.ERROR || !response.ACCOUNTS) {
        console.log("error", response.ERROR);
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      // this.loading = false;
    }
  }

  async created() {
    const query: any = this.$route.query;
    if (
      query.sourceType &&
      ["alert_list", "custom_alert_list"].includes(query.sourceType)
    ) {
      this.sourceType = query.sourceType;
    }
    this.loadAccounts().then(() => {
    });
    // await this.fetchData(this.activeTab);
    await this.fetchPageData();
    if (typeof this.sourcesObj != 'undefined' && this.StatusFilters.sSource.length == 0) {
      this.StatusFilters.sSource = Object.keys(this.sourcesObj);
      this.StatusFilters.sourceName = Object.values(this.sourcesObj);
    }
  }

  get filteredAlerts() {
    return this.alerts.filter(item => item.ALERTREAD === 0);
  }

  statusAlerts() {
    return this.status.map(item => {
      const content = item.CONTENT;
      // Regular expressions to match 'from' and 'to' statuses separately
      const fromMatch = content.match(/from\s+'([^']+)'|from\s+([^']+)\b/);
      // eslint-disable-next-line no-useless-escape
      const toMatch = content.match(/to\s+'([^']+)'|to\s+([^\.]+)\./);

      // Extract 'from' and 'to' statuses and remove trailing periods
      item.statusFrom = fromMatch ? (fromMatch[1] || fromMatch[2]).replace(/\.$/, '') : "Unknown";
      item.statusTo = toMatch ? (toMatch[1] || toMatch[2]).replace(/\.$/, '') : "Unknown";

      // Format date and time
      item.ALERTRUN_FORMATTED_TS = formatDateAndTime(item.ALERTRUN);

      return item;
    });

    function formatDateAndTime(dateInput) {
      let date = new Date(dateInput);
      let formattedDate = date.toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: 'numeric'});
      let formattedTime = date.toLocaleTimeString('en-US', {hour: 'numeric', minute: '2-digit', hour12: true});
      return `${formattedDate} ${formattedTime}`;
    }
  }

  async fetchPageData() {
    if (this.sourceType === 'alert_list') {
      this.fetchData(this.activeTab);
    } else {
      this.fetchCustomAlertData();
    }
  }

  async fetchData(type = 'alerts', timeframe = '') {
    this.hasChangedCustomerIds = false;
    this.hasChangedVarUserIds = false;

    await this.getFooterData(type, timeframe, 1);
    colsResizeable({ms: 500});
  }

  async fetchCustomAlertData() {
    try {
      this.loading = true;
      const response = await ApiHelper.callApi('post',
          {
            FunctionName: "CustomAlertList",
            controller: "Alerts",
            pageNumber: this.currentCustomAlertPage,
            name: this.searchCustomAlertsFilters.name
          }
      );
      if (response.STATUS == 1) {
        let customAlerts = response['CUSTOMALERTS'];
        customAlerts = customAlerts.map((alert) => ({
          ...alert,
          CUSTOMERS: [...new Set(alert.CUSTOMERS.map((cust) => cust.aName.trim()))].join(', ')
        }))
        
        this.customAlerts = customAlerts;
        this.currentCustomAlertPage = response.CURRENTPAGE;
        this.customAlertPageTotal = response.TOTALPAGES;
      } else {
        const message = response.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      this.loading = false;
    }
  }

  getActivityType(typeID: number) {
    let ret = "";
    if ([1, 2, 3, 31].includes(typeID)) {
      ret = "order";
    } else if ([5, 6, 7].includes(typeID)) {
      ret = "quote";
    } else if ([9, 10, 11, 12].includes(typeID)) {
      ret = "account";
    } else if ([13, 14, 15].includes(typeID)) {
      ret = "invoice";
    } else if ([18, 19].includes(typeID)) {
      ret = "paycheck";
    } else if ([20].includes(typeID)) {
      ret = "user_login";
    } else if ([21, 22, 23].includes(typeID)) {
      ret = "support";
    }

    return ret;
  }

  buildActivitiesDetails(details: any[]) {
    let ret: any = details;
    if (Array.isArray(details)) {
      ret = details.join(". ");
    }

    return ret;
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
    this.thisType = this.capitalize(this.activeTab);
    this.searchFilters = `search${this.thisType}Filters`;
    this.Filters = `${this.thisType}Filters`;

    this.loadPage(1, '');
  }

  async sortBy(field) {
    this[this.Sort]['field'] = field;
    this[this.Sort].direction[field] = this[this.Sort].direction[field] === 1 ? 2 : 1;

    await this.fetchData(this.activeTab);
  }

  async searchBy(field) {
    // console.log(this.searchFilters, this[this.searchFilters], this.Filters, this[this.Filters]);
    $('.searchBox').each((item, value) => {
      if ($(value).height()) {
        $(value).find('.isClose').trigger('click');
      }
    });

    if (!this[this.searchFilters][field].length) {
      return;
    } else {
      this[this.Filters][field] = this[this.searchFilters][field];
      this[this.currentPage] = 1;
      try {
        if (this.activeTab === "alerts" && !this.isChecked) {
          this.isChecked = true;
        }
        await this.fetchData(this.activeTab);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }

  async resetSearchBy(field) {
    this[this.searchFilters][field] = this[this.Filters][field] = "";
    this.resetPageNumber();

    await this.fetchData(this.activeTab);
  }

  async searchByDate() {
    $('.searchBox').each((item, value) => {
      if ($(value).height()) {
        $(value).find('.isClose').trigger('click');
      }
    });
    if (!this[this.searchFilters].sDate && !this[this.searchFilters].eDate) {
      return;
    }

    this[this.searchFilters].sDate = this[this.searchFilters].sDate;
    this[this.searchFilters].eDate = this[this.searchFilters].eDate;
    this[this.currentPage] = 1;
    // console.log("this.thisType", this.thisType);
    if (this.activeTab === "alerts" && !this.isChecked) {
      this.isChecked = true;
    }
    await this.fetchData(this.activeTab);
  }

  async resetSearchByDate() {
    if (!this[this.searchFilters].sDate && !this[this.searchFilters].eDate) {
      return;
    }

    this[this.searchFilters].sDate = undefined;
    this[this.searchFilters].eDate = undefined;
    this.resetPageNumber();

    await this.fetchData(this.activeTab);
  }

  async checkAlertTypeList(alertsType, alertsTypeName) {
    const i = this.alertsType.findIndex(function (s) {
      return s === alertsType;
    });
    if (i === -1) {
      this.alertsType.push(alertsType);
    } else {
      this.alertsType.splice(i, 1);
    }
    const alertTypeIndex = this.AlertsFilters.alertsType.findIndex(function (s) {
      return s === alertsTypeName;
    });
    if (alertTypeIndex === -1) {
      this.AlertsFilters.alertsType.push(alertsTypeName);
    } else {
      this.AlertsFilters.alertsType.splice(i, 1);
    }
    this[this.currentPage] = 1;
    if (this.activeTab === "alerts" && !this.isChecked) {
      this.isChecked = true;
    }
    await this.fetchData(this.activeTab);
  }

  async uncheckAllAlertTypeList() {
    this.alertsType = [-1];
    this.AlertsFilters.alertsType = [];
    await this.fetchData(this.activeTab);
  }

  async checkAllAlertTypeList() {
    this.alertsType = [1, 2, 3];
    this.AlertsFilters.alertsType = ['Quotes', 'Orders', 'Invoices'];
    await this.fetchData(this.activeTab);
  }

  async resetAlertTypeList() {
    this.resetPageNumber();
    this.checkAllAlertTypeList();
  }

  showTooltipSnooze(index) {
    this.snoozeTooltipIndex = index;

    var tooltipHeight = 128;
    var bottom = $(this.$refs[`snooze${index}`]).offset().top - $("#alertLog-container").offset().top;
    var container = $("#alertLog-container").height();
    bottom = container - bottom;
    if (bottom <= tooltipHeight) {
      if (!$(this.$refs[`snooze${index}`]).parent().parent().hasClass("topTooltip")) {
        $(this.$refs[`snooze${index}`]).parent().parent().addClass("topTooltip");
      }
    } else {
      $(this.$refs[`snooze${index}`]).parent().parent().removeClass("topTooltip");
    }
  }

  async updateTotalALert() {
    await this.fetchData(this.activeTab);
    this.$emit("updateTotalAlert", this.filteredAlerts.length);
    this.$store.dispatch('getAlertCount');
  }

  async handleSwitchChange() {
    Vue.prototype.$dueDateAlerts = this.isChecked;
    localStorage.setItem('ALERTCONFIG', JSON.stringify({
      DUEDATEALERTS: this.isChecked ? 1 : 0,
    }));
    this.isDisabled = true;
    if (this.isChecked) {
      await this.fetchData(this.activeTab, "upcoming");
    } else {
      await this.fetchData(this.activeTab);
    }
    this.isDisabled = false;

    await ApiHelper.callApi("post", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "update",
      dueDateAlerts: this.isChecked ? 1 : 0,
    });
  }

  getFilterValue(header) {
    let filter = '';

    switch (header) {
      case 'alertId': {
        const arr: string[] = [];
        var alertTypeFilters = ['Quotes', 'Orders', 'Invoices'];
        if (this.AlertsFilters.Id) {
          arr.push(`ID: ${this.AlertsFilters.Id}`);
        }
        if (!this.AlertsFilters.alertsType || !this.AlertsFilters.alertsType.length) {
          arr.push(`ID Type: None`);
        } else if (this.AlertsFilters.alertsType.length && this.AlertsFilters.alertsType.length != alertTypeFilters.length) {
          arr.push(`ID Type: ${this.AlertsFilters.alertsType}`);
        }

        filter = arr.join('; ');
        break;
      }
      case 'statusSource':
        if (this.StatusFilters.sourceName && !((Object.keys(this.sourcesObj).length) == this.StatusFilters.sourceName.length)) {
          filter = this.StatusFilters.sourceName.join(', ');
        }
        break;
      case "CreatedBy":
        if (this.AlertsFilters.CreatedByIds.length) {
          filter = this.varUsers
              .filter(t => this.AlertsFilters.CreatedByIds.includes(t.USERID))
              .map(t => t.UFULLNAME)
              .join(", ");
        }
        break;

      case "Customer":
        if (this.AlertsFilters.CustomerIds.length) {
          filter = this.customers
              .filter(t => this.AlertsFilters.CustomerIds.includes(t.AID))
              .map(t => t.ANAME)
              .join(", ");
        }
        break;
      default:
        // nothing
        break;
    }

    return filter;
  }

  async resetColumn() {
    this.searchAlertsFilters['Id'] = this.AlertsFilters['Id'] = "";
    this.resetPageNumber();
    await this.resetAlertTypeList();
  }

  // loadAlerts(type, page: number, direction: 'prev' | 'next' | '') {
  //   let currPage = `current${type}Page`;

  //   if (direction === "prev") {
  //     this[currPage] = this[currPage] - 1;
  //   } else if (direction === "next") {
  //     this[currPage] = this[currPage] + 1;
  //   } else {
  //     this[currPage] = page;
  //   }

  //   this.getFooterData(type, '', this[currPage]);
  // }

  async loadPage(page: number, direction: "prev" | "next" | "") {
    let type = "Alert";
    if (this.activeTab == "status") {
      type = "Status";
    } else if (this.activeTab == "logs") {
      type = "Logs";
    }

    let currPage = `current${type}Page`;

    if (direction === "prev") {
      this[currPage] = this[currPage] - 1;
    } else if (direction === "next") {
      this[currPage] = this[currPage] + 1;
    } else {
      this[currPage] = page;
    }

    this.getFooterData(type, '', this[currPage]);
  }

  resetPageNumber() {
    let type = "Alert";
    if (this.activeTab == "status") {
      type = "Status";
    } else if (this.activeTab == "logs") {
      type = "Logs";
    }

    let currPage = `current${type}Page`;

    this[currPage] = 1;
  }

  async getFooterData(type, timeframe, page) {
    this.loading = true;
    this.hasChangedSourceFilters = false;

    const data: any = {
      pageNumber: this[`current${type}Page`],
      activeTab: this.activeTab,
      searchFilters: {},
      sort: {}
    };

    if (this.activeTab === 'alerts') {
      data.searchFilters = {
        Id: this.searchAlertsFilters.Id,
        Name: this.searchAlertsFilters.Name,
        PO: this.searchAlertsFilters.PO,
        Type: this.searchAlertsFilters.Type,
        Customer: this.searchAlertsFilters.Customer,
        CustomerIds: this.AlertsFilters.CustomerIds.join(','),
        CreatedBy: this.searchAlertsFilters.CreatedBy,
        CreatedByIds: this.AlertsFilters.CreatedByIds.join(','),
        User: this.searchAlertsFilters.User,
        sDate: this.searchAlertsFilters.sDate,
        eDate: this.searchAlertsFilters.eDate
      };
      data.sort = {
        field: this.alertsSort.field,
        direction: this.alertsSort.direction[this.alertsSort.field] ? this.alertsSort.direction[this.alertsSort.field] : 1
      };
      data.alertTypeList = this.alertsType.length ? this.alertsType.toString() : "-1";
    } else if (this.activeTab === 'logs') {
      data.searchFilters = {
        Id: this.searchLogsFilters.Id,
        Details: this.searchLogsFilters.Details,
        Customer: this.searchLogsFilters.Customer,
        User: this.searchLogsFilters.User,
        sDate: this.searchLogsFilters.sDate,
        eDate: this.searchLogsFilters.eDate
      };
      data.sort = {
        field: this.logsSort.field,
        direction: this.logsSort.direction[this.logsSort.field] ? this.logsSort.direction[this.logsSort.field] : 1
      };
    } else if (this.activeTab === 'status') {
      data.searchFilters = {
        Id: this.searchStatusFilters.Id,
        Customer: this.searchStatusFilters.Name,
        sDate: this.searchStatusFilters.sDate,
        eDate: this.searchStatusFilters.eDate,
        source: ""
      };
      if (this.StatusFilters.sSource.length) {
        data.searchFilters.source = this.StatusFilters.sSource.join(',');
      }
    }

    try {
      const response = await this.$store.dispatch('getFooterData', {
        type: type,
        data: data,
        timeframe: this.isChecked ? 'upcoming' : '',
        page: page
      });
      if (response.STATUS == 1) {
        this.loading = false;
        this.tickcounts = response.queryTimes || '';
        this.alerts = response.ALERTS || this.alerts;
        this.varUsers = response.varUsers || this.varUsers;
        this.logs = response.LOGS || this.logs;
        this.status = response.STATUSLIST || this.status;
        this.alertPages = typeof response.TOTALALERTPAGES != 'undefined' ? response.TOTALALERTPAGES : this.alertPages;
        this.statusPages = typeof response.TOTALSTATUSPAGES != 'undefined' ? response.TOTALSTATUSPAGES : this.statusPages;
        this.logPages = typeof response.TOTALLOGPAGES != 'undefined' ? response.TOTALLOGPAGES : this.logPages;
        this.sourcesObj = response.SOURCESOBJ || this.sourcesObj;
      } else {
        this.loading = false;
      }
    } catch (err) {
      // Handle errors as needed
      console.error(err);
    }
  }

  isTodaysDate(dateStr) {
    const today = new Date();
    const date = new Date(dateStr);

    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
  }

  async uncheckSourceList() {
    this.StatusFilters.sSource = [];
    this.StatusFilters.sourceName = [];
    await this.fetchData(this.activeTab);
  }

  async resetSourceList(allowFetchData = true) {
    this.StatusFilters.sSource = [];
    this.StatusFilters.sourceName = [];
    this.resetPageNumber();
    this.checkAllSourceList(allowFetchData);
  }

  async checkAllSourceList(allowFetchData = true) {
    this.StatusFilters.sSource = Object.keys(this.sourcesObj);
    this.StatusFilters.sourceName = Object.values(this.sourcesObj);
    if (allowFetchData) {
      await this.fetchData(this.activeTab);
    }
  }

  async checkSourceList(status: string, name: string) {
    this.hasChangedSourceFilters = true;
    if (this.StatusFilters.sSource != undefined && this.StatusFilters.sourceName != undefined) {
      const i = this.StatusFilters.sSource.findIndex((s) => s === status);
      const sourceIndex = this.StatusFilters.sourceName.findIndex((sourceValue) => sourceValue === name);
      if (i === -1) {
        this.StatusFilters.sSource.push(status);
      } else {
        this.StatusFilters.sSource.splice(i, 1);
      }
      if (sourceIndex === -1) {
        this.StatusFilters.sourceName.push(name);
      } else {
        this.StatusFilters.sourceName.splice(sourceIndex, 1);
      }
    }
  }

  formattedTickCounts() {
    return Object.entries(this.tickcounts)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([key, value]) => `${key}: ${value}ms`)
        .join('\n');
  }

  // Created By filter
  hasChangedVarUserIds = false;
  searchCreatedBy = "";
  varUsers: any = [];

  get filteredVarUsers() {
    let ret = this.varUsers || [];
    if (this.searchCreatedBy) {
      ret = ret.filter(
          item =>
              item.UFULLNAME.toLowerCase().indexOf(
                  this.searchCreatedBy.toLowerCase()
              ) != -1
      );
    }
    return ret;
  }

  async resetCreatedByList(allowFetchData = true) {
    this.currentAlertPage = 1;
    this.AlertsFilters.CreatedByIds = [];
    if (allowFetchData) {
      await this.fetchData(this.activeTab);
    }
  }

  async checkAllCreatedByList() {
    this.AlertsFilters.CreatedByIds = this.filteredVarUsers.map(item => item.USERID);
    if (!this.AlertsFilters.CreatedByIds.length) {
      return;
    }
    this.currentAlertPage = 1;
    await this.fetchData(this.activeTab);
  }

  // Customer filter
  hasChangedCustomerIds = false;
  searchCustomer = "";
  customers: any = [];

  get filteredCustomers() {
    let ret = this.customers || [];
    if (this.searchCustomer) {
      ret = ret.filter(
          item =>
              item.ANAME.toLowerCase().indexOf(
                  this.searchCustomer.toLowerCase()
              ) != -1
      );
    }
    return ret;
  }

  async resetCustomerList(allowFetchData = true) {
    this.currentAlertPage = 1;
    this.AlertsFilters.CustomerIds = [];
    if (allowFetchData) {
      await this.fetchData(this.activeTab);
    }
  }

  async checkAllCustomerList() {
    this.AlertsFilters.CustomerIds = this.filteredVarUsers.map(item => item.AID);
    if (!this.AlertsFilters.CustomerIds.length) {
      return;
    }
    this.currentAlertPage = 1;
    await this.fetchData(this.activeTab);
  }

  openModifyModal(alert) {
    this.selectedAlert = alert;
    this.showModifyAlertModalFlg = true;
  }

  async modifyAlertContentDone(data) {
    this.selectedAlert = null;
    this.showModifyAlertModalFlg = false;
    await this.fetchData(this.activeTab);
  }

  async sourceTypeChange(type) {
    if (!type) return;

    this.sourceType = type;

    // switch route
    this.$router.replace({
      name: "Alerts",
      query: {
        sourceType: this.sourceType
      }
    });
    await this.fetchPageData();
  }

  async loadCustomAlertPage(page: number, type: "prev" | "next" | "" = "") {
    if (type === "prev") {
      this.currentCustomAlertPage = this.currentCustomAlertPage - 1;
    } else if (type === "next") {
      this.currentCustomAlertPage = this.currentCustomAlertPage + 1;
    } else {
      this.currentCustomAlertPage = page;
    }

    if (this.currentCustomAlertPage <= this.customAlertPageTotal && this.currentCustomAlertPage >= 1) {
      await this.fetchCustomAlertData()
    }
  }

  selectCustomAlert(customAlert) {
    this.selectedCustomAlert = customAlert;
    this.customAlertModalVisible = true;
  }

  resetCustomAlertSearchBy(field) {
    this.searchCustomAlertsFilters[field] = "";
    this.customAlertsFilters[field] = "";
    this.currentCustomAlertPage = 1;
    this.fetchCustomAlertData();
  }

  searchCustomAlertBy(field) {
    this.customAlertsFilters[field] = this.searchCustomAlertsFilters[field]
    this.currentCustomAlertPage = 1;
    this.fetchCustomAlertData();
  }
}
