





























































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from '@/components/LaddaButton.vue'
import ConfirmRemoveItemModal from '@/components/ConfirmRemoveItemModal.vue';
import { ApiHelper } from "@/helpers/all";
import { notifier } from "@/models/common";
import axios from "axios";
import PreviewIdentifiers from './PreviewIdentifiersModal.vue';

interface Props {
  title: string,
  data: string[]
}

interface Events {}

declare const $: any
declare const getImageRouteData: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    ConfirmRemoveItemModal,
    PreviewIdentifiers
  }
})
export default class AddIdentifiers extends TSXComponent<Props,Events> {
  @Prop({required: true})
  title!: string

  @Prop({required: true})
  data!: any

  @Prop({required: true})
  quantity!: number

  @Prop({required: true})
  hardwareDetails!: object[]

  @Prop({required: false, default: 0})
  aId!: number;

  @Prop({required: false, default: false})
  loading!: boolean;

  @Prop({ required: false, default: false })
  showAdvanced!: boolean;

  identifiers: string = '';

  $refs!: {
    modal: HTMLDivElement,
    hardwareImportFileUpload: HTMLDivElement
  }

  confirmAddNewHardware = false;
  isConfirm = false;
  hardwareIds: any[] = [];
  newHardwareCount = 0;
  hardwareSKU = '';
  hardwareName = '';
  originalHardwareDetails: object[] = [];
  initiallHardwareDetails: object[] = [];
  isEdited = false;
  currentRole = sessionStorage.getItem("userRole");
  confirmGenerateHardwares = false;
  generatingHardwares: any = false;
  addNewHardwares: any = false;
  updateLoading: boolean = false;
  newGeneratedHardwareCount: number = 0;
  warningMessage = "";
  inputField = "";
  changedField = 0;
  isActive: string = "addEntries";
  isOverride: boolean = true;
  previewLoading: boolean = false;
  previewIdentifiersModalVisible: boolean = false;
  totalRows: number = 0;
  appendRows: number = 0;
  previewDetails: any = [];
  disabledImport: boolean = true;
  importLoading: boolean = false;
  previewHardwareDetails: object[] = [];
  showNotes: boolean = false;
  previewItems: any = [];
  includeEndUserInfo: boolean = true;

  created() {
    if ((this.data.hardwares || []).length) {
      this.hardwareIds = this.data.hardwares.map((item: any) => {
        return item.HARDWAREID
      });
    }
    this.hardwareSKU = this.data.HARDWARESKU;
    this.hardwareName = this.data.HDESCRIPTION;
    if (this.currentRole === 'Reseller' && (!this.data.QUANTITY || this.data.QUANTITY < this.data.SKUQTY)) {
      let newHardwareQty = this.data.SKUQTY - this.data.QUANTITY;
      for (let index = 0; index < newHardwareQty; index++) {
        let newHardwareItem = {
          "HARDWAREID" : 0,
          "HARDWARESKU" : this.data.HARDWARESKU,
          "ASSETNUMBER" : "",
          "ASSETTAG" : ""
        };
        this.hardwareDetails.push(newHardwareItem);
      }
      this.addNewHardwares = true;
    }
    this.originalHardwareDetails = JSON.parse(JSON.stringify(this.hardwareDetails));
    this.initiallHardwareDetails = JSON.parse(JSON.stringify(this.hardwareDetails));
    this.previewHardwareDetails = JSON.parse(JSON.stringify(this.hardwareDetails));
  }

  mounted() {
    $(this.$refs.modal).modal('show');

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close');
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide');
  }

  async addIdentifiers() {
    try {
      let newHardwaresCount = this.hardwareDetails.filter((item: any) => item.HARDWAREID == 0).length;
      if (((this.hardwareIds.length + newHardwaresCount) > this.quantity)) {
        this.newHardwareCount = (this.hardwareIds.length + newHardwaresCount) - this.quantity;
        this.confirmAddNewHardware = true;
        return false;
      }

      let hardwareDetails = [...this.hardwareDetails].filter((item: any) => item.ASSETNUMBER.trim().length || item.ASSETTAG.trim().length);
      if (hardwareDetails.length) {
        this.updateLoading = true;
        const response = await ApiHelper.callApi(
          'post',
          {
            controller: "Deployments",
            functionName: "UpdateHardware",
            action: "addIdentifiers",
            hardwareDetails: hardwareDetails,
            aId: this.aId,
            includeEndUserInfo: this.includeEndUserInfo,
            subOrderId: this.data.SUBORDERID || '',
            euId: this.data.EUID || 0
          }
        );
        if (response.STATUS === 1) {
          notifier.success(response.STATUSMESSAGE);
          this.$emit('updateHardwares');
          this.$emit('close');
          this.isEdited = false;
        } else if (response.STATUS === 2) {
          notifier.alert(response.STATUSMESSAGE);
        } else {
          notifier.alert("Something was wrong");
        }
        this.updateLoading = false;
      } else {
        notifier.alert("Please enter valid Serial Numbers/Asset Tags");
      }
    } catch (err) {
      // console.log(err);
    }
  }

  @Watch("hardwareDetails", { deep: true })
  async isHardwareDetailsChanged() {
    let oldVal = JSON.stringify(this.initiallHardwareDetails);
    let newVal = JSON.stringify(this.hardwareDetails);
    if (oldVal != newVal) {
      this.isEdited = true;
    } else {
      this.isEdited = false;
    }
  }

  checkDuplicateExists(header, value, hardwareId, index) {
    if(value != '') {
      const response = axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateHardware",
        header: header,
        value: value,
        hardwareId: hardwareId,
        action: "checkDuplicate"
      });
      response.then(
        response => {
          this.warningMessage = response.data.STATUSMESSAGE;
          this.inputField = response.data.header;
        }
      )
      this.changedField = index;
    }
  }

  async updateIdentifer(type: string, items: any = '', index) {
    if (type == "assetNumber" && items != '' && items != ',') {
      let assetNumberList = items.split(',').map(item => item.trim());
      if (assetNumberList.length > 1) {
        this.hardwareDetails = this.hardwareDetails.map((item: any, itemIndex) => {
          const oldItem: any = this.originalHardwareDetails[index];
          if ((!item.ASSETNUMBER.length && assetNumberList.length > 0) || (index == itemIndex && (typeof oldItem.ASSETNUMBER == "undefined" || !oldItem.ASSETNUMBER.length))) {
            return { ...item, ASSETNUMBER: assetNumberList.shift() };
          } else if (index == itemIndex) {
            return { ...item, ASSETNUMBER: oldItem.ASSETNUMBER };
          } else {
            return {
              HARDWAREID: item.HARDWAREID || 0,
              ASSETNUMBER: item.ASSETNUMBER || '',
              ASSETTAG: item.ASSETTAG || ''
            };
          }
        });
  
        // while (assetNumberList.length > 0) {
        //   const assetNumber = assetNumberList.shift();
        //   this.hardwareDetails.push({ ASSETNUMBER: assetNumber, HARDWAREID: 0, ASSETTAG: '' });
        // }
      }
    } else if (type == "assetTag" && items != '' && items != ',') {
      let assetTagList = items.split(',').map(item => item.trim());
      if (assetTagList.length > 1) {
        this.hardwareDetails = this.hardwareDetails.map((item: any, itemIndex) => {
          const oldItem: any = this.originalHardwareDetails[index];
          if ((!item.ASSETTAG.length && assetTagList.length > 0) || (index == itemIndex && (typeof oldItem.ASSETTAG == "undefined" || !oldItem.ASSETTAG.length))) {
            return { ...item, ASSETTAG: assetTagList.shift() };
          } else if (index == itemIndex && oldItem.ASSETTAG != '') {
            return { ...item, ASSETTAG: oldItem.ASSETTAG };
          } else {
            return {
              HARDWAREID: item.HARDWAREID || 0,
              ASSETNUMBER: item.ASSETNUMBER || '',
              ASSETTAG: item.ASSETTAG || ''
            };
          }
        });
        
        // while (assetTagList.length > 0) {
        //   const assetTag = assetTagList.shift();
        //   this.hardwareDetails.push({ ASSETTAG: assetTag, HARDWAREID: 0, ASSETNUMBER: '' });
        // }
      }
    }
    this.originalHardwareDetails = JSON.parse(JSON.stringify(this.hardwareDetails));
  }

  // addNewHardware() {
  //   this.hardwareDetails.push({ HARDWAREID: 0, ASSETTAG: '', ASSETNUMBER: '' });
  //   this.originalHardwareDetails.push({ HARDWAREID: 0, ASSETTAG: '', ASSETNUMBER: '' });
  // }

  async addNewIdentifiers() {
    try {
      this.confirmAddNewHardware = false;
      let hardwareDetails = [...this.hardwareDetails].filter((item: any) => item.ASSETNUMBER.trim().length || item.ASSETTAG.trim().length);
      if (hardwareDetails.length) {
        const response = await ApiHelper.callApi(
          'post',
          {
            controller: "Deployments",
            FunctionName: "UpdateHardware",
            action: "addIdentifiers",
            HardwareId: this.data.HARDWAREID,
            hardwareDetails: hardwareDetails,
            aId: this.aId,
            includeEndUserInfo: this.includeEndUserInfo,
            subOrderId: this.data.SUBORDERID || '',
            euId: this.data.EUID || 0
          }
          
        );
        if (response.STATUS === 1) {
          notifier.success(response.STATUSMESSAGE);
          this.$emit('updateHardwares');
          this.$emit('close');
          this.isEdited = false;
        } else if (response.STATUS === 2) {
          notifier.alert(response.STATUSMESSAGE);
        } else {
          notifier.alert("Something was wrong");
        }
      } else {
        notifier.alert("Please enter valid Serial Numbers/Asset Tags");
      }
    } catch (err) {
      // console.log(err);
    }
  }

  async generateHardwares(force = false) {
    if(!force) {
      // show a confirm message before generating new hardwares
      let newHardwaresCount = this.hardwareDetails.filter((item: any) => item.HARDWAREID == 0 && item.ASSETNUMBER.trim().length).length;
      this.newGeneratedHardwareCount = newHardwaresCount;
      this.confirmGenerateHardwares = true;
      return;
    }

    this.generatingHardwares = true;
    try {
      let hardwareDetails = [...this.hardwareDetails].filter((item: any) => item.ASSETNUMBER.trim().length);
      const response = await ApiHelper.callApi(
        'post',
        {
          controller: "Deployments",
          FunctionName: "UpdateHardware",
          action: "addIdentifiers",
          newHardware: true,
          purchaseId: this.data.PURCHASEID,
          poLiIds: this.data.POLIIDS,
          hardwareDetails: hardwareDetails,
          aId: this.aId,
          includeEndUserInfo: this.includeEndUserInfo,
          subOrderId: this.data.SUBORDERID || '',
          euId: this.data.EUID || 0,
          // from order details page
          initSource: this.$route.name == "ViewOrder" ? 5 : 0
        }
        
      );

      if (response.STATUS === 1) {
        if (response.notInsertAllHardware) {
          notifier.alert(response.STATUSMESSAGE);
        } else {
          notifier.success(response.STATUSMESSAGE);
        }
        this.$emit('updateHardwares');
        this.isEdited = false;
      } else if (response.STATUS === 2) {
        notifier.alert(response.STATUSMESSAGE);
      } else {
        notifier.alert("Something was wrong");
      }
      this.$emit('close');

    } catch (err) {
      console.log(err);
      this.generatingHardwares = "error";
    }
  }

  showHardwareImportFileUpload() {
    $(this.$refs.hardwareImportFileUpload).click();
  }

  async handleHardwareImportFileUpload(e) {
    e.stopPropagation();
    e.preventDefault();

    var comInstance = this;
    comInstance.disabledImport = true;
    comInstance.showNotes = false;
    var filePath = e.target.files[0].name;
    var allowedExtensions = /(\.csv)$/i;

    if (!allowedExtensions.exec(filePath)) {
      notifier.alert("Please upload file having extensions .csv only.");
    } else {
      //upload file, and get returned json
      comInstance.previewLoading = true;
      var processedFileUpload = getImageRouteData({
        Controller: "Deployments",
        FunctionName: "previewImport",
        importfile: e.target.files[0],
        fileType: filePath.indexOf(".csv") != -1 ? "csv" : "",
        isOverride: this.isOverride,
        aId: this.aId
      }).then(async (response, statusText, jqXHR) => {
        comInstance.previewLoading = false
        if(response.data.STATUS == 1) {
          comInstance.previewItems = response.data.PreviewItems || [];
          if (comInstance.previewItems.length) {
            comInstance.previewIdentifiersModalVisible = true;
            this.updateImportHardware();
          } else if (response.data.duplicateSerialNumberCount || 0) {
            notifier.alert("All Serial Numbers are duplicated and won't be imported.");
          }
        }
        else {
          notifier.alert(response.data.STATUSMESSAGE)
        }
      }).catch(function (error) {
          // console.log(error);
      });
    }
  }

  async importHardwares() {
    if (this.addNewHardwares) {
      try {
        this.importLoading = true;
        let hardwareDetails = [...this.previewDetails].filter((item: any) => item.ASSETNUMBER.trim().length);
        const response = await ApiHelper.callApi(
          'post',
          {
            controller: "Deployments",
            FunctionName: "UpdateHardware",
            action: "addIdentifiers",
            newHardware: true,
            purchaseId: this.data.PURCHASEID,
            poLiIds: this.data.POLIIDS,
            hardwareDetails: hardwareDetails,
            aId: this.aId,
            includeEndUserInfo: this.includeEndUserInfo,
            subOrderId: this.data.SUBORDERID || '',
            euId: this.data.EUID || 0
          }
          
        );

        if (response.STATUS === 1) {
          if (response.notInsertAllHardware) {
            notifier.alert(response.STATUSMESSAGE);
          } else {
            notifier.success(response.STATUSMESSAGE);
          }
          this.$emit('updateHardwares');
        } else if (response.STATUS === 2) {
          notifier.alert(response.STATUSMESSAGE);
        } else {
          notifier.alert("Something was wrong");
        }
        this.importLoading = false;
        this.$emit('close');

      } catch (err) {
        // console.log(err);
      }
    } else {
      try {
        let hardwareDetails = [...this.previewDetails].filter((item: any) => item.ASSETNUMBER.trim().length || item.ASSETTAG.trim().length);
        if (hardwareDetails.length) {
          this.importLoading = true;
          const response = await ApiHelper.callApi(
            'post',
            {
              controller: "Deployments",
              functionName: "UpdateHardware",
              action: "addIdentifiers",
              hardwareDetails: hardwareDetails,
              aId: this.aId,
              includeEndUserInfo: this.includeEndUserInfo,
              subOrderId: this.data.SUBORDERID || '',
              euId: this.data.EUID || 0
            }
          );
          if (response.STATUS === 1) {
            notifier.success(response.STATUSMESSAGE);
            this.$emit('updateHardwares');
            this.$emit('close');
          } else if (response.STATUS === 2) {
            notifier.alert(response.STATUSMESSAGE);
          } else {
            notifier.alert("Something was wrong");
          }
          this.importLoading = false;
        } else {
          notifier.alert("Please enter valid Serial Numbers/Asset Tags");
        }
      } catch (err) {
        // console.log(err);
      }
    }
  }

  updateImportHardware() {
    let previewItems = [...this.previewItems];
    let previewHardwareDetails = JSON.parse(JSON.stringify(this.previewHardwareDetails));
    if (!this.isOverride) {
      previewHardwareDetails.map((item: any, hIndex: number) => {
        let findOverridesHardware = previewItems.find((item: any, pIndex: number) => pIndex === hIndex);
        if (findOverridesHardware) {
          item.ASSETNUMBER = findOverridesHardware.ASSETNUMBER;
          item.ASSETTAG = findOverridesHardware.ASSETTAG;
        }
      });
    } else {
      // let serialNumberHardware = previewHardwareDetails.filter((item: any) => !item.ASSETNUMBER.trim().length);
      // if (serialNumberHardware.length) {
      //   serialNumberHardware.map((item: any, hIndex: number) => {
      //     let findOverridesHardware = previewItems.find((item: any, pIndex: number) => pIndex === hIndex);
      //     if (findOverridesHardware) {
      //       item.ASSETNUMBER = findOverridesHardware.ASSETNUMBER;
      //       item.ASSETTAG = findOverridesHardware.ASSETTAG;
      //     }
      //   });
      // }

      // try to map with existed firstly
      for(const item of previewItems) {
        const inList = previewHardwareDetails.find(t => t.HARDWAREID == item.HARDWAREID && t.ASSETNUMBER == item.ASSETNUMBER);
        if(inList) {
          // update asset tag
          if(inList.ASSETTAG != item.ASSETTAG) {
            inList.ASSETTAGBK = inList.ASSETTAG;
          }
          inList.ASSETTAG = item.ASSETTAG;
          inList.linked = item.linked = 1;
        }
      }
      // map for others
      for(const item of previewHardwareDetails) {
        if(item.linked || 0) continue;

        // map to line has unique serial number
        const inList: any = previewItems.find(t => !(t.linked || 0) && !t.HARDWAREID);
        if(inList) {
          item.ASSETNUMBER = inList.ASSETNUMBER;
          item.ASSETTAG = inList.ASSETTAG;
          inList.linked = item.linked = 1;
        }
      }
    }

    this.previewDetails = previewHardwareDetails;
    this.totalRows = previewItems.length;
    this.appendRows = this.previewDetails.filter((item: any) => item.HARDWAREID && item.ASSETNUMBER.trim().length).length;
  }
}
