





























































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { validateFn } from "../helpers";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import CustomAlertModal from "@/components/Alert/CustomAlertModal.vue";
import axios from "axios";
import AddBusinessLineModal from "@/components/AddBusinessLineModal.vue";
import { ApiHelper } from "@/helpers/all";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import USState from "../static/USstates.json";
import Inputmask from "inputmask";

declare const dataURL: string;
declare const $: any;
declare const window: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    CustomAlertModal,
    AddBusinessLineModal,
    ConfirmRemoveItemModal,
    DropdownControl
  }
})
export default class GlobalParams extends TSXComponent<void> {
  loading = false;
  saving: boolean | string = false;
  distributor = [];
  estPercent = 0;
  HPEestPercent = 0;
  hpTaxRate = 0;
  indirectTaxRate = 0;
  orderGenerateHardware = "no";
  enable2FAF = 0;
  notShippedYet: boolean = false;
  // fourDaysCreationAlert: boolean = false;
  autoAlerts: any[] = [];
  autoAlertModalVisible: boolean = false;
  openAutoAlertId: number = 0;
  businessLineOptions: any = [];
  addBusinessLineModalVisible: boolean = false;
  confirmBLDelete: boolean = false;
  deletedBLDetails: any = {};
  deletingBL: boolean = false;
  businessLineDetails: any = {};
  companyName = "";
  companyURL = "";
  companyAddr = "";
  companyAddr2 = "";
  companyCity = "";
  companyState = "";
  stateList: any = [];
  selectedState: string[] = [];
  companyZip = "";
  companyLogo = "";
  filePath = "";
  fileName = "";
  fileType = "";
  quoteContactPhone1 = "";
  quoteContactPhone2 = "";
  orderContactPhone1 = "";
  orderContactPhone2 = "";
  quoteContactEmail = "";
  invoiceBillingEmail = "";

  async created() {
  	// build states list
    for (const i in USState) {
      this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
    }
    
    await this.fetchData();
  }

  async fetchData() {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "VARSources",
        FunctionName: "GlobalParams",
        subsystem: "VAR360",
        action: "list",
        isBusinessLine: true
      });
      this.estPercent = response.data.ESTPercent || 0;
      this.HPEestPercent = response.data.HPEESTPercent || 0;
      this.distributor = response.data.distributor;
      this.hpTaxRate = response.data.hpTaxRate || 0;
      this.indirectTaxRate = response.data.indirectTaxRate || 0;
      this.orderGenerateHardware = response.data.orderGenerateHardware || "no";
      this.enable2FAF = response.data.enable2FAF || 0;
      this.autoAlerts = response.data.ALERT || [];
      this.notShippedYet = response.data.notShippedYet || false;
      // this.fourDaysCreationAlert = response.data.fourDaysCreationAlert || false;
      this.businessLineOptions = response.data.businessLines || [];
      this.companyName = response.data.companyName || "";
      this.companyAddr = response.data.companyAddr || "";
      this.companyAddr2 = response.data.companyAddr2 || "";
      this.companyCity = response.data.companyCity || "";
      this.companyState = response.data.companyState || "";
      if(this.companyState) {
        this.selectedState = [this.companyState];
      }
      this.companyZip = response.data.companyZip || "";
      this.companyLogo = response.data.companyLogo || "";
      this.quoteContactPhone1 = response.data.quoteContactPhone1 || "";
      this.quoteContactPhone2 = response.data.quoteContactPhone2 || "";
      this.orderContactPhone1 = response.data.orderContactPhone1 || "";
      this.orderContactPhone2 = response.data.orderContactPhone2 || "";
      this.quoteContactEmail = response.data.quoteContactEmail || "";
      this.companyURL = response.data.companyURL || "";
      this.invoiceBillingEmail = response.data.invoiceBillingEmail || "";
    }catch (err) {
      console.log(err);
    }finally {
      this.loading = false;
    }
    
    // mask for phone
    this.$nextTick().then(() => {
      $("input[inputmask=phonenumber]").each(function(i, obj) {
        Inputmask("(999) 999-9999", {}).mask(obj);
      });
    });
  }

  async validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    // validate phones
    let valid = true;
    const mask = "(999) 999-9999";
    this.$validator.errors.remove("quoteContactPhone1");
    this.$validator.errors.remove("quoteContactPhone2");
    this.$validator.errors.remove("orderContactPhone1");
    this.$validator.errors.remove("orderContactPhone2");
    if(this.quoteContactPhone1 && !Inputmask.isValid(this.quoteContactPhone1, { mask })) {
      this.$validator.errors.add({
        field: "quoteContactPhone1",
        msg: "Invalid Phone"
      });
      valid = false;
    }
    if(this.quoteContactPhone2 && !Inputmask.isValid(this.quoteContactPhone2, { mask })) {
      this.$validator.errors.add({
        field: "quoteContactPhone2",
        msg: "Invalid Phone"
      });
      valid = false;
    }
    if(this.orderContactPhone1 && !Inputmask.isValid(this.orderContactPhone1, { mask })) {
      this.$validator.errors.add({
        field: "orderContactPhone1",
        msg: "Invalid Phone"
      });
      valid = false;
    }
    if(this.orderContactPhone2 && !Inputmask.isValid(this.orderContactPhone2, { mask })) {
      this.$validator.errors.add({
        field: "orderContactPhone2",
        msg: "Invalid Phone"
      });
      valid = false;
    }
    const quoteEmailValid = await this.$validator.validate("quoteContactEmail");
    if(!valid || !quoteEmailValid) {
      return;
    }

    this.$validator.validateAll().then(async result => {
      if(result) {
        this.submit();
      }      
    });
  }

  async submit() {
    try {
      this.saving = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "VARSources",
        FunctionName: "GlobalParams",
        subsystem: "VAR360",
        action: "update",
        distributor: this.distributor,
        estPercent: this.estPercent,
        HPEestPercent: this.HPEestPercent,
        hpTaxRate: this.hpTaxRate,
        indirectTaxRate: this.indirectTaxRate,
        orderGenerateHardware: this.orderGenerateHardware,
        enable2FAF: this.enable2FAF,
        notShippedYet: this.notShippedYet,
        // fourDaysCreationAlert: this.fourDaysCreationAlert
        companyName: this.companyName,
        companyURL: this.companyURL,
        companyAddr: this.companyAddr,
        companyAddr2: this.companyAddr2,
        companyCity: this.companyCity,
        companyState: this.companyState,
        companyZip: this.companyZip,
        quoteContactPhone1: this.quoteContactPhone1,
        quoteContactPhone2: this.quoteContactPhone2,
        orderContactPhone1: this.orderContactPhone1,
        orderContactPhone2: this.orderContactPhone2,
        quoteContactEmail: this.quoteContactEmail,
        invoiceBillingEmail: this.invoiceBillingEmail
      });
      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if(response.data.STATUS != 1) {
        this.saving = "error";
      }else {
        notifier.success("Updated Params Successfully");
      }
      this.saving = false;
    }catch (err) {
      this.saving = "error";
    }
  }
  structuredClone(obj) {
    return window.structuredClone(obj)
  }
  addAutoAlert(data) {
    if (data.PARAMS) {
      if (data.ALERTID && data.ISUPDATE) {
        let alertIdx = this.autoAlerts.findIndex(item => item.ALERTID && item.ALERTID == data.ALERTID);
        if (alertIdx >= 0 ) {
          this.autoAlerts[alertIdx] = data.PARAMS;
        } else {
          this.autoAlerts.push(data.PARAMS);
        }
      } else {
        this.autoAlerts.push(data.PARAMS);
      }
    }
  }
  openAutoAlert(alertId) {
    this.openAutoAlertId = alertId;
    this.autoAlertModalVisible = true;
  }
  async removeAutoAlert(alertId) {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Alerts",
        FunctionName: "UpdateAccountAlert",
        action: "deleteAccountAlert",
        alertId
      });
      if (response.data.STATUS == 1) {
        const deletedAlertIndex = this.autoAlerts.findIndex((alert) => alert.ALERTID === alertId);
        if (deletedAlertIndex >= 0) {
          this.autoAlerts.splice(deletedAlertIndex , 1);
        }
  
        this.$forceUpdate();
        notifier.success(response.data.STATUSMSG);
      }
    } catch (error) {
      // console.log(error);
    } 
  }

  async reload() {
    this.addBusinessLineModalVisible = false;
    this.businessLineDetails = {};
    await this.fetchData();
  }

  async deleteBL() {
    if (this.deletedBLDetails.ID) {
      this.deletingBL = true;
      const response = await ApiHelper.callApi("post", {
        controller: "Helpers",
        FunctionName: "BusinessLine",
        action: 'deleteBL',
        businessLineId: this.deletedBLDetails.ID
      });

      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        this.businessLineOptions = this.businessLineOptions.filter((item: any) => item.ID != this.deletedBLDetails.ID);
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }

      this.deletingBL = false;
      this.confirmBLDelete = false;
    }
  }

  onChangeImg() {
    $('#logoUpload').click();
  }

  async onFileChange(e) {
    const imageFile = e.target.files[0];

    // validate
    const allowedExtensions = /(\.png|\.jpg|\.jpeg)$/i;
    if (!allowedExtensions.exec(imageFile.name)) {
      notifier.alert("Please upload file having extensions .png, jpg, jpeg only.");
      return;
    }

    this.companyLogo = URL.createObjectURL(imageFile);
    this.filePath = imageFile;
    this.fileName = imageFile.name.replace(/\s/g, '');
    this.fileType = imageFile.type;

    // save company logo
    const requestObj = {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "updateCompanyLogo",
      filePath: this.filePath,
      fileName: this.fileName,
      fileType: this.fileType,
    };
    const formData = new FormData();
    for (let key in requestObj) {
      formData.append(key, requestObj[key]);
    }
    formData.append("session", sessionStorage.getItem("sessionID") || "");
    const response: any = await axios.post(
      dataURL + "?ReturnType=JSON&r=VARSources/GlobalParams",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data"
        }
      }
    );
    if(response.data.STATUS == 1) {
      this.companyLogo = response.data.S3URL || "";
      notifier.success("Saved logo successfully");
    }
  }
}
