



















































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { notifier, downloadFile, printHtml } from "../models/common";
import Pagination from "../components/Pagination.vue";
import UpdateItemStatusModal from "../components/UpdateItemStatusModal.vue";
import DeleteItemModal from "../components/DeleteItemModal.vue";
import UserStatus from "../components/UserStatus.vue";
import { UserAPIRepo } from "../repos/UserAPIRepo";
import { ApiHelper } from "@/helpers/all";
import LogList from "@/components/Log/LogList.vue";
import {dollarFormat} from "../helpers/ApiHelper";

interface Props {
  searchChange: Function;
  gotoSearch: Function;
  showBottomPanel: Boolean;
  hideBottomPanelClose: Boolean;
}

interface Events {
  onInput: string;
}

declare const dataURL: string;
declare const getMaxRows: Function;

const userRepo = new UserAPIRepo();

const REQUIRED_CHARS = 2

@Component({
  methods: {dollarFormat},
  inheritAttrs: false,
  components: {
    LogList,
    Pagination,
    UpdateItemStatusModal,
    DeleteItemModal,
    UserStatus
  }
})
export default class Footer extends TSXComponent<Props, Events> {
  @Prop({ required: false })
  showBottomPanel?: boolean;

  @Prop({ required: false })
  hideBottomPanelClose?: boolean;

  @Prop({ required: false, default: ''})
  objectType?: string;

  @Prop({ required: false, default: 0})
  selectedID?: number;

  @Prop({ required: false, default: '0.0'})
  selectedGrandTotal?: string;

  @Prop({ required: false, default: '0.0'})
  selectedProfitTotal?: string;

  resizeHeight = 400;

  upSizeModal() {
    const innerHeight = window.innerHeight || 400;
    this.resizeHeight = ApiHelper.saveHeightAlerts(this.resizeHeight + 50);
    if (this.resizeHeight > (innerHeight - 300)) {
      this.resizeHeight = innerHeight - 300;
      ApiHelper.showErrorMessage('Modal size much smaller than screen height');
    }
  }

  downSizeModal() {
    if (this.resizeHeight - 50 < 100) {
      ApiHelper.showErrorMessage('Modal size much larger than 100px');
    } else {
      this.resizeHeight =  ApiHelper.saveHeightAlerts(this.resizeHeight - 50);
    }
  }

  togglePinModal() {
    this.isPin = ApiHelper.togglePinAlerts();
    const logListComponent: any = this.$refs.logListComponent;
    if (logListComponent) {
      ApiHelper.saveCacheAlerts(
        logListComponent.items,
        logListComponent.alerts,
        logListComponent.activeTab,
        logListComponent.searchLogsFilters,
        logListComponent.logsSort,
        logListComponent.searchAlertsFilters,
        logListComponent.alertsSort,
        logListComponent.isChecked,
        logListComponent.AlertsFilters,
        logListComponent.logsFilters
      );
    }


  }

  reloadLogList() {
    const logListComponent: any = this.$refs.logListComponent;
    if (logListComponent) {
      logListComponent.fetchData(logListComponent.isChecked ? "upcoming" : "", true);
    }
  }
  isPin = '';

  $alertCountReseller: any;

  @Watch("showBottomPanel")
  async showBottomPanelChanged() {
    if (this.showBottomPanel) {
      this.searchResultsVisible = false;
      this.modalAlerts.show = false;
    }
  }

  clickOutside() {
    this.$emit('closeBottomPanel');
  }

  $route: any;
  $parent: any;
  $allowedFunctions: any;
  $userFunctions: any;
  searchResultsVisible = false
  searching = false
  enableSearch = false
  search = ''
  viewType = 'CurrentUsers'
  selectedView = false
  searchValue = ''
  totalAlerts: any = null
  newAlerts: any = ''

  searchResults: any[] = []
  searchMore = ''
  timer: any = null;

  searchForDescription = {
    id: 'Var ID',
    name: 'Name',
    skuName: 'SKU Name',
    sku: 'SKU',
    serialNumber: 'Serial Number'
  };

  selectedSearchIn: string[] = [];

  searchInOptions = [
    {
      text: 'Accounts',
      value: 'accounts',
    },
    {
      text: 'Quotes',
      value: 'quotes',
    },
    {
      text: 'Orders',
      value: 'orders',
    },
    {
      text: 'Invoices',
      value: 'invoices',
    },
    {
      text: 'Deployments',
      value: 'deployments',
    },
    {
      text: 'Hardwares',
      value: 'hardwares',
    }
  ]

  selectedSearchFor: string[] = [];

  searchForOptions = [
    {
      text: 'Var Id',
      value: 'id',
    },
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'SKU Name',
      value: 'skuName',
    },
    {
      text: 'SKU',
      value: 'sku',
    },
    {
      text: 'Serial Number',
      value: 'serialNumber',
    }
  ]

  @Watch('selectedSearchFor', { deep: true })
  onSearchForChange(value: string, oldValue: string) {
    this.searchInput(this.searchValue);
  }

  @Watch('selectedSearchIn', { deep: true })
  onSearchInChange(value: string, oldValue: string) {
    this.searchInput(this.searchValue);
  }

  created(){
    const allowedPages = ['Quotes','Orders','Customers','Users','Payroll','Invoices','Reports','SecurityGroups','Spiffs','Indirectorders','Hardware','HPAgent','Supports'];
    if(allowedPages.indexOf(this.$route.name) >= 0){
      this.enableSearch = true;
    }

    this.selectedSearchFor = this.searchForOptions.map((op) => op.value);
    this.selectedSearchIn = this.searchInOptions.map((op) => op.value);
  }

  mounted(){
    const $this = this;
    const innerHeight = window.innerHeight || 400;
    const defaultModalHeight = ApiHelper.getHeightAlerts();
    this.isPin = ApiHelper.getPinAlerts();
    this.resizeHeight = defaultModalHeight > (innerHeight - 300) ? innerHeight - 300 : defaultModalHeight;
    this.modalAlerts.show = !!this.isPin;
    /* if (this.checkUser() === 'Reseller') {
      setTimeout(() => {
        test();
        async function test(){
          await $this.$store.dispatch('getAlertCount');
        }
      }, 300);
    } */

    setTimeout( () => {
      this.pane = document.getElementById('pane');
      this.ghostpane = document.getElementById('ghostpane');
      // Mouse events
      // this.pane.addEventListener('mousedown', this.onMouseDown);
      document.addEventListener('mousemove', this.onMove);
      document.addEventListener('mouseup', this.onUp);

      // Touch events
      // this.pane.addEventListener('touchstart', this.onTouchDown);
      document.addEventListener('touchmove', this.onTouchMove);
      document.addEventListener('touchend', this.onTouchEnd);
      this.animate();
    }, 100);
  }

  unselectSearchFor(opt) {
    this.selectedSearchFor = this.selectedSearchFor.filter((value) => value !== opt);
  }

  unselectSearchIn(opt) {
    this.selectedSearchIn = this.selectedSearchIn.filter((value) => value !== opt);
  }

  uncheckAll(type) {
    if (type === 'searchIn') {
      this.selectedSearchIn = [];
    } else if (type === 'searchFor') {
      this.selectedSearchFor = [];
    }
  }

  checkAll(type) {
    if (type === 'searchIn') {
      this.selectedSearchIn = this.searchInOptions.map((op) => op.value);
    } else if (type === 'searchFor') {
      this.selectedSearchFor = this.searchForOptions.map((op) => op.value);
    }
  }

  handleAlertRead() {
    this.totalAlerts -= 1;
  }

  updateTotalAlert(count) {
    this.totalAlerts = count;
  }

  get searchResultMessage() {
    if (this.search.length <= REQUIRED_CHARS) {
      return 'Keep typing... we need ' + ((REQUIRED_CHARS + 1) - this.search.length) + ' more character'
    }

    return ''
  }

  checkUser() {
    return sessionStorage.getItem("userRole");
  }

  async toggleCustomView(viewType){
    this.searching = true
    this.viewType = viewType;
    this.selectedView = true;
    await this.searchInput(this.searchValue);
    const _this = this;
    setTimeout(() => {
      _this.searching = false
    }, 300);

  }

  async searchInForSelectedChange() {
    this.searching = true;
    await this.searchInput(this.searchValue);
    const thiz = this;
    setTimeout(() => {
      thiz.searching = false
    }, 300);
  }


  async searchInput(search) {
    const isPin = !!ApiHelper.getPinAlerts();
    if(isPin) {
      this.modalAlerts.show = true;  
    }else {
      this.modalAlerts.show = false;
    }

    this.searchValue = search;
    var currentUser = JSON.parse(sessionStorage.getItem("currentUser") || "");
    var dataObj = {};

    if (search.length === 0) {
      this.searchResultsVisible = false
      return
    }

    this.searchResultsVisible = true

    if (search.length <= REQUIRED_CHARS) {
      return;
    }

    this.searching = true;

    if(!this.selectedView && typeof this.$parent.viewType != "undefined") {
      // get viewType of parent page in case user didn't select a view type yet
      try {
        const parentViewType = (typeof this.$parent.viewType != "undefined" ? this.$parent.viewType : "");
        if(["All", "CurrentUsers"].includes(parentViewType)) {
          this.viewType = parentViewType;
        }else if(this.$parent.$route.name == "Spiffs") {
          if(parentViewType == "all_spiffs") {
            this.viewType = "All";
          }else if(parentViewType == "my_spiffs") {
            this.viewType = "CurrentUsers";
          }
        }
      // eslint-disable-next-line no-empty
      } catch (error) {}
    }

    try {

      if (this.selectedSearchIn.length === 0 || this.selectedSearchFor.length === 0) {
        this.searchResults = [];
        this.searchMore = "";
        return;
      }

      const reqBody = {
        session: sessionStorage.getItem("sessionID"),
        userRole: sessionStorage.getItem("userRole"),
        Controller: "Search",
        FunctionName: "Var360Search",
        search: search,
        selectedSearchIn: this.selectedSearchIn,
        selectedSearchFor: this.selectedSearchFor,
        view: this.viewType,
        selectedView: this.selectedView,
        aID: currentUser[0]["ACCOUNTID"]
      };

      const response = await axios.post(dataURL + "?ReturnType=JSON", reqBody);

      if (typeof response.data.ERROR != "undefined") {
        throw new Error(response.data.ERROR);
      }
      if (
        typeof response.data.STATUS != "undefined" &&
        response.data.STATUS !== 1
      ) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (typeof response.data.SEARCHRETURN != "undefined") {
        this.searchResults = response.data.SEARCHRETURN || [];
        this.searchMore = response.data.SEARCHMORE || "";
      }

      // if(!this.selectedView && typeof response.data.userSelectedCurrentView != "undefined") {
      //   this.selectedView = true;
      //   this.viewType = response.data.userSelectedCurrentView;
      // }
    } finally {
      setTimeout(() => {
          this.searching = false
      }, 300);
    }
  }

  async closeSearch() {
    this.searchResultsVisible = false
    this.search = ''
    this.selectedView = false;
    // if (document.URL.split("/")[4] == "search") {
    //   $("#searchKey").val(document.URL.split("/")[5]);
    //   $("#searchFor").val(document.URL.split("/")[7]);
    // }
  }

  gotoGlobalSearch(params) {
    if(this.$route.name != "Search") {
      var routeQuery = {};
      if(this.$allowedFunctions.includes(this.$userFunctions.All_Milestone_Accounts)) {
        routeQuery["viewType"] = params.viewType;
      }
      this.$router.push({
        path: "/search/" + params.key + "/for/" + params.type,
        query: routeQuery
      });
    }else {
      this.searchResultsVisible = false;
      this.$parent.updateSearchParams(params);
    }
  }

  checkTyping() {
    this.$emit('closeBottomPanel');
    if(this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(async () => {
      await this.searchInput(this.search);
    }, 300);

  }


  modalAlerts = {
    show: false,
    isLoading: false,
    items: [],
    alerts: [],
  };

  async toggleAlertsPopup() {
    this.newAlerts = 0;
    if(this.searchResultsVisible){
      this.searchResultsVisible = !this.searchResultsVisible;
    }
    this.modalAlerts.show = !this.modalAlerts.show;
    if (!this.modalAlerts.show) {
      this.pane = null
      this.ghostpane = null;
    } else {
      setTimeout( () => {
        this.pane = document.getElementById('pane');
        this.ghostpane = document.getElementById('ghostpane');
        this.animate();
      }, 100);
    }
    const currYear = new Date().getFullYear();
    if(this.modalAlerts.isLoading) {
      return;
    }
    this.modalAlerts.isLoading = true;
    // const response = await ApiHelper.callApi(
    //   'post',
    //   {
    //     controller: "Queries",
    //     subsystem: "Helpers",
    //     FunctionName: "activities",
    //     limitActivities: 10,
    //     activityType: 'Order,Invoice,Account,Support,System',
    //     view: 'CurrentUsers',
    //     fyear: currYear,
    //     tagIDs: '',
    //     gTagIDs: '',
    //   }
    // );

    // if (response.STATUS != 1) {
    //   notifier.alert(response.STATUSMESSAGE || 'Something was wrong');
    //   this.modalAlerts.show = false;
    // } else {
    //   this.modalAlerts.items = response.ACTIVITIES || [];
    //   this.modalAlerts.alerts = response.ALERTS || [];
    // }
    // this.modalAlerts.isLoading = false;

  }

  footerClickOutside(e) {
    this.$emit('clickOutSide', e);
  }

  pane:any;
  ghostpane:any;

  // Minimum resizable area
  minWidth = 60;
  minHeight = 40;

  // Thresholds
  FULLSCREEN_MARGINS = -10;
  MARGINS = 4;

  // End of what's configurable.
  clicked: any = null;
  onRightEdge;
  onBottomEdge;
  onLeftEdge;
  onTopEdge;
  rightScreenEdge;
  bottomScreenEdge;

  preSnapped;

  b;
  x;
  y;

  redraw = false;

  setBounds(element, x, y, w, h) {
    // element.style.left = x + 'px';
    element.style.top = y + 'px';
    // element.style.width = w + 'px';
    element.style.height = h + 'px';
  }

  hintHide() {
    if (!this.pane) {
      this.pane = document.getElementById('pane');
    }
    if (!this.ghostpane) {
      this.ghostpane = document.getElementById('ghostpane');
    }
    if (!(this.pane && this.ghostpane)) {
      return;
    }
    this.setBounds(this.ghostpane, this.b.left, this.b.top, this.b.width, this.b.height);
    this.ghostpane.style.opacity = 0;
  }


  onTouchDown(e) {
    this.onDown(e.touches[0]);
    e.preventDefault();
  }

  onTouchMove(e) {
    this.onMove(e.touches[0]);
  }

  onTouchEnd(e) {
    if (e.touches.length ==0) this.onUp(e.changedTouches[0]);
  }

  onMouseDown(e) {
    this.onDown(e);
    // e.preventDefault();
  }

  onMouseUp(e) {
    this.resizeHeight = ApiHelper.saveHeightAlerts(this.pane.offsetHeight - 100);
    e.preventDefault();
  }

  onDown(e) {
    this.calc(e);
    const isResizing = this.onRightEdge || this.onBottomEdge || this.onTopEdge || this.onLeftEdge;

    this.clicked = {
      x: this.x,
      y: this.y,
      cx: e.clientX,
      cy: e.clientY,
      w: this.b.width,
      h: this.b.height,
      isResizing: isResizing,
      isMoving: !isResizing && this.canMove(),
      onTopEdge: this.onTopEdge,
      onLeftEdge: this.onLeftEdge,
      onRightEdge: this.onRightEdge,
      onBottomEdge: this.onBottomEdge
    };
  }

  canMove() {
    return this.x > 0 && this.x < this.b.width && this.y > 0 && this.y < this.b.height
      && this.y < 30;
  }

  calc(e) {
    if (!this.pane) {
      this.pane = document.getElementById('pane');
    }
    if (!this.ghostpane) {
      this.ghostpane = document.getElementById('ghostpane');
    }
    if (!(this.pane && this.ghostpane)) {
      return;
    }

    this.b = this.pane.getBoundingClientRect();
    this.x = e.clientX - this.b.left;
    this.y = e.clientY - this.b.top;

    this.onTopEdge = this.y < this.MARGINS;
    this.onLeftEdge = this.x < this.MARGINS;
    this.onRightEdge = this.x >= this.b.width - this.MARGINS;
    this.onBottomEdge = this.y >= this.b.height - this.MARGINS;

    this.rightScreenEdge = window.innerWidth - this.MARGINS;
    this.bottomScreenEdge = window.innerHeight - this.MARGINS;
  }

  e;

  onMove(ee) {
    this.calc(ee);
    this.e = ee;
    this.redraw = true;
  }

  animate() {
    if (!this.pane) {
      this.pane = document.getElementById('pane');
    }
    if (!this.ghostpane) {
      this.ghostpane = document.getElementById('ghostpane');
    }
    if (!(this.pane && this.ghostpane)) {
      return;
    }
    requestAnimationFrame(this.animate);
    if (!this.redraw) {
      return;
    }

    this.redraw = false;

    if (this.clicked && this.clicked.isResizing) {
      // if (this.clicked.onRightEdge) this.pane.style.width = Math.max(this.x, this.minWidth) + 'px';
      // if (this.clicked.onBottomEdge) this.pane.style.height = Math.max(this.y, this.minHeight) + 'px';
      //
      // if (this.clicked.onLeftEdge) {
      //   const currentWidth = Math.max(this.clicked.cx - this.e.clientX  + this.clicked.w, this.minWidth);
      //   if (currentWidth > this.minWidth) {
      //     this.pane.style.width = currentWidth + 'px';
      //     this.pane.style.left = this.e.clientX + 'px';
      //   }
      // }

      if (this.clicked.onTopEdge) {
        const currentHeight = Math.max(this.clicked.cy - this.e.clientY  + this.clicked.h, this.minHeight);

        if (currentHeight > this.minHeight) {
          this.pane.style.height = currentHeight + 'px';
          this.pane.style.top = this.e.clientY + 'px';
        }
      }

      this.hintHide();
      return;
    }
    /*

        if (this.clicked && this.clicked.isMoving) {
          if (this.b.top < this.FULLSCREEN_MARGINS || this.b.left < this.FULLSCREEN_MARGINS || this.b.right > window.innerWidth - this.FULLSCREEN_MARGINS || this.b.bottom > window.innerHeight - this.FULLSCREEN_MARGINS) {
            // hintFull();
            this.setBounds(this.ghostpane, 0, 0, window.innerWidth, window.innerHeight);
            this.ghostpane.style.opacity = 0.2;
          } else if (this.b.top < this.MARGINS) {
            // hintTop();
            this.setBounds(this.ghostpane, 0, 0, window.innerWidth, window.innerHeight / 2);
            this.ghostpane.style.opacity = 0.2;
          } else if (this.b.left < this.MARGINS) {
            // hintLeft();
            this.setBounds(this.ghostpane, 0, 0, window.innerWidth / 2, window.innerHeight);
            this.ghostpane.style.opacity = 0.2;
          } else if (this.b.right > this.rightScreenEdge) {
            // hintRight();
            this.setBounds(this.ghostpane, window.innerWidth / 2, 0, window.innerWidth / 2, window.innerHeight);
            this.ghostpane.style.opacity = 0.2;
          } else if (this.b.bottom > this.bottomScreenEdge) {
            // hintBottom();
            this.setBounds(this.ghostpane, 0, window.innerHeight / 2, window.innerWidth, window.innerWidth / 2);
            this.ghostpane.style.opacity = 0.2;
          } else {
            this.hintHide();
          }

          if (this.preSnapped) {
            this.setBounds(this.pane,
              this.e.clientX - this.preSnapped.width / 2,
              this.e.clientY - Math.min(this.clicked.y, this.preSnapped.height),
              this.preSnapped.width,
              this.preSnapped.height
            );
            return;
          }

          // moving
          this.pane.style.top = (this.e.clientY - this.clicked.y) + 'px';
          this.pane.style.left = (this.e.clientX - this.clicked.x) + 'px';
          return;
        }
    */

    // This code executes when mouse moves without clicking
    // style cursor
    // if (this.onRightEdge && this.onBottomEdge || this.onLeftEdge && this.onTopEdge) {
    //   this.pane.style.cursor = 'nwse-resize';
    // } else if (this.onRightEdge && this.onTopEdge || this.onBottomEdge && this.onLeftEdge) {
    //   this.pane.style.cursor = 'nesw-resize';
    // } else if (this.onRightEdge || this.onLeftEdge) {
    //   this.pane.style.cursor = 'ew-resize';
    // } else
    if (
      // this.onBottomEdge ||
      this.onTopEdge) {
      this.pane.style.cursor = 'ns-resize';
      // } else if (this.canMove()) {
      //   this.pane.style.cursor = 'move';
    } else {
      this.pane.style.cursor = 'default';
    }
  }



  onUp(e) {
    this.calc(e);

    if (this.clicked && this.clicked.isMoving) {

      // Snap
      const snapped = {
        width: this.b.width,
        height: this.b.height
      };
      // if (this.b.top < this.FULLSCREEN_MARGINS || this.b.left < this.FULLSCREEN_MARGINS || this.b.right > window.innerWidth - this.FULLSCREEN_MARGINS || this.b.bottom > window.innerHeight - this.FULLSCREEN_MARGINS) {
        // hintFull();
        // this.setBounds(this.pane, 0, 0, window.innerWidth, window.innerHeight);
        // this.preSnapped = snapped;
      // } else
        if (this.b.top < this.MARGINS) {
        // hintTop();
        this.setBounds(this.pane, 0, 0, window.innerWidth, window.innerHeight / 2);
        this.preSnapped = snapped;
      // } else if (this.b.left < this.MARGINS) {
        // hintLeft();
        // this.setBounds(this.pane, 0, 0, window.innerWidth / 2, window.innerHeight);
        // this.preSnapped = snapped;
      // } else if (this.b.right > this.rightScreenEdge) {
        // hintRight();
        // this.setBounds(this.pane, window.innerWidth / 2, 0, window.innerWidth / 2, window.innerHeight);
        // this.preSnapped = snapped;
      // } else if (this.b.bottom > this.bottomScreenEdge) {
        // hintBottom();
        // this.setBounds(this.pane, 0, window.innerHeight / 2, window.innerWidth, window.innerWidth / 2);
        // this.preSnapped = snapped;
      } else {
        this.preSnapped = null;
      }
      this.hintHide();
    }
    this.clicked = null;
  }
}
