import { render, staticRenderFns } from "./CustomData.vue?vue&type=template&id=f9b9dfb8&scoped=true"
import script from "./CustomData.vue?vue&type=script&lang=tsx"
export * from "./CustomData.vue?vue&type=script&lang=tsx"
import style0 from "./CustomData.vue?vue&type=style&index=0&id=f9b9dfb8&prod&lang=less"
import style1 from "./CustomData.vue?vue&type=style&index=1&id=f9b9dfb8&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9b9dfb8",
  null
  
)

export default component.exports