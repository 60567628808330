



































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";

@Component({
  inheritAttrs: true,
  components: {}
})

export default class StandardProductsToolTip extends TSXComponent<void> {

  @Prop({ required: false, default: false })
  topTooltip!: boolean;

  @Prop({ required: false, default: "" })
  standardUUID!: string;

  @Prop({ required: false, default: 0 })
  totalProducts!: number;

  items = [];
  loading: boolean = false;
  
  clickOutside() {
    this.$emit("close");
  }

  async created() {
    if (this.standardUUID) {
      this.loading = true;
      const response = await ApiHelper.callApi('post', {
        controller: "Quotes",
        FunctionName: "StandardProducts",
        Content: "top5",
        standardUUID: this.standardUUID,
      });
      if (response.STATUS === 1) {
        this.items = response.ITEMS || [];
      }
      this.loading = false;
    }
  }
}
