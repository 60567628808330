





























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";


declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    DropdownControl
  }
})
export default class AddBusinessLineModal extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement
  }

  @Prop({ required: false, default: false })
  isUpdate?: boolean;

  @Prop({ required: false, default: "Add Business Lines" })
  title!: string;

  @Prop({ required: false, default: {} })
  data!: any;

  $validator: any;
  businessLineName: string = "";
  saving: boolean = false;
  businessLineId: number = 0;
  subBusinessLines: any = [];

  mounted() {
    $(this.$refs.modal).modal('show');

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    });
  }

  created() {
    if (this.isUpdate && this.data) {
      this.title = "Update Business Lines";
      if (this.data.BUSINESSLINENAME) {
        this.businessLineName = this.data.BUSINESSLINENAME;
      }

      if (this.data.ID) {
        this.businessLineId = this.data.ID;
      }

      if (this.data.SUBBUSINESSLINES) {
        this.subBusinessLines = JSON.parse(JSON.stringify(this.data.SUBBUSINESSLINES));
        this.addInputRow(this.subBusinessLines.length);
      }
    }
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  async save() {
    const valid = await this.$validator.validateAll();

    if (!valid) {
      return;
    }

    this.checkBL();
    var duplicateBLExist = this.subBusinessLines.find(o => o.ISDUPLICATE === 1);
    if (duplicateBLExist) {
      notifier.alert("Duplicate Business Line exists");
      return;
    }

    try {
      this.saving = true;
      let subBusinessLinesData = this.subBusinessLines.filter((item: any) => item.BUSINESSLINENAME.length)
      const response = await ApiHelper.callApi("post", {
        controller: "Helpers",
        FunctionName: "BusinessLine",
        businessLineName: this.businessLineName,
        action: this.isUpdate ? 'Update' : "Add",
        businessLineId: this.businessLineId,
        subBusinessLines: subBusinessLinesData
      });

      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        this.$emit('reload');
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
    } catch (error) {
      // console.log('error ', error);
    } finally {
      this.saving = false;
    }
  }

  addInputRow(index) {
    if (index === this.subBusinessLines.length) {
      var newSubBusinessLine = {
        ID: 0,
        BUSINESSLINENAME: "",
        PARENTID: 0,
        ISACTIVE: 1,
        ISDUPLICATE: 0
      };
      this.subBusinessLines.push(newSubBusinessLine);
    }
  }

  deleteInputRow(index) {
    if (index < this.subBusinessLines.length) {
      this.subBusinessLines.splice(index, 1);
      this.checkBL();
    }
  }

  checkBL() {
    this.subBusinessLines.map((item: any) => {
      var filteredBL = this.subBusinessLines.filter(checkDupItem => item.BUSINESSLINENAME != "" && checkDupItem.BUSINESSLINENAME != "" && checkDupItem.BUSINESSLINENAME == item.BUSINESSLINENAME);
      item.ISDUPLICATE = 0;
      if (filteredBL.length > 1) {
        item.ISDUPLICATE = 1;
      }
    });
    this.$forceUpdate();
  }
}
