











































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";

declare const dataURL: string;

interface ItemProps {
  DESKTOPSCOUNT: number;
  ACCESSORIESCOUNT: number;
  MONITORSCOUNT: number;
  QUANTITY: number;
  ID: number;
  CATEGORYNAME: string;
  AID: number;
  EUNAME: string;
  ENDUSERFULLADDRESS: string;
}

@Component
export default class HardwareDevicesComponent extends Vue {
  @Prop() readonly item!: ItemProps;
  activePopover: string | null = null;
  activeHardwareData: any = [];
  isMouseOverPopover= false;
  loading = false;
  mouseoutTimeoutId: any = null;
  tooltipPosition : string = '';
  totalShow = 5;

  async mouseover(type: string, euId: number, quantity: number) {
    this.checkOffset();

    if(!this.isMouseOverPopover) {
      if (this.mouseoutTimeoutId) {
        clearTimeout(this.mouseoutTimeoutId);
        this.mouseoutTimeoutId = null;
      }

      if(quantity != 0){
          this.activeHardwareData = [];
          this.totalShow = 5;
          this.activePopover = type;
          this.loading = true;
          var data = {};

          data = {
            controller: "Hardware",
            FunctionName: "DeviceList",
            type: type,
            euId,
            aId: this.item.AID || 0,
            euName: this.item.EUNAME || "",
            euFullAddress: this.item.ENDUSERFULLADDRESS || "",
          };
          try {
              const response = await axios.post(dataURL + "?ReturnType=JSON", data);
              this.loading = false;
              this.activeHardwareData = response.data.HARDWARES;
          } catch (error) {
              console.error("Error fetching hardware data:", error);

              this.activeHardwareData = [];
          }
      }
      else{
          this.activePopover = null;
      }
    }
  }

  checkOffset(){
    const tooltipHeight = 132;
    const offset = 60; 

    // Get the target element's position and height
    const target: any = event ? event.target : null;
    const targetRect = target.getBoundingClientRect();
    const targetBottomPosition = targetRect.bottom;

    // Calculate available space below the target element
    const spaceBelow = window.innerHeight - targetBottomPosition;

    // Determine if the tooltip needs to open above
    if (spaceBelow < tooltipHeight + offset) {
      // Add the "top" class to make the tooltip open above
      this.tooltipPosition = 'top';
    } else {
      // Remove the "top" class or set to default to open below
      this.tooltipPosition = ''; // or any other class that signifies the default position
    }
  }

  mouseout() {
    this.mouseoutTimeoutId = setTimeout(() => {
      if (!this.isMouseOverPopover) {
          this.activePopover = null;
      }
      this.mouseoutTimeoutId = null;
    }, 300);
  }

  popoverMouseover() {
    this.isMouseOverPopover = true;
    if (this.mouseoutTimeoutId) {
      clearTimeout(this.mouseoutTimeoutId);
      this.mouseoutTimeoutId = null;
    }
  }

  popoverMouseout() {
    this.isMouseOverPopover = false;
    this.mouseoutTimeoutId = setTimeout(() => {
      this.activePopover = null;
      this.mouseoutTimeoutId = null;
    }, 300);
  }

  getMoreDevices(items) {
    let ret = 0;
    for (const item of items.slice(5)) {
      ret += item.QTY || 0;
    }

    return ret;
  }

  showFullDevices() {
    this.totalShow = this.activeHardwareData.length;
  }
}
