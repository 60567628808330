







































































































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";
import Loader from "./Loader.vue";
import LaddaButton from "@/components/LaddaButton.vue";
import { ApiHelper } from "@/helpers/all";

declare const $: any;
interface Props {
  currentItem: any;
}
interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    LaddaButton
  }
})
export default class ProductCatTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  currentItem!: any;

  @Prop({ required: false, default: false })
  isquote!: boolean;

  @Prop({ required: false, default: false })
  isinvoice!: boolean;

  @Prop({ required: false, default: -1 })
  currentIndex?: number;

  @Prop({ required: false, default: 0 })
  subCurrentIndex?: number;

  @Prop({ required: false, default: false })
  hideConfigToggle!: boolean;

  @Prop({ required: false, default: {} })
  configCategory!: any;

  @Prop({ required: false, default: "" })
  from!: string;

  @Prop({ required: false, default: true })
  showPL!: boolean;

  $parent: any;
  loading = false;
  catList: object[] = [];
  fullCatList: object[] = [];
  search = "";
  isConfig = false;
  plSearch: string = "";
  plList: any = [];
  plListBK: any = [];
  newPL: string = "";
  saving: boolean = false;
  showAddPL: boolean = false;
  showAddNewPL: boolean = false;

  clickOutside() {
    this.$emit("close");
  }

  async created() {
    // check if this is config item
    // const configCategoryId = this.configCategory.CATEGORYID || 0;
    // if(configCategoryId > 0 && configCategoryId == (this.currentItem.ItemCategory || 0)) {
    //   this.isConfig = true;
    // }
    this.isConfig = !!(this.currentItem.ISCONFIG || 0);

    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "Category",
        ignoreConfig: true,
        productSKU: this.currentItem.sku || "",
        categoryID: this.currentItem.ItemCategory || 0
      });

      if (response.data.STATUS == 1) {
        this.fullCatList = response.data.CATEGORY;
        this.catList = response.data.CATEGORY;
        this.plList = response.data.PRODUCTLINES || [];
        this.plListBK = JSON.parse(
          JSON.stringify(response.data.PRODUCTLINES || [])
        );
        // this.currentItem.ItemPLID = response.data.PRODUCTLINEID || 0;
        // this.currentItem.ItemPLName = response.data.PRODUCTLINENAME || '';
        this.$parent.$forceUpdate();
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  selectCategory(catItem) {
    if (this.isConfig) return;

    this.currentItem.ItemCategory = catItem.CATEGORYID;
    this.currentItem.ItemCategoryName = catItem.CATEGORYNAME;
    this.currentItem.ItemPLID = catItem.PRODUCTLINEID;
    this.currentItem.ItemPLName = catItem.PRODUCTLINENAME;
    this.currentItem.categoryType = catItem.CATEGORYTYPE;
    this.$parent.$forceUpdate();
    this.$emit("updateItemCategory", {
      index: this.currentIndex,
      subIndex: this.subCurrentIndex,
      ItemCategory: this.currentItem.ItemCategory,
      ItemCategoryName: this.currentItem.ItemCategoryName,
      selectedItem: this.currentItem
    });
    this.$emit("close");
  }

  async categoryLookup() {
    let options = this.fullCatList;

    if (this.search) {
      options = options.filter(
        (option: any) =>
          `${option.CATEGORYNAME}`
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1
      );
    }

    this.catList = options;
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  /* REMOVING ABILITY TO CREATE A CATEGORY BUT LEAVING IN CODE FOR FUTURE REFERENCE */
  /*   async createCategory() {
    //remove HTML
    this.search = this.removeHTML(this.search);

    if (this.search != "" && this.search.length >= 2) {
      try {
        this.loading = true;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Helpers",
          FunctionName: "Category",
          category: this.search,
          action: "add"
        });

        if (response.data.STATUS == 1) {
          if (response.data.newCatID > 0) {
            this.currentItem.ItemCategory = response.data.newCatID;
            this.currentItem.ItemCategoryName = this.search;
            this.$parent.$forceUpdate();
            this.$emit("close");
          } else {
            this.categoryLookup();
          }
        }
      } catch (err) {
        // console.log(err.message);
      } finally {
        this.loading = false;
      }
    }
  } */

  async configToggleChange() {
    const configCategoryId = this.configCategory.CATEGORYID || 0;
    this.currentItem.ISCONFIG = this.isConfig ? 1 : 0;
    if (this.isConfig) {
      // turn on "configuration"
      this.currentItem.ItemCategory = this.configCategory.CATEGORYID || 0;
      this.currentItem.ItemCategoryName =
        this.configCategory.CATEGORYNAME || "";
    } else {
      // turn off "configuration"
      if (this.currentItem.ItemCategory == configCategoryId) {
        this.currentItem.ItemCategory = 0;
        this.currentItem.ItemCategoryName = "";
      }
      this.currentItem.savedAsConfig = 0;
    }

    this.$emit("configToggleChange");
    this.$parent.$forceUpdate();

    this.$emit("updateItemCategory", {
      index: this.currentIndex,
      subIndex: this.subCurrentIndex,
      ItemCategory: this.currentItem.ItemCategory,
      ItemCategoryName: this.currentItem.ItemCategoryName,
      selectedItem: this.currentItem,
      configToggle: true
    });
  }

  async savePL() {
    let check = true;
    if (this.newPL.length > 3) {
      notifier.alert("You can't enter more than 3 characters");
      check = false;
    } else if (!this.newPL.length) {
      notifier.alert("Please enter the Product Line");
      check = false;
    }

    let checkDupPL = this.plList.filter(
      (item: any) =>
        item.PRODUCTLINENAME.toLowerCase() == this.newPL.toLowerCase()
    );

    if (checkDupPL.length) {
      notifier.alert("Product Line Already Exist!");
      check = false;
    }

    if (check) {
      this.saving = true;
      const response = await ApiHelper.callApi("post", {
        controller: "Helpers",
        FunctionName: "ProductLine",
        productLine: this.newPL,
        action: "add",
        productSKU: this.currentItem.sku || "",
        categoryID: this.currentItem.ItemCategory || 0
      });
      if (response.STATUS === 1) {
        let newPLStruct = {
          PRODUCTLINENAME: response.PRODUCTLINENAME,
          PRODUCTLINEID: response.PRODUCTLINEID
        };
        this.plList.push(newPLStruct);
        this.plListBK.push(newPLStruct);
        this.newPL = "";
        this.plSearch = "";
        this.productLineLookup();
        this.currentItem.ItemPLID = response.PRODUCTLINEID;
        this.currentItem.ItemPLName = response.PRODUCTLINENAME;
        this.$parent.$forceUpdate();
        notifier.success(response.STATUSMESSAGE);
      } else {
        notifier.alert(response.STATUSMESSAGE || "Something Went Wrong");
      }
      this.saving = false;
    }
  }

  async productLineLookup() {
    this.showAddNewPL = false;
    this.showAddPL = false;
    this.newPL = "";
    let options = [...this.plListBK];

    if (this.plSearch) {
      options = options.filter(
        (option: any) =>
          `${option.PRODUCTLINENAME}`
            .toLowerCase()
            .indexOf(this.plSearch.toLowerCase()) !== -1
      );
    }

    this.plList = options;
    if (!this.plList.length && this.plSearch.length) {
      this.showAddNewPL = true;
    }
  }

  async selectProductLine(plItem) {
    this.currentItem.ItemPLID = plItem.PRODUCTLINEID;
    this.currentItem.ItemPLName = plItem.PRODUCTLINENAME;
    this.$parent.$forceUpdate();
    if (plItem.PRODUCTLINEID) {
      const response = await ApiHelper.callApi("post", {
        controller: "Helpers",
        FunctionName: "ProductLine",
        plID: plItem.PRODUCTLINEID,
        action: "setPLtoCate",
        productSKU: this.currentItem.sku || "",
        categoryID: this.currentItem.ItemCategory || 0
      });
    }
    this.$emit("close");
  }

  deletePL() {
    this.currentItem.ItemPLID = 0;
    this.currentItem.ItemPLName = "";
    this.$parent.$forceUpdate();
    this.$emit("close");
  }

  deleteCategory() {
    this.currentItem.ItemCategory = 0;
    this.currentItem.ItemCategoryName = "";
    this.$parent.$forceUpdate();
    this.$emit("updateItemCategory", {
      index: this.currentIndex,
      subIndex: this.subCurrentIndex,
      ItemCategory: this.currentItem.ItemCategory,
      ItemCategoryName: this.currentItem.ItemCategoryName,
      selectedItem: this.currentItem
    });
    this.$emit("close");
  }
}
