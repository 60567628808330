import { render, staticRenderFns } from "./CustomerDetailsTabTags.vue?vue&type=template&id=259a9202&scoped=true"
import script from "./CustomerDetailsTabTags.vue?vue&type=script&lang=tsx"
export * from "./CustomerDetailsTabTags.vue?vue&type=script&lang=tsx"
import style0 from "./CustomerDetailsTabTags.vue?vue&type=style&index=0&id=259a9202&prod&scoped=true&lang=less"
import style1 from "./CustomerDetailsTabTags.vue?vue&type=style&index=1&id=259a9202&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "259a9202",
  null
  
)

export default component.exports