




















































































































































































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ErrorBag } from "vee-validate";
import CustomerAutoSuggestInput from "./CustomerAutoSuggestInput.vue";
import Datepicker from "./Datepicker.vue";
import { InvoiceBasicDetailsFormData } from "../models/invoice";
import { notifier } from "../models/common";
import DropdownControl from "../components/DropdownControl.vue";
import USState from "../static/USstates.json";
import CustomerAddressTooltip from "../components/CustomerAddressTooltip.vue";
import MessageBox from "../components/MessageBox.vue";
import GlobalTagSelect from "../components/GlobalTagSelect.vue";

interface Props {}
interface Events {}
declare const dataURL: string;
declare const getRouteData: Function;
declare const htmlParse: Function;

@Component({
  inheritAttrs: false,
  components: {
    CustomerAutoSuggestInput,
    Datepicker,
    DropdownControl,
    CustomerAddressTooltip,
    MessageBox,
    GlobalTagSelect
  }
})
export default class InvoiceFormBasicDetails extends TSXComponent<
  Props,
  Events
> {
  @Prop({
    required: false,
    default: {
      invoiceId: "[Auto Generate]",
      invoiceName: "",
      PO: "",
      shippingId: "",
      invoiceDate: "",
      invoiceDueDate:"",
      customer: {},
      address: "",
      city: "",
      state: "",
      zip: ""
    }
  })
  invBasicDetails!: InvoiceBasicDetailsFormData;

  @Prop({required: false, default: []})
  orderContractNumber?: object[];
  
  @Prop({required: false, default: []})
  customDropdown?: object[];
  
  @Prop({required: false, default: []})
  selectedCustomDropdown?: number[];

  $parent: any;
  errors!: ErrorBag;
  customerAddresses: any = [];
  selectedAddress: number[] = [];
  customerAddressesBilling: any = [];
  selectedAddressBilling: number[] = [];
  stateList: any = [];
  selectedState: string[] = [];
  selectedStateBilling: string[] = [];
  $refs!: {
    drodown1: DropdownControl;
    drodown: DropdownControl;
    Datepicker: Datepicker;
  };
  onetimeAddress: any = {};
  onetimeAddressBilling: any = {};
  customerAddressVisible = false;
  customerAddressBillingVisible = false;
  addNewType = "";
  addNewTypeBilling = "";
  messageBoxModal = false;
  // orderContractNumber: object[] = [];
  // customDropdown: object[] = [];
  // selectedCustomDropdown: number[] = [];
  invoiceType: object[] = [
    {
      ID: 1,
      TEXT: 'Credit Memo'
    },
    {
      ID: 2,
      TEXT: 'HPE Direct'
    },
    {
      ID: 3,
      TEXT: 'Adjustment'
    },
    {
      ID: 4,
      TEXT: 'Invoice'
    },
    {
      ID: 5,
      TEXT: 'Credit Card'
    }
  ];
  selectedInvTypeID: number[] = [4];
  selectedInvTypeDetails: any = {
    ID: 4,
    TEXT: 'Invoice'
  };
  
  @Watch("invBasicDetails.invoiceDueDate")
  async updateDueDate() {
    this.$refs.Datepicker.setDate(this.invBasicDetails.invoiceDueDate);
  }

  async created() {
    for(const i in USState) {
      this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
    }
  }

  get validation() {
    const validations: Object = {};
    validations["required"] = true;
    return validations;
  }

  htmlCheck(InputVal) {
    if (InputVal.length > htmlParse(InputVal).length) {
      return true;
    } else {
      return false;
    }
  }

  async updateCustomer(data) {
    // var self = this;
    // getRouteData({
    //   controller: "Accounts",
    //   FunctionName: "defaultAddress",
    //   aID: data.account.AID
    // }).then(function(response, statusText, jqXHR) {
    //   if (response.data.STATUS == 1 && response.data.DEFAULTADDRESSCOUNT != 0) {
    //     self.invBasicDetails.address = response.data.DEFAULTADDRESS[0].ADDR1;
    //     self.invBasicDetails.city = response.data.DEFAULTADDRESS[0].CITY;
    //     self.invBasicDetails.state = response.data.DEFAULTADDRESS[0].STATE;
    //     self.invBasicDetails.zip = response.data.DEFAULTADDRESS[0].ZIP;
    //   } else {
    //     self.invBasicDetails.address = data.account.AADDRESS;
    //     self.invBasicDetails.city = data.account.ACITY;
    //     self.invBasicDetails.state = data.account.ASTATE;
    //     self.invBasicDetails.zip = data.account.AZIP;
    //   }
    //   self.invBasicDetails.customer.value = data.account.ANAME;
    //   self.invBasicDetails.customer.aID = data.account.AID;
    // });

    this.invBasicDetails.customer.value = data.account.ANAME;
    this.invBasicDetails.customer.aID = data.account.AID;
    this.updateAddressForCustomer(data.account.AID, data.account);
  }

  async updateAddressForCustomer(aID, defaultVal,defShipping = 0) {
    // try {
      // reset customer address
      this.customerAddresses = [];
      this.selectedAddress = [];
      this.customerAddressesBilling = [];
      this.selectedAddressBilling = [];
      if(aID > 0) {
        await this.getCustomerAddresses(aID);
      }

      // get default address
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Accounts",
        FunctionName: "defaultAddress",
        orderShipping : defShipping,
        aID: aID
      });
      if(response.data.STATUS == 1) {
        if (response.data.INVOICEFLAG) {
          // this.messageBoxModal = true;
        }
        // fill shipping address
        if(defShipping > 0){
          const defaultShippingAddr = response.data.DEFAULTADDRESS.find(tmp => tmp.ID == defShipping);
          if(defaultShippingAddr) {
            this.invBasicDetails.address = defaultShippingAddr.ADDR1;
            this.invBasicDetails.address2 = defaultShippingAddr.ADDR2;
            this.invBasicDetails.city = defaultShippingAddr.CITY;
            this.invBasicDetails.state = defaultShippingAddr.STATE.toUpperCase();
            this.invBasicDetails.zip = defaultShippingAddr.ZIP;
            this.selectedAddress = [defaultShippingAddr.ACCOUNTSADDRESS_ID];
            this.selectedState = [this.invBasicDetails.state];
          }
        }else{
          const defaultShippingAddr = response.data.DEFAULTADDRESS.find(tmp => tmp.ACCOUNTSADDRESS_TYPE == 1);
          if(defaultShippingAddr) {
            // if had a default shipping address
            this.invBasicDetails.address = defaultShippingAddr.ADDR1;
            this.invBasicDetails.address2 = defaultShippingAddr.ADDR2;
            this.invBasicDetails.city = defaultShippingAddr.CITY;
            this.invBasicDetails.state = defaultShippingAddr.STATE.toUpperCase();
            this.invBasicDetails.zip = defaultShippingAddr.ZIP;
            this.selectedAddress = [defaultShippingAddr.ACCOUNTSADDRESS_ID];
            this.selectedState = [this.invBasicDetails.state];
          }else {
            // if no, use the address when create this account for shipping address
            this.invBasicDetails.address = defaultVal.AADDRESS || "";
            this.invBasicDetails.address2 = "";
            this.invBasicDetails.city = defaultVal.ACITY || "";
            this.invBasicDetails.state = (defaultVal.ASTATE || "").toUpperCase();
            this.invBasicDetails.zip = defaultVal.AZIP || "";
            this.selectedState = [this.invBasicDetails.state];
            this.selectedAddress = [defShipping];
          }
        }

        // fill billing address
        const defaultBillingAddr = response.data.DEFAULTADDRESS.find(tmp => tmp.ACCOUNTSADDRESS_TYPE == 2);
        if(defaultBillingAddr) {
          // if had a default billing address
          this.invBasicDetails.addressBilling = defaultBillingAddr.ADDR1;
          this.invBasicDetails.address2Billing = defaultBillingAddr.ADDR2;
          this.invBasicDetails.cityBilling = defaultBillingAddr.CITY;
          this.invBasicDetails.stateBilling = defaultBillingAddr.STATE.toUpperCase();
          this.invBasicDetails.zipBilling = defaultBillingAddr.ZIP;
          this.selectedAddressBilling = [defaultBillingAddr.ACCOUNTSADDRESS_ID];
          this.selectedStateBilling = [this.invBasicDetails.stateBilling];
        }else {
          // if no, use the address when create this account for shipping address
          this.invBasicDetails.addressBilling = defaultVal.AADDRESS || "";
          this.invBasicDetails.address2Billing = "";
          this.invBasicDetails.cityBilling = defaultVal.ACITY || "";
          this.invBasicDetails.stateBilling = (defaultVal.ASTATE || "").toUpperCase();
          this.invBasicDetails.zipBilling = defaultVal.AZIP || "";
          this.selectedStateBilling = [this.invBasicDetails.stateBilling];
        }
      }
    // } catch (error) {
    //   // console.log(error);
    // }
  }

  /*async validateAll(): Promise<false | InvoiceBasicDetailsFormData> {
    var valid = await this.$validator.validateAll();
    var htmlCount = 0;
    for (var i = 0; i < Object.keys(this.invBasicDetails).length; i++) {
      if (this.invBasicDetails[Object.keys(this.invBasicDetails)[i]]) {
        if (
          htmlCheck(this.invBasicDetails[Object.keys(this.invBasicDetails)[i]])
        ) {
          this.invBasicDetails[
            Object.keys(this.invBasicDetails)[i]
          ] = htmlParse(
            this.invBasicDetails[Object.keys(this.invBasicDetails)[i]]
          );
          htmlCount++;
        }
      }
    }
    if (htmlCount != 0) {
      notifier.alert("HTML content found. This content has been removed!");
      valid = false;
    } else {
      valid = true;
    }
    var date_regex = /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    if ($("#invoiceDate").val() != "") {
      if (
        !$("#invoiceDate")
          .val()
          .trim()
          .match(date_regex)
      ) {
        $(".errorMsg.invoiceDate").text("Please Enter Valid Date");
        valid = false;
      } else {
        $(".errorMsg.invoiceDate").text("");
      }
    }
    if ($("#state").val() != "") {
      if (
        !$("#state")
          .val()
          .trim()
          .match("^[A-Za-z0-9 ]*$")
      ) {
        $(".errorMsg.state").text("Please Enter Valid State");
        valid = false;
      } else {
        $(".errorMsg.state").text("");
      }
    }
    if ($("#zip").val() != "") {
      if (
        !$("#zip")
          .val()
          .match("^[0-9]*$")
      ) {
        $(".errorMsg.zip").text("Please Enter a Valid Zip Code");
        valid = false;
      } else {
        $(".errorMsg.zip").text("");
      }
    }
    if ($("#city").val() != "") {
      if (
        !$("#city")
          .val()
          .trim()
          .match("^[A-Za-z0-9 ]*$")
      ) {
        $(".errorMsg.city").text("Please Enter Valid City");
        valid = false;
      } else {
        $(".errorMsg.city").text("");
      }
    }
    if (!valid) {
      return false;
    }

    // return this.formData;
    return this.invBasicDetails;
  }*/

  async getCustomerAddresses(aID) {
    if(aID > 0) {
      // try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Queries",
          subsystem: "Helpers",
          FunctionName: "getAccountAddresses",
          aID: aID,
          addressType: "1,2",
        });
        if(response.data.STATUS) {
          // const addressArr = response.data.addressArr;
          const shippingAddr = response.data.addressArr.filter(tmp => tmp.ACCOUNTSADDRESS_TYPE == 1);
          const billingAddr = response.data.addressArr.filter(tmp => tmp.ACCOUNTSADDRESS_TYPE == 2);
          if(shippingAddr.length) {
            this.customerAddresses = shippingAddr.map(tmp => ({
              ID: tmp.ACCOUNTSADDRESS_ID,
              TEXT: this.getAddressText(tmp),
              data: tmp
            }));
          }
          // option to add a permanent address, and one time using
          this.customerAddresses.push({
            ID: "permanent",
            TEXT: "Enter a shipping address"
          });
          if(Object.keys(this.onetimeAddress).length) {
            this.customerAddresses.unshift({
              ID: 0,
              TEXT: this.getAddressText(this.onetimeAddress),
              data: this.onetimeAddress
            });
          }
          if(billingAddr.length) {
            this.customerAddressesBilling = billingAddr.map(tmp => ({
              ID: tmp.ACCOUNTSADDRESS_ID,
              TEXT: this.getAddressText(tmp),
              data: tmp
            }));
          }
          // option to add a permanent address, and one time using
          this.customerAddressesBilling.push({
            ID: "permanent",
            TEXT: "Enter a billing address"
          });
          if(Object.keys(this.onetimeAddressBilling).length) {
            this.customerAddressesBilling.unshift({
              ID: 0,
              TEXT: this.getAddressText(this.onetimeAddressBilling),
              data: this.onetimeAddressBilling
            });
          }
        }
      // } catch (error) {
      //   // console.log(error);
      // }
    }
  }

  getAddressText(address) {
    var ret = "";
    const name = address.ADDRESSNAME || "";
    const addr1 = address.ACCOUNTSADDRESS_ADDRESS1 || "";
    const addr2 = address.ACCOUNTSADDRESS_ADDRESS2 || "";
    const city = address.ACCOUNTSADDRESS_CITY || "";
    const state = address.ACCOUNTSADDRESS_STATE || "";
    const zip = address.ACCOUNTSADDRESS_ZIP || "";
    if(name != "") {
      ret += name + " - ";
    }
    if(addr1 != "") {
      ret += addr1 + ",";
    }
    if(addr2 != "") {
      ret += " " + addr2 + ",";
    }
    if(city != "") {
      ret += " " + city;
    }
    if(state != "" || zip != "") {
      ret += ",";
      if(state != "") {
        ret += " " + state;
      }
      if(zip != "") {
        ret += " " + zip;
      }
    }

    return ret;
  }

  updateCustomerAddress(selectedID) {
    if(selectedID == "permanent" || selectedID == "onetime") {
      this.addNewType = selectedID;
      this.customerAddressVisible = true;
    }else {
      // fill shipping address
      const selected = this.customerAddresses.find(tmp => tmp.ID == selectedID);
      if(selected) {
        this.selectedAddress = [selectedID];
        this.invBasicDetails.address = selected.data.ACCOUNTSADDRESS_ADDRESS1 || "";
        this.invBasicDetails.address2 = selected.data.ACCOUNTSADDRESS_ADDRESS2 || "";
        this.invBasicDetails.city = selected.data.ACCOUNTSADDRESS_CITY || "";
        this.invBasicDetails.state = (selected.data.ACCOUNTSADDRESS_STATE || "").toUpperCase();
        this.invBasicDetails.zip = selected.data.ACCOUNTSADDRESS_ZIP || "";
        this.selectedState  = [this.invBasicDetails.state];
      }
    }
    if(this.customerAddresses.length) {
      this.$parent.sortingAddresses(this.customerAddresses, this.selectedAddress);
    }
  }

  updateCustomerAddressBilling(selectedID) {
    if(selectedID == "permanent" || selectedID == "onetime") {
      this.addNewTypeBilling = selectedID;
      this.customerAddressBillingVisible = true;
    }else {
      // fill billing address
      const selected = this.customerAddressesBilling.find(tmp => tmp.ID == selectedID);
      if(selected) {
        this.selectedAddressBilling = [selectedID];
        this.invBasicDetails.addressBilling = selected.data.ACCOUNTSADDRESS_ADDRESS1 || "";
        this.invBasicDetails.address2Billing = selected.data.ACCOUNTSADDRESS_ADDRESS2 || "";
        this.invBasicDetails.cityBilling = selected.data.ACCOUNTSADDRESS_CITY || "";
        this.invBasicDetails.stateBilling = (selected.data.ACCOUNTSADDRESS_STATE || "").toUpperCase();
        this.invBasicDetails.zipBilling = selected.data.ACCOUNTSADDRESS_ZIP || "";
        this.selectedStateBilling = [this.invBasicDetails.stateBilling];
        var objDate = new Date(
          new Date().toLocaleString("en-US", {
            timeZone: "America/Denver",
            hour12: false
          })
        );
        var newDueDate = new Date(objDate.setDate(objDate.getDate() + selected.data.SELECTEDNETTERM));
        this.invBasicDetails.invoiceDueDate = [
          (newDueDate.getMonth() + 1 < 10 ? "0" : "") +
            (newDueDate.getMonth() + 1),
          (newDueDate.getDate() < 10 ? "0" : "") + newDueDate.getDate(),
          newDueDate.getFullYear()
        ].join("/");
      }
    }
    if(this.customerAddressesBilling.length) {
      this.$parent.sortingAddresses(this.customerAddressesBilling, this.selectedAddressBilling);
    }
  }

  // updatecustomDropdown(selectedID) {
  //   this.selectedCustomDropdown = [selectedID];
  //   this.invBasicDetails.customValue = selectedID;
  //   this.$parent.selectedCustomDropdown = [selectedID];
  // }

  toggleSelectedContractNumber(selectedID: number, contractName) {
    if(this.selectedCustomDropdown){
      const index = this.selectedCustomDropdown.findIndex(id => id === selectedID);
      if (index === -1) {
        this.selectedCustomDropdown = [selectedID];
        this.invBasicDetails.customValue = contractName;
        this.$parent.selectedCustomDropdown = [selectedID];
        return;
      }
      this.selectedCustomDropdown.splice(index, 1);
      this.invBasicDetails.customValue = "";
      this.$parent.selectedCustomDropdown.splice(index, 1);
    }
  }

  updateShippingState(selectedID) {
    this.selectedState = [selectedID];
    this.invBasicDetails.state = selectedID;
  }

  updateBillingState(selectedID) {
    this.selectedStateBilling = [selectedID];
    this.invBasicDetails.stateBilling = selectedID;
  }

  close(){
    this.$refs.drodown.dropdownVisible = false;
    this.$refs.drodown1.dropdownVisible = false;
  }

  async addNewAddress(addressList, type) {
    if(type == "shipping") {
      // add permanent or one time using for invoice shipping address
      if(addressList[0].isSave) {
        this.onetimeAddress = {};
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Accounts",
          FunctionName: "Update",
          aID: this.invBasicDetails.customer.aID,
          addressList: addressList,
          addOneAddress: true
        });
        if(response.data.STATUS == 1 && typeof response.data.AccountsAddress_ID != "undefined") {
          await this.getCustomerAddresses(this.invBasicDetails.customer.aID);
          this.updateCustomerAddress(response.data.AccountsAddress_ID);
          this.customerAddressVisible = false;
        }
        else if (response.data.STATUS == 2) {
          $('#customer_address_name').addClass("error");
          notifier.alert(response.data.MESSAGE);
        }
      }else{
        this.onetimeAddress = {
          ADDRESSNAME: addressList[0].NAME,
          ACCOUNTSADDRESS_ADDRESS1: addressList[0].ADDR1,
          ACCOUNTSADDRESS_ADDRESS2: addressList[0].ADDR2,
          ACCOUNTSADDRESS_CITY: addressList[0].CITY,
          ACCOUNTSADDRESS_STATE: addressList[0].STATE,
          ACCOUNTSADDRESS_ZIP: addressList[0].ZIP
        };
        const newAddressItem = {
          ID: 0,// for one time address
          TEXT: this.getAddressText(this.onetimeAddress),
          data: this.onetimeAddress
        };
        let onetimeAddr = this.customerAddresses.find(item => item.ID == 0);
        if(onetimeAddr) {
          onetimeAddr = Object.assign(onetimeAddr, newAddressItem);
        }else {
          this.customerAddresses.unshift(newAddressItem);
        }
        this.updateCustomerAddress(0);
        this.customerAddressVisible = false;
      }
    } else if(type == "billing") {
      // add permanent or one time using for invoice billing address
      if(addressList[0].isSave) {        
        this.onetimeAddressBilling = {};
        // set address type = billing
        addressList[0].ADDRTYPE = 2;
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Accounts",
          FunctionName: "Update",
          aID: this.invBasicDetails.customer.aID,
          addressList: addressList,
          addOneAddress: true
        });
        if(response.data.STATUS == 1 && typeof response.data.AccountsAddress_ID != "undefined") {
          await this.getCustomerAddresses(this.invBasicDetails.customer.aID);
          this.updateCustomerAddressBilling(response.data.AccountsAddress_ID);
          this.customerAddressBillingVisible = false;
        }
        else if (response.data.STATUS == 2) {
          $('#customer_address_name').addClass("error");
          notifier.alert(response.data.MESSAGE);
        }
      }else {
        this.onetimeAddressBilling = {
          ADDRESSNAME: addressList[0].NAME,
          ACCOUNTSADDRESS_ADDRESS1: addressList[0].ADDR1,
          ACCOUNTSADDRESS_ADDRESS2: addressList[0].ADDR2,
          ACCOUNTSADDRESS_CITY: addressList[0].CITY,
          ACCOUNTSADDRESS_STATE: addressList[0].STATE,
          ACCOUNTSADDRESS_ZIP: addressList[0].ZIP,
          SELECTEDNETTERM: addressList[0].SELECTEDNETTERM
        };
        const newAddressItem = {
          ID: 0,// for one time address
          TEXT: this.getAddressText(this.onetimeAddressBilling),
          data: this.onetimeAddressBilling
        };
        let onetimeAddr = this.customerAddressesBilling.find(item => item.ID == 0);
        if(onetimeAddr) {
          onetimeAddr = Object.assign(onetimeAddr, newAddressItem);
        }else {
          this.customerAddressesBilling.unshift(newAddressItem);
        }
        this.updateCustomerAddressBilling(0);
        this.customerAddressBillingVisible = false;
      }
    }
  }

  updateInvoiceType(selectedID) {
    this.selectedInvTypeID = [selectedID];
    this.selectedInvTypeDetails = this.invoiceType.find((type: any) => type.ID === selectedID);
  }
}
