






























import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: HardwareStatusValue;
}

interface Events {
  onChangeStatus: HardwareStatusValue
}

export enum HardwareStatusValue {
  Deleted = 0,
  Active = 1,
  Retired = 2
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class HardwareStatusComponent extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: '' })
  status!: string;

  @Prop({ required: false, default: true })
  showStatus?: boolean;

  @Prop({ required: false, default: [
    {
      "Status_ID": 0,
      "StatusName": 'Deleted',
      "StatusColor": 'red'
    },
    {
      "Status_ID": 1,
      "StatusName": 'Active',
      "StatusColor": 'blue'
    },
    {
      "Status_ID": 2,
      "StatusName": 'Retired',
      "StatusColor": 'grey'
    }
  ] })
  statuses!: any[];

  currentRole = sessionStorage.getItem("userRole");
  popoverVisible = false
  optionsVisible = false

  get iconClass() {
    if(this.status == 'Deleted'){
      return 'red'
    }
    else if(this.status == 'Active'){
      return 'blue'
    }
    else if(this.status == 'Retired'){
      return 'grey'
    }
    else{
      return 'red'
    }
  }
  hideTooltip() {
    this.optionsVisible = false;
  }
  mouseover() {
    if (this.optionsVisible) {
      return
    }
  }

  mouseout() {
    this.popoverVisible = false
  }

  click() {
    this.popoverVisible = false
    
    this.optionsVisible = !this.optionsVisible
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false
    }
  }

  itemClick(status: HardwareStatusValue) {
    this.$emit('changeStatus', status)
    this.optionsVisible = false
  }
}
