















































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import {htmlParse} from '../helpers/htmlHelpers'

interface Props {
  options: any;
  selectedIDs: [];
  label: string;
  placeholderText: string;
  multiSelect: boolean;
  inputText: string;
}

interface Events {}

declare const $: any;
declare const htmlCheck: Function;

@Component({
  inheritAttrs: false,
  components: {}
})
export default class MultiSelect extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: any;

  @Prop({ required: true })
  selectedIDs!: number[];

  @Prop({ required: false, default: "" })
  label!: string;


  @Prop({ required: false, default: "" })
  labelClass?: string;

  @Prop({ required: false, default: "Search" })
  placeholderText!: string;

  @Prop({ required: false, default: false })
  hideToggle!: boolean;

  @Prop({ required: false, default: false })
  contractDesc!: boolean;

  @Prop({ required: false, default: true })
  multiSelect!: boolean;

  @Prop({ required: false, default: "Please make a selection" })
  inputText!: string;

  @Prop({ required: false, default: "No Global Tags Found" })
  noDataMessage!: string;

  @Prop({ required: false, default: false })
	hideSearch?: boolean;

  @Prop({ required: false, default: false })
	hideSelectAll!: boolean;

  @Prop({ required: false, default: false })
	hideShowSelected?: boolean;

  @Prop({ required: false, default: false })
  showAddTag!: boolean;

  dropdownVisible = false
  search = ''
  showOnlySelected: boolean = false
  creating: any = false;

  get filteredOptions() {
    let options = this.options

    if (this.search) {
      options = options.filter((option) => `${option.CUSTOMFIELDOPTIONNAME}`.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    }

    if (this.showOnlySelected) {
      options = options.filter((option) => this.selectedIDs.indexOf(option.CUSTOMFIELDOPTIONID) !== -1)
    }

    return options
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible
    if(this.dropdownVisible == true) {
      setTimeout(() => {
        $(".global-tags #autoExpand").focus();
      }, 300);
    }
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(id: any): boolean {
    const checked = !!this.selectedIDs.find((val) => val === id);
    return checked;
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }

  removeHTML(txt) {
    if(htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  getSelectedOption() {
    //use for single select
    var ret:(string | undefined) = "";
    if (this.selectedIDs.length) {
      let tmp = this.options.filter(
        (item: any) => item.CUSTOMFIELDOPTIONID == this.selectedIDs[0]
      );
      if (tmp.length) {
        ret = tmp[0].CUSTOMFIELDOPTIONDESC != '' ? tmp[0].CUSTOMFIELDOPTIONNAME + ': ' + tmp[0].CUSTOMFIELDOPTIONDESC : tmp[0].CUSTOMFIELDOPTIONNAME;
      }
    }
    return ret;
  }

  checkToHide() {
    if (!this.multiSelect) {
      //single select case
      this.dropdownVisible = false;
    }
  }
}
