



































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import CustomerAutoSuggestInput from "@/components/CustomerAutoSuggestInput.vue";
import SecurityAutoSuggestInput from "@/components/SecurityAutoSuggestInput.vue";
import UserAutoSuggestInput from "@/components/UserSuggestInput.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";


declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    CustomerAutoSuggestInput,
    SecurityAutoSuggestInput,
    DropdownControl,
    UserAutoSuggestInput
  }
})
export default class AddFinancialBoardModal extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement
  }

  @Prop({ required: false, default: false })
  isUpdate?: boolean;

  @Prop({ required: false, default: [] })
  businessLineOptions!: any;

  @Prop({ required: false, default: "Add Financial Board" })
  title!: string;

  @Prop({ required: false, default: {} })
  data!: any;

  @Prop({ required: false, default: 'accounts' })
  groupType!: string;

  $validator: any;
  boardName: string = "";
  saving: boolean = false;
  selectedAccounts: any = [];
  selectedGroups: any = [];
  selectedBusinessLine: number[] = [0];
  boardUUID: string = "";
  selectedEmpGroups: any = [];
  selectedUsers: any = [];

  mounted() {
    $(this.$refs.modal).modal('show');

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    });
  }

  created() {
    if (this.isUpdate && this.data) {
      if (this.data.BOARDNAME) {
        this.boardName = this.data.BOARDNAME;
      }

      if (this.data.SELECTEDGROUPS) {
        this.selectedGroups = this.data.SELECTEDGROUPS;
      }

      if (this.data.SELECTEDACCOUNTS) {
        this.selectedAccounts = this.data.SELECTEDACCOUNTS;
      }

      if (this.data.SELECTEDBUSINESSLINE) {
        this.selectedBusinessLine = this.data.SELECTEDBUSINESSLINE;
      }

      if (this.data.BOARDUUID) {
        this.boardUUID = this.data.BOARDUUID;
      }

      if (this.data.SELECTEDEMPGROUPS) {
        this.selectedEmpGroups = this.data.SELECTEDEMPGROUPS;
      }

      if (this.data.SELECTEDUSERS) {
        this.selectedUsers = this.data.SELECTEDUSERS;
      }
    }
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  updateCustomer(data) {
    if (data.account) {
      const index = this.selectedAccounts.findIndex((item: any) => item.AID === data.account.AID);
      if (index === -1) {
        this.selectedAccounts.push({
          ANAME: data.account.ANAME || '',
          AID: data.account.AID || 0
        });
      }
    }
  }

  updateAccountList(index, id) {
    this.$delete(this.selectedAccounts, index);
  }

  updateSecurityGroup(data) {
    if (data.securityGroups) {
      const index = this.selectedGroups.findIndex((item: any) => item.SECURITYGROUPID === data.securityGroups.SECURITYGROUPID);
      if (index === -1) {
        this.selectedGroups.push({
          SECURITYGROUPNAME: data.securityGroups.SECURITYGROUPNAME || '',
          SECURITYGROUPID: data.securityGroups.SECURITYGROUPID || 0
        });
      }
    }
  }

  updateSGList(index, id) {
    this.$delete(this.selectedGroups, index);
  }

  async save() {
    const valid = await this.$validator.validateAll();

    if (!valid) {
      return;
    }

    if (!this.selectedAccounts.length && !this.selectedGroups.length && !this.selectedBusinessLine.length) {
      notifier.alert("Please select any one Account or Security Group or Business Line");
      return;
    }

    try {
      this.saving = true;
      let selectedAccountIDs = this.selectedAccounts.map((item: any) => item.AID);
      let selectedGroupsIDs = this.selectedGroups.map((item: any) => item.SECURITYGROUPID);
      const response = await ApiHelper.callApi("post", {
        controller: "Finances",
        FunctionName: "BoardUpdate",
        boardName: this.boardName,
        accountIDs: selectedAccountIDs.join(),
        securityGroupIDs: selectedGroupsIDs.join(),
        action: this.isUpdate ? 'Update' : "Add",
        businessLineId: this.selectedBusinessLine[0] || 0,
        boardUUID: this.boardUUID
      });

      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        this.$emit('reload');
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
    } catch (error) {
      // console.log('error ', error);
    } finally {
      this.saving = false;
    }
  }

  updateBusinessLine(id) {
    this.selectedBusinessLine = [id];
  }

  updateEmpSecurityGroup(data) {
    if (data.securityGroups) {
      const index = this.selectedEmpGroups.findIndex((item: any) => item.SECURITYGROUPID === data.securityGroups.SECURITYGROUPID);
      if (index === -1) {
        this.selectedEmpGroups.push({
          SECURITYGROUPNAME: data.securityGroups.SECURITYGROUPNAME || '',
          SECURITYGROUPID: data.securityGroups.SECURITYGROUPID || 0
        });
      }
    }
  }

  updateEmpSGList(index, id) {
    this.$delete(this.selectedEmpGroups, index);
  }

  updateUser(data) {
    if (data.account) {
      const index = this.selectedUsers.findIndex((item: any) => item.USERID === data.account.USERID);
      if (index === -1) {
        this.selectedUsers.push({
          UFULLNAME: `${data.account.UFNAME || ''} ${data.account.ULNAME || ''}`,
          USERID: data.account.USERID || 0
        });
      }
    }
  }

  updateUserList(index, id) {
    this.$delete(this.selectedUsers, index);
  }

  async saveEmployees() {
    try {
      this.saving = true;
      let selectedUserIDs = this.selectedUsers.map((item: any) => item.USERID);
      let selectedEmpGroupsIDs = this.selectedEmpGroups.map((item: any) => item.SECURITYGROUPID);
      const response = await ApiHelper.callApi("post", {
        controller: "Finances",
        FunctionName: "BoardUpdate",
        userIDs: selectedUserIDs.join(),
        securityEmpGroupIDs: selectedEmpGroupsIDs.join(),
        action: "updateBoardParams",
        boardUUID: this.boardUUID
      });

      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        this.$emit('reload');
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
    } catch (error) {
      // console.log('error ', error);
    } finally {
      this.saving = false;
    }
  }
}
