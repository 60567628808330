var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return " <table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\">\n  <tr style=\"color: #b1b1b1; height: 40px;\">\n    <td width=\"15%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">CUSTOMER PO</td>\n    <td width=\"20%\" style=\"border-bottom: 5px solid #b1b1b1;\"> ORDER NAME</td>\n    <td width=\"15%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">CUSTOMER</td>\n    <td width=\"10%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">SOURCE</td>\n    <td width=\"10%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">STATUS</td>\n    <td width=\"15%\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;\">DATE</td>\n    <td width=\"15%\" style=\"text-align: right; border-bottom: 5px solid #b1b1b1;\">DETAILS</td>\n  </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.ORDERS : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "  <!--\n  <tr style=\"font-weight: bold;\">\n    <td width=\"60%\" style=\"border-top: 5px solid #b1b1b1;\" colspan=\"3\"></td>\n    <td width=\"20%\" style=\"text-align: right; color: #B1B1B1; border-top: 5px solid #b1b1b1;\">Total:</td>\n    <td width=\"20%\" style=\"text-align: right; border-top: 5px solid #b1b1b1;\">"
    + container.escapeExpression(((helper = (helper = helpers.SUMTOTALCUSTOMERPRICE_FORMATTED || (depth0 != null ? depth0.SUMTOTALCUSTOMERPRICE_FORMATTED : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"SUMTOTALCUSTOMERPRICE_FORMATTED","hash":{},"data":data,"loc":{"start":{"line":28,"column":78},"end":{"line":28,"column":113}}}) : helper)))
    + "</td>\n  </tr>\n  -->\n</table> \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <tr>\n      <td width=\"15%\" style=\"text-align: left;\">"
    + ((stack1 = ((helper = (helper = helpers.POID || (depth0 != null ? depth0.POID : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"POID","hash":{},"data":data,"loc":{"start":{"line":15,"column":48},"end":{"line":15,"column":58}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"20%\" style=\"text-align: left;\">"
    + ((stack1 = ((helper = (helper = helpers.PODESCRIPTION || (depth0 != null ? depth0.PODESCRIPTION : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"PODESCRIPTION","hash":{},"data":data,"loc":{"start":{"line":16,"column":48},"end":{"line":16,"column":67}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"15%\" style=\"text-align: left;\">"
    + ((stack1 = ((helper = (helper = helpers.ANAME || (depth0 != null ? depth0.ANAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ANAME","hash":{},"data":data,"loc":{"start":{"line":17,"column":48},"end":{"line":17,"column":59}}}) : helper))) != null ? stack1 : "")
    + "</td>\n      <td width=\"10%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.STATUS_SOURCE || (depth0 != null ? depth0.STATUS_SOURCE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"STATUS_SOURCE","hash":{},"data":data,"loc":{"start":{"line":18,"column":48},"end":{"line":18,"column":65}}}) : helper)))
    + "</td>\n      <td width=\"10%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.STATUS_TYPE || (depth0 != null ? depth0.STATUS_TYPE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"STATUS_TYPE","hash":{},"data":data,"loc":{"start":{"line":19,"column":48},"end":{"line":19,"column":63}}}) : helper)))
    + "</td>\n      <td width=\"15%\" style=\"text-align: left;\">"
    + alias4(((helper = (helper = helpers.DATETIME || (depth0 != null ? depth0.DATETIME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DATETIME","hash":{},"data":data,"loc":{"start":{"line":20,"column":48},"end":{"line":20,"column":60}}}) : helper)))
    + "</td>\n      <td width=\"15%\" style=\"text-align: right;\">"
    + alias4(((helper = (helper = helpers.STATUS_NAME || (depth0 != null ? depth0.STATUS_NAME : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"STATUS_NAME","hash":{},"data":data,"loc":{"start":{"line":21,"column":49},"end":{"line":21,"column":64}}}) : helper)))
    + "</td>\n    </tr>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <table width=\"100%\" id=\"quoteDetails_items\" cellspacing=\"0\" cellpadding=\"0\" style=\"table-layout:fixed;\">\n    <tr style=\"color: #b1b1b1; height: 40px;\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.SELECTEDHEADERSARRAY : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":35,"column":6},"end":{"line":37,"column":15}}})) != null ? stack1 : "")
    + "    </tr>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.ORDERS : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 2, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":39,"column":4},"end":{"line":45,"column":13}}})) != null ? stack1 : "")
    + "\n    <tr style=\"font-weight: bold;\">\n      <td width=\"60%\" style=\"border-top: 5px solid #b1b1b1;\" colspan=\""
    + alias4(((helper = (helper = helpers.SELECTEDHEADERSLENGTH || (depth0 != null ? depth0.SELECTEDHEADERSLENGTH : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SELECTEDHEADERSLENGTH","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":48,"column":70},"end":{"line":48,"column":95}}}) : helper)))
    + "\"></td>\n      <td width=\"20%\" style=\"text-align: right; color: #B1B1B1; border-top: 5px solid #b1b1b1;\">Total:</td>\n      <td width=\"20%\" style=\"text-align: right; border-top: 5px solid #b1b1b1;\">"
    + alias4(((helper = (helper = helpers.SUMTOTALCUSTOMERPRICE_FORMATTED || (depth0 != null ? depth0.SUMTOTALCUSTOMERPRICE_FORMATTED : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"SUMTOTALCUSTOMERPRICE_FORMATTED","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":50,"column":80},"end":{"line":50,"column":115}}}) : helper)))
    + "</td>\n    </tr>\n\n  </table>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <td width=\""
    + alias4(((helper = (helper = helpers.widthPercent || (depth0 != null ? depth0.widthPercent : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widthPercent","hash":{},"data":data,"loc":{"start":{"line":36,"column":19},"end":{"line":36,"column":35}}}) : helper)))
    + "\" style=\"text-align: left; border-bottom: 5px solid #b1b1b1;overflow: hidden;word-wrap: break-word;\">"
    + alias4(((helper = (helper = helpers.column || (depth0 != null ? depth0.column : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"column","hash":{},"data":data,"loc":{"start":{"line":36,"column":136},"end":{"line":36,"column":146}}}) : helper)))
    + "</td>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "      <tr>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].SELECTEDHEADERSARRAY : depths[1]),{"name":"each","hash":{},"fn":container.program(8, data, 2, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":41,"column":8},"end":{"line":43,"column":17}}})) != null ? stack1 : "")
    + "      </tr>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression;

  return "        <td width=\""
    + alias3(((helper = (helper = helpers.widthPercent || (depth0 != null ? depth0.widthPercent : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"widthPercent","hash":{},"data":data,"loc":{"start":{"line":42,"column":19},"end":{"line":42,"column":35}}}) : helper)))
    + "\" style=\"text-align: left;word-break: break-all;vertical-align: baseline;\"> "
    + alias3((helpers.getIestedIterationValues||(depth0 && depth0.getIestedIterationValues)||alias2).call(alias1,blockParams[1][0],((stack1 = blockParams[0][0]) != null ? stack1.header : stack1),{"name":"getIestedIterationValues","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":42,"column":111},"end":{"line":42,"column":155}}}))
    + " </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isOrderStatus : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"blockParams":blockParams,"loc":{"start":{"line":2,"column":0},"end":{"line":54,"column":7}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true,"useBlockParams":true});