getComponent = function(name, templates) {
    const Footer = Vue.component('add-footer', {
        template: templates.Footer.data,
        data: function() {
            return {
                search: '',
            }
        },
        props: {
            totalAlerts: Number,
            searchChange: Function,
        },
        methods: {
            checkUser: function() {
                return sessionStorage.getItem('userRole');
            }
        },
    })

    const BarChart = Vue.component('bar-chart', {
        extends: VueChartJs.Bar,
        props: {         
            data: Array,
            label: Array,
            type: String,
            backgroundColor: String,
        },
        methods: {
            renderBarChart: function () {
                var vm = this;
                this.renderChart({
                    labels: this.label,
                    datasets: this.chartData,
                }, {  responsive: true, 
                        maintainAspectRatio: false,
                        legend: { //hides the legend
                            display: false,
                        },
                        tooltips: {
                            callbacks: {
                            title: function(tooltipItem, data) {
                                return data['labels'][tooltipItem[0]['index']];
                            },
                            label: function(tooltipItem, data) {
                                return vm.type == "units" ? tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (tooltipItem.yLabel == 1) ? tooltipItem.yLabel + ' Day' : tooltipItem.yLabel + ' Days' ;
                            },
                            labelTextColor:function(tooltipItem, chart){
                                    return '#0066ff' ;
                                }
                            },
                            backgroundColor: '#FFF',
                            borderColor: 'grey',
                            borderWidth: 1,
                            titleFontSize: 14,
                            titleFontColor: '#000000',
                            bodyFontColor: '#000',
                            bodyFontSize: 14,
                            displayColors: false
                        },
                        scales: { //hides the y axis
                            xAxes: [{
                                barThickness: 15
                            }],
                            yAxes: [{
                                ticks: {
                                    min: 0,
                                    reverse: false,
                                    callback: function(value, index, values) {
                                        if(value == 0)
                                            return '';
                                        else
                                            return vm.type == "units" ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (value == 1) ? value + ' Day' : value + ' Days'
                                    }
                                }
                            }]
                        }
                    })
            }
        },
        watch: {
            'chartData': {
                handler: function (val, oldVal) {
                    this.renderBarChart();        
                },
                deep: true
            }
        },
        computed: {
            chartData: function() {
                return this.data;
            }
        },
        mounted: function () {
            this.renderBarChart();
        }       
    })
      
    let components = {
        /*NEW COMPONENTS - DELETE COMMENTS WHEN DONE*/
        /*NEW COMPONENTS - DELETE COMMENTS WHEN DONE*/
        'profile': Vue.component('profile', {
            methods: validateFn,
            template: templates.Profile.data,
            components: { Header: Header, Footer: Footer },
        }),
        'user-add': Vue.component('user-add', {
            methods: validateFn,
            template: templates.UserAdd.data,
            components: { Header: Header, Footer: Footer },
        }),
        'admin': Vue.component('admin', {
            template: templates.Admin.data,
            components: { Header: Header, Footer: Footer },
        }),
        'edit-user': Vue.component('edit-user', {
            methods: validateFn,
            template: templates.Profile.data,
            components: { Header: Header, Footer: Footer },
        }),
    }
    return components[name]
}
