import { render, staticRenderFns } from "./OppStatus.vue?vue&type=template&id=b6316724&scoped=true"
import script from "./OppStatus.vue?vue&type=script&lang=tsx"
export * from "./OppStatus.vue?vue&type=script&lang=tsx"
import style0 from "./OppStatus.vue?vue&type=style&index=0&id=b6316724&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6316724",
  null
  
)

export default component.exports