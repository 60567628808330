


















































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import CustomerAutoSuggestInput from "@/components/CustomerAutoSuggestInput.vue";
import UserAutoSuggestInput from "../components/UserSuggestInput.vue";
import LaddaButton from "@/components/LaddaButton.vue";
import ConfirmRegenerateCommissionModal from "@/components/ConfirmRemoveItemModal.vue";
import SecurityAutoSuggestInput from "@/components/SecurityAutoSuggestInput.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import { notifier } from "../models/common";

@Component({
  inheritAttrs: false,
  components: {
    CustomerAutoSuggestInput,
    UserAutoSuggestInput,
    LaddaButton,
    ConfirmRegenerateCommissionModal,
    SecurityAutoSuggestInput,
    DropdownControl
  }
})
export default class EmployeePlanEdit extends TSXComponent<void> {
  $route: any;
  $router: any;
  $validator: any;
  pageTitle = "";
  employeePlanId = "";
  compId = 0;
  errors: any;
  allTargetsTotal = '0.00';
  adjustedProfitTotal = '$0.00';
  saving: boolean = false;
  user_account = "";
  user_account_name = "";
  oteBase = "";
  oteCommission = "";
  oteYear = "";
  empPlanAccountData = [
    {
      LINKID: 0,
      SECURITYGROUPID: 0,
      COMPWEIGHT: 100,
      AID: "",
      ANAME: "",
      TARGET_PROFIT: 0,
      TARGET_PROFIT_FORMATTED: '$0.00',
      ISDUPLICATE: 0,
      BUSINESSLINEID: 0,
      LASTTHREEYEARSMARGIN: 0,
      LASTTHREEYEARSMARGIN_FORMATTED: '$0.00'
    }
  ];
  isEditAdjustedTarget: boolean = false;
  gustoEmployeeId: string = "";
  confirmCommModalVisible: boolean = false;
  allowEditComm: boolean = false;
  realizedProfitTotal: string = "$0.00";
  oteCommBK: number = 0;
  selectedGroups: any = [];
  businessLineOptions: any = [
    {
      ID: 0,
      TEXT: "All"
    }
  ];
  selectedBusinessLine: number[] = [];
  lastThreeYearsMarginTotal = '0.00';
  prevSelectedBusinessLine: number = 0;

  async created() {
    this.employeePlanId = this.$route.params.id == 0 ? "" : this.$route.params.id;
    if (this.employeePlanId != "") {
      this.pageTitle = "Edit Employee Comp Plan #<span id='employeeIdID'>" + this.compId + "</span>";
      await this.fetchData();
    } else {
      this.pageTitle = 'Create Comp Plan';
    }
    await this.businessLineLoad();
  }

  validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.$validator.validateAll().then(result => {
      if (result) {
        this.checkAccount();
        var duplicateAccountExist = this.empPlanAccountData.find(o => o.ISDUPLICATE === 1);
        if (duplicateAccountExist) {
          notifier.alert("Duplicate Account exists");
          return;
        }
        return this.submit();
      }
    });
  }

  async fetchData() {
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "EmployeePlanView",
      compUUID: this.employeePlanId,
      action: "Detailed"
    });

    if (response.STATUS === 1) {
      if(response.EMPLOYEEPLANDETAIL.COMPID){
        this.pageTitle = "Edit Employee Comp Plan #<span id='employeeIdID'>" + response.EMPLOYEEPLANDETAIL.COMPID + "</span>";
      }
      this.user_account = response.EMPLOYEEPLANDETAIL.EMPLOYEEID;
      this.user_account_name = response.EMPLOYEEPLANDETAIL.EMPLOYEENAME;
      this.oteBase = response.EMPLOYEEPLANDETAIL.OTEBASE_FORMATTED;
      this.oteCommission = response.EMPLOYEEPLANDETAIL.OTECOMM_FORMATTED;
      this.gustoEmployeeId = response.EMPLOYEEPLANDETAIL.GUSTOEMPLOYEEID;
      this.oteYear = response.EMPLOYEEPLANDETAIL.OTEYEAR;
      this.empPlanAccountData = response.EMPLOYEELINKACCOUNT;
      this.realizedProfitTotal = response.REALIZEDPROFITTOTAL;
      this.oteCommBK = response.EMPLOYEEPLANDETAIL.OTECOMM;
      let allTargetsTotal = response.ALLTARGETSTOTAL || 0;
      let adjustedProfitTotal = response.EMPLOYEEPLANDETAIL.COMP_TARGET || 0;
      if (allTargetsTotal != adjustedProfitTotal) {
        this.isEditAdjustedTarget = true;
        this.adjustedProfitTotal = `$${this.formatter(adjustedProfitTotal)}`;
      }
      this.calculateTotal();
      this.addInputRow(this.empPlanAccountData.length);
    }
  }

  async businessLineLoad() {
    const response = await ApiHelper.callApi("post", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });

    if (response.STATUS === 1) {
      (response.businessLineOptions || []).map((item: any) => {
        this.businessLineOptions.push({
          ID: item.ID,
          TEXT: item.BUSINESSLINENAME
        })
      });
    }

  }

  async submit() {
    let empAccountData = this.empPlanAccountData.filter((item: any) => item.AID);
    if (!empAccountData.length) {
      notifier.alert("Add at least one Account");
      return;
    }
    if (this.formatAmount(this.oteCommission) != this.oteCommBK && !this.allowEditComm && this.formatAmount(this.realizedProfitTotal) != 0) {
      this.confirmCommModalVisible = true;
      return;
    }
    this.saving = true;
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "EmployeePlanAdd",
      user_account: this.user_account,
      oteBase: this.formatAmount(this.oteBase),
      oteCommission: this.formatAmount(this.oteCommission),
      oteYear: this.oteYear,
      empPlanAccount: empAccountData,
      allTargetsTotal: this.formatAmount(this.allTargetsTotal),
      adjustedProfitTotal: this.formatAmount(this.adjustedProfitTotal),
      action: !this.employeePlanId ? "insert" : "update",
      compUUID: this.employeePlanId || "",
      gustoEmployeeId: this.gustoEmployeeId
    });

    if (response.STATUS === 1) {
      notifier.success(response.STATUSMESSAGE);
      this.backToDetails();
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
    this.saving = false;
  }

  backToDetails() {
    if (this.employeePlanId) {
      this.$router.push({ name: "EmployeePlanDetails", params: { id: `${this.employeePlanId}` } });
    } else {
      this.$router.push({ name: "EmployeePlans" });
    }
  }

  formatAmount(n) {
    return n.replace(/[^0-9.]/g, "")
  }

  formatNumber(n) {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatCurrency(event, id) {
    // Ensure value is treated as a string
    let input_val = String(event.target.value);

    // Extract and remove the dollar sign to handle numeric operations
    input_val = input_val.replace(/^\$/, "");

    if (input_val === "") {
      return '';
    }

    // Split the input value into two parts: before and after the decimal point
    let parts = input_val.split(".");
    let left_side = parts[0];
    let right_side = parts.length > 1 ? '.' + parts[1] : '';

    left_side = this.formatNumber(left_side);

    // Only format the right side if there's something to format
    if (right_side) {
      right_side = right_side.substring(0, 3); // Keep the decimal point and the next two digits
    }

    this[id] = `$${left_side}${right_side}`;
  }

  handleBlur(event, id) {
    let input_val = String(event.target.value);

    // Remove any existing dollar sign to avoid confusion in formatting
    input_val = input_val.replace(/^\$/, "");

    if (input_val === "") {
      this[id] = "";
      return '';
    }

    // Check if the value includes a decimal point but no cents or incomplete cents
    let parts = input_val.split(".");
    if (parts.length < 2 || parts[1].length < 2) {
      // Append missing parts or pad with zeros to ensure two decimal places
      input_val = parts[0] + (parts.length < 2 ? ".00" : "." + parts[1].padEnd(2, '0'));
    } else {
      // Ensure two decimal places if user entered partial cents
      input_val = parts[0] + "." + parts[1].substring(0, 2);
    }

    
    // Re-add the dollar sign for consistent currency formatting
    this[id] = `$${input_val}`;

  }

  addInputRow(index) {
    if (index === this.empPlanAccountData.length) {
      let BLid = 0;
      if (this.prevSelectedBusinessLine) {
        BLid = this.prevSelectedBusinessLine;
      } else if (this.selectedBusinessLine.length) {
        BLid = this.selectedBusinessLine[0];
      }
      var empAccountNew = {
        LINKID: 0,
        SECURITYGROUPID: 0,
        COMPWEIGHT: 100,
        AID: "",
        ANAME: "",
        TARGET_PROFIT: 0,
        TARGET_PROFIT_FORMATTED: '$0.00',
        ISDUPLICATE: 0,
        BUSINESSLINEID: BLid,
        LASTTHREEYEARSMARGIN: 0,
        LASTTHREEYEARSMARGIN_FORMATTED: '$0.00'
      };
      this.empPlanAccountData.push(empAccountNew);
    }
  }

  deleteInputRow(index) {
    if (index < this.empPlanAccountData.length) {
      this.empPlanAccountData.splice(index, 1);
      this.calculateTotal();
      this.checkAccount();
    }
  }

  calculateTotal() {
    const total = this.empPlanAccountData.reduce((sum, {TARGET_PROFIT}) => {
      const numericValue = parseFloat(TARGET_PROFIT.toString().replace(/[^\d.-]/g, '')) || 0;
      return sum + numericValue;
    }, 0);

    const lastThreeYearsMarginTotal = this.empPlanAccountData.reduce((sum, {LASTTHREEYEARSMARGIN}) => {
      const numericValue = parseFloat(LASTTHREEYEARSMARGIN.toString().replace(/[^\d.-]/g, '')) || 0;
      return sum + numericValue;
    }, 0);

    // Use Intl.NumberFormat to format the total as a currency string
    // const formatter = new Intl.NumberFormat('en-US', {
    //   style: 'decimal',
    //   currency: 'USD',
    //   minimumFractionDigits: 2,
    //   maximumFractionDigits: 2,
    // });

    this.allTargetsTotal = this.formatter(total);
    this.lastThreeYearsMarginTotal = this.formatter(lastThreeYearsMarginTotal);
    if (!this.isEditAdjustedTarget) {
      this.adjustedProfitTotal = `$${this.formatter(total)}`;
    }
  }

  formatter(amount) {
    // Use Intl.NumberFormat to format the total as a currency string
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }

  updateCustomer(data, item, index) {
    this.checkAccount();
    item.TARGET_PROFIT = data.account.TARGET_PROFIT || 0;
    item.TARGET_PROFIT_FORMATTED = `$${this.formatter(data.account.TARGET_PROFIT || 0)}`;
    item.LASTTHREEYEARSMARGIN = data.account.LASTTHREEYEARSMARGIN || 0;
    item.LASTTHREEYEARSMARGIN_FORMATTED = `$${this.formatter(data.account.LASTTHREEYEARSMARGIN || 0)}`;
    
    this.calculateTotal();
    this.addInputRow(index);
  }

  yearOptions() {
    const currentYear: number = new Date().getFullYear();
    const options: number[] = [];
    // Add previous year, current year, and 5 years after the current year
    for (let i = -1; i <= 5; i++) {
      options.push(currentYear + i);
    }
    return options;
  }

  listPageRedirection() {
    this.backToDetails();
  }

  checkAccount() {
    this.empPlanAccountData.map((item: any) => {
      var filteredAccount = this.empPlanAccountData.filter(checkDupItem => item.AID != "" && checkDupItem.AID != "" && checkDupItem.AID == item.AID);
      item.ISDUPLICATE = 0;
      if (filteredAccount.length > 1) {
        item.ISDUPLICATE = 1;
      }
    });
    this.$forceUpdate();
  }

  async regenerateCommission() {
    this.confirmCommModalVisible = false;
    this.allowEditComm = true;
    await this.submit();
  }

  updateSecurityGroup(data) {
    if (data.securityGroups) {
      const index = this.selectedGroups.findIndex((item: any) => item.SECURITYGROUPID === data.securityGroups.SECURITYGROUPID);
      if (index === -1) {
        this.selectedGroups.push({
          SECURITYGROUPNAME: data.securityGroups.SECURITYGROUPNAME || '',
          SECURITYGROUPID: data.securityGroups.SECURITYGROUPID || 0
        });
        if (data.securityGroups.ACCOUNTS) {
          this.empPlanAccountData.pop();
          data.securityGroups.ACCOUNTS.map((item: any) => {
            const acc_index = this.empPlanAccountData.findIndex((accItem: any) => accItem.AID != "" && accItem.AID == item.AID);
            if (acc_index === -1) {
              var empAccountNew = {
                LINKID: 0,
                SECURITYGROUPID: data.securityGroups.SECURITYGROUPID || 0,
                COMPWEIGHT: 100,
                AID: item.AID || 0,
                ANAME: item.ANAME || '',
                TARGET_PROFIT: item.TARGET_PROFIT || 0,
                TARGET_PROFIT_FORMATTED: `$${this.formatter(item.TARGET_PROFIT || 0)}`,
                ISDUPLICATE: 0,
                BUSINESSLINEID: 0,
                LASTTHREEYEARSMARGIN: item.LASTTHREEYEARSMARGIN || 0,
                LASTTHREEYEARSMARGIN_FORMATTED: `$${this.formatter(item.LASTTHREEYEARSMARGIN || 0)}`,
              };
              this.empPlanAccountData.push(empAccountNew);
            }
          });
          this.calculateTotal();
          this.addInputRow(this.empPlanAccountData.length);
        }
      }
    }
  }

  updateSGList(index, id) {
    this.$delete(this.selectedGroups, index);
    this.empPlanAccountData = this.empPlanAccountData.filter(item => item.SECURITYGROUPID !== id);
    this.calculateTotal();
  }

  updateBusinessLine(id, item) {
    item.BUSINESSLINEID = id;
    this.prevSelectedBusinessLine = id;
    this.empPlanAccountData.map((item:any) => {
      if (!item.AID) {
        item.BUSINESSLINEID = id;
      }
    });
  }

  removeChar(data, key) {
    if (typeof data[key] != 'undefined') {
      data[key] = data[key].replace(/[^0-9.]/g, "");
      if (!data[key].trim().length) {
        data[key] = 0;
      }
      if (data[key] > 100) {
        data[key] = 100;
      } else if (data[key] < 0) {
        data[key] = 0;
      }
    }
  }

  updateAllBusinessLine(id) {
    this.selectedBusinessLine = [id];
    this.prevSelectedBusinessLine = id;
    this.empPlanAccountData.map((item:any) => {
      item.BUSINESSLINEID = id;
    });
  }
}
