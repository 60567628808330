






























import {Component as TSXComponent} from "vue-tsx-support";
import {Component, Prop} from "vue-property-decorator";
import Loader from '../Loader.vue';

interface Props {
  title: string
  acctCommission: object[];
  selectedSGID: number[];
  selectedTagID: number[];
}

interface Events {
  onClose: void
}

declare const $: any
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    Loader
  }
})
export default class ContentModal extends TSXComponent<
    Props,
    Events
> {

  @Prop({required: true})
  title!: string

  @Prop({required: true})
  content!: string

  $parent: any;
  loading = false;

  $refs!: {
    modal: HTMLDivElement
  }

  mounted() {
    $(this.$refs.modal).modal('show')

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    })
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  async created() {

  }

}
