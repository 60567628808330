


















































































































































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "./LaddaButton.vue";
import { notifier, wait } from "../models/common";
import axios from "axios";
import DropdownControl from "../components/DropdownControl.vue";
import { getEUAddress } from "../helpers/ApiHelper";
import USState from "../static/USstates.json";
import Inputmask from "inputmask";
import FormMultiSelect from "@/components/Form/FormMultiSelect.vue";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    DropdownControl,
    FormMultiSelect
  },
  methods: {
    getEUAddress
  }
})
export default class AssignToEndUserModal extends TSXComponent<void> {
  @Prop({ required: false, default: "" })
  selectedIDs!: any;

  @Prop({ required: false, default: [] })
  selectedItems!: any;

  @Prop({ required: false, default: 0 })
  selectedEuId!: any;

  @Prop({ required: false, default: false })
  forceEditEU!: any;

  @Prop({ required: false, default: false })
  isAddingNewEU!: any;

  @Prop({ required: false, default: {} })
  newEUData!: any;

  $refs!: {
    stateListRef: any;
    modal: HTMLDivElement;
  };

  loading = false;
  saving: boolean | "error" = false;
  $parent: any;
  accountEUsList: any = [];
  hardwareInfo: any = [];
  selectedEUIds: any = [];
  editUser = false;
  aId = 0;
  euInfo: any = {
    euId: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    addr: "",
    addr2: "",
    city: "",
    state: "",
    zip: "",
    country: ""
  };
  stateList: any = [];
  selectedState: string[] = [];
  euSettings: any = {
    selectedChangeInfoIds: [],
    rememberSetting: false
  };
  changeInfoOptions = [
    {
      id: "Quotes",
      text: "Quotes"
    },
    {
      id: "Orders",
      text: "Orders"
    },
    {
      id: "Hardware",
      text: "Hardware"
    },
    {
      id: "Deployments",
      text: "Deployments"
    }
  ];

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async created() {
    const aIds = [
      ...new Set(
        this.selectedItems
          .filter(item => item.AID || 0)
          .map(item => item.AID || 0)
      )
    ];

    if (!aIds.length) {
      return;
    } else if (aIds.length > 1) {
      notifier.alert("Selected hardware of different accounts");
      return;
    }

    // build states list
    for (const i in USState) {
      this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
    }

    // update aId
    this.aId = parseInt(`${aIds[0]}`);

    // get end users list of this account
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Hardware",
        FunctionName: "GetAccountEUsList",
        hardwareIds: this.selectedIDs.join(","),
        aId: this.aId,
        selectedEuId: this.selectedEuId || 0
      });
      if (response.data.STATUS == 1) {
        this.accountEUsList = response.data.accountEUsList || [];
        this.hardwareInfo = response.data.hardwareInfo || [];
        // this.aID = response.data.aId || 0;
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }

    if (this.forceEditEU) {
      this.runMethod(this.isAddingNewEU ? "onetime" : this.selectedEuId);
    }

    // get change info setting by account
    // const response = await axios.post(dataURL + "?ReturnType=JSON", {
    //   controller: "Accounts",
    //   FunctionName: "GetParams",
    //   aId: this.aId,
    //   key: "selectedChangeInfoIds"
    // });
    // if (response.data.STATUS == 1 && "keyValue" in response.data) {
    //   const selectedChangeInfoIds = response.data.keyValue || "";
    //   this.euSettings.selectedChangeInfoIds = selectedChangeInfoIds
    //     ? selectedChangeInfoIds.split(",")
    //     : [];
    // }
    this.euSettings.selectedChangeInfoIds = ["Orders"];
  }

  async assignToEndUser(forceUpdate = false) {
    if (!this.selectedEUIds.length || !this.selectedIDs.length) {
      return;
    }

    // validate
    let valid = await this.$validator.validateAll();

    // check existed in list if not case adding new
    if (!this.isAddNewEU) {
      const euInfo = this.accountEUsList.find(
        item => item.EUID == this.selectedEUIds[0]
      );
      if (!euInfo) valid = false;
    }

    if (!valid) return;

    try {
      this.saving = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Hardware",
        FunctionName: "AssignToEU",
        hardwareIds: this.selectedIDs.join(","),
        euInfo: this.euInfo,
        aId: this.aId,
        isAddNewEU: this.isAddNewEU,
        selectedChangeInfoIds: this.euSettings.selectedChangeInfoIds.join(","),
        rememberSetting: this.euSettings.rememberSetting,
        fromSub: "Hardware"
      });

      if (response.data.STATUS == 1) {
        this.saving = false;
        // success
        this.$emit("close");
        this.$emit("resetSelectedIds");
        notifier.success("Assigned successfully");
      } else {
        this.saving = "error";
      }
    } catch (err) {
      console.log(err);
      this.saving = "error";
    }
  }

  runMethod(id) {
    this.selectedEUIds = [id];

    const selectedUser = this.accountEUsList.find(user => {
      return user.EUID === id;
    });

    if (selectedUser) {
      const names = selectedUser.EUNAME.split(" ");

      this.euInfo = {
        euId: selectedUser.EUID,
        firstName: names[0],
        lastName: names.length > 1 ? names.slice(1).join(" ") : "",
        email: selectedUser.EUEMAIL,
        phone: selectedUser.EUPHONE,
        addr: selectedUser.ADDR1,
        addr2: selectedUser.ADDR2,
        city: selectedUser.CITY,
        state: selectedUser.EUSTATE,
        zip: selectedUser.ZIP,
        country: selectedUser.COUNTRY
      };
      if (this.euInfo.state != "") {
        this.selectedState = [this.euInfo.state];
      }
    } else if (this.isAddNewEU && (this.newEUData.data || "")) {
      // pre fill new data for end user
      const nameParts = (this.newEUData.data.EUNAME || "").trim().split(" ");
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(" ");
      this.euInfo = {
        euId: 0,
        firstName,
        lastName,
        email: "",
        phone: "",
        addr: this.newEUData.data.ADDR1 || "",
        addr2: this.newEUData.data.ADDR2 || "",
        city: this.newEUData.data.CITY || "",
        state: this.newEUData.data.EUSTATE || "",
        zip: this.newEUData.data.ZIP || "",
        country: this.newEUData.data.COUNTRY || ""
      };
      if (this.euInfo.state != "") {
        this.selectedState = [this.euInfo.state];
      }
    }

    // create new case
    if(`${this.euInfo.euId || ""}`.indexOf("0-") != -1) {
      this.euInfo.euId = 0;
    }

    this.editUser = true;
    this.$nextTick().then(() => {
      // mask for phone
      $("input[inputmask=phonenumber]").each(function(i, obj) {
        Inputmask("(999) 999-9999", {}).mask(obj);
      });
    });
  }

  get isAddNewEU() {
    let ret = false;
    if (this.selectedEUIds.length && this.selectedEUIds[0] == "onetime") {
      ret = true;
    }

    return ret;
  }

  toggleChangeInfoOption(id, text) {
    if (!this.euSettings.selectedChangeInfoIds.includes(id)) {
      this.euSettings.selectedChangeInfoIds.push(id);
    } else {
      this.euSettings.selectedChangeInfoIds = this.euSettings.selectedChangeInfoIds.filter(
        t => t != id
      );
    }
    // sort
    const ret: any = [];
    for (const t of this.changeInfoOptions) {
      if (this.euSettings.selectedChangeInfoIds.includes(t.id)) {
        ret.push(t.id);
      }
    }
    this.euSettings.selectedChangeInfoIds = ret;
  }
}
