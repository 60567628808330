import { ApiHelper } from "@/helpers/all";

export class HardwareDashboardAPIRepo {

  async fetchData(year: any = 0) {
    let reqData = {}
    reqData["controller"] = "Hardware";
    reqData["FunctionName"] = "Dashboard";
    reqData["fYear"] = year;

    const response = await ApiHelper.callApi("post", reqData);

    if (response.ERROR) {
      throw new Error(response.ERROR);
    }
    if (response.STATUS !== 1) {
      // throw new Error(response.STATUSMESSAGE);
      return response
    }

    return response
  }

}